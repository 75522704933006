export default {
  translation: {
    commonUsedWords: {
      login: 'Anmelden',
      email: 'E-Mail-Adresse',
      password: 'Passwort',
      emailIsRequired: 'E-Mail ist erforderlich',
      mustBeAValidEmail: 'Muss eine gültige E-Mail sein',
      deviceSerialNumber: 'Geräteseriennummer',
      next: 'Weiter',
      success: 'Erfolg',
      submit: 'Einreichen',
      firstName: 'Vorname',
      firstNameIsRequired: 'Vorname ist erforderlich',
      lastName: 'Nachname',
      lastNameIsRequired: 'Nachname ist erforderlich',
      save: 'Speichern',
      delete: 'Löschen',
      undoChanges: 'Änderungen rückgängig machen',
      cancel: 'Abbrechen',
      loading: 'Laden...',
      yes: 'Ja',
      no: 'Nein',
      clear: 'Löschen',
      confirm: 'Bestätigen',
      noOption: 'Keine Optionen',
      openText: 'Öffnen',
      edit: 'Bearbeiten',
      logout: 'Abmelden',
      latitude: 'Breitengrad',
      longitude: 'Längengrad',
      ok: 'Ok',
      okay: 'Okay',
      done: 'Fertig',
      comingSoon: 'Demnächst...',
      reset: 'Zurücksetzen',
      attention: 'Achtung',
      note: 'Hinweis',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Pflichtfelder fehlen',
        userNotFound: 'Benutzer nicht gefunden',
        missingRefreshToken: 'Auffrischungstoken fehlt',
        cannotRefreshToken: 'Token kann nicht aktualisiert werden',
      },
      helpers: {
        error: 'Fehler',
        invalidHTTPMethod: 'Ungültige HTTP-Methode',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'E-Mail-Adresse bestätigen',
        confirmEmailIsRequired: 'Bestätigte E-Mail ist erforderlich',
        emailNotMatched: 'E-Mail-Einträge stimmen nicht überein',
      },
      confirmPassword: {
        confirmPassword: 'Passwort bestätigen',
        confirmPasswordIsRequired: 'Bestätigtes Passwort ist erforderlich',
        newPasswordIsRequired: 'Ein neues Passwort ist erforderlich',
        showPassword: 'Passwort anzeigen',
        passwordLength: 'Passwörter müssen mindestens 6 Zeichen lang sein',
        passwordContainSpaces: 'Das Passwort darf keine Leerzeichen enthalten',
        passwordNotMatch: 'Passworteinträge stimmen nicht überein',
        newPassword: 'Neues Passwort',
      },
      confirmModal: {
        areYouSure: 'Sind Sie sicher?',
      },
      appliedFilters: {
        between: 'Zwischen {{firstValue}} und {{secondValue}}',
        bottom: 'Unten {{primaryValue}}%',
        top: 'Oben {{primaryValue}}%',
        activeFilters: 'Aktive Filter',
        active: 'aktiv',
        closed: 'geschlossen',
      },
      editTable: {
        editTable: 'Tabelle bearbeiten',
        selectDeselect: 'Tabellwerte auswählen/abwählen',
        resetToDefault: 'Auf Standard zurücksetzen',
      },
      errorBoundary: {
        oops: 'Ups! Etwas Unerwartetes ist passiert.',
        tryAgain: 'Bitte versuchen Sie es erneut! Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Administrator.',
        refresh: 'Aktualisieren',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Entschuldigung, keine Ergebnisse gefunden. Bitte versuchen Sie es erneut.',
      },
      permissionGuard: {
        invalidPermission: 'Ungültige Berechtigungsstruktur, 3 Elemente im Array erwartet',
      },
      preview: {
        findInView: 'In der Ansicht finden',
        showDetails: 'Details anzeigen',
        closeEsc: 'Schließen (Esc)',
        prevLeftArrow: 'Zurück (Pfeil nach links)',
        nextRightArrow: 'Weiter (Pfeil nach rechts)',
        proceedWithoutSaving: 'Ohne Speichern navigieren',
        continueEditing: 'Weiter bearbeiten',
        unsavedChanges: {
          partTwo: 'Sie haben ',
          partThree: 'NICHT GESPEICHERTE ÄNDERUNGEN',
          partFour: '. Ohne Speichern navigieren wird alle Änderungen verwerfen. Sind Sie sicher?',
        },
      },
      printRecord: {
        title: 'Eintrag',
        date: 'Datum:',
        location: 'Ort',
        leakDetails: 'Details',
        leakParameters: 'Parameter',
        energyLoss: 'Energieverlust',
        leakSourceEquipment: 'Quellgerät',
        notes: 'Notizen',
        leakEventStatus: 'Status',
        requiredAction: 'Erforderliche Aktion',
        leakSourceImage: 'Quellbild',
        smartLeakTag: 'Smart Tag',
        lossPerYear: 'Verlust ({{currency}}/Jahr)',
        costOfEnergy: 'Energiekosten ({{currency}}/kWh)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'Die Zuweisung wurde gelöscht',
          deleteAssignmentError: 'Die Zuweisung kann nicht gelöscht werden. Bitte versuchen Sie es später erneut',
          currentlyAssigned: 'Derzeit zugewiesene Aktion',
          repairedBy: 'Repariert von',
          text: 'Sie sind dabei, diese Zuweisung zu löschen. Diese Aktion kann nicht rückgängig gemacht werden',
        },
        actionSaveDate: 'Zuletzt gespeichert',
      },
      visibleColumnsPopper: {
        tableValues: 'Tabellenwerte auswählen / abwählen',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Gleich',
        LESS_THAN: 'Weniger als',
        GREATER_THAN: 'Mehr als',
        LESS_THAN_OR_EQUAL_TO: 'Weniger als oder gleich',
        GREATER_THAN_OR_EQUAL_TO: 'Mehr als oder gleich',
        BETWEEN_A_AND_Y: 'Zwischen',
        TOP_PERCENT: 'Oben "x"%',
        BOTTOM_PERCENT: 'Unten "x"%',
      },
      recordFields: {
        CREATED_AT: 'Erstellt am',
        RECORD_ID: 'Registrierungs-ID',
        DOCUMENT_ID: 'Registrierungs-ID',
        DOLLARS_PER_YEAR: 'Verlust',
        GAS_COMPONENT: 'Gas/Komponente',
        LEAK_TYPE: 'Typ-Profil',
        DATE_TIME: 'Datum/Uhrzeit',
        DB_PEAK: 'DB-Spitze',
        COMPANY: 'Firma',
        NOTES: 'Notizen',
        USER: 'Benutzer',
        EQUIPMENT_TAG: 'Geräte/Komponenten-Tag',
        FLOW: 'Durchfluss (m³/h)',
        EQUIPMENT: 'Einheit/Equipment',
        ROUTE: 'Route',
        ROUTE_LOCATION: 'Routen-Standort',
        PROJECT_SURVEY: 'Audit/Umfrage-Referenz',
        OUTAGE_STATUS: 'Ausfall/Stillstands erforderlich?',
        FACILITY: 'Einrichtung/Standort',
        ACTION_STATUS: 'Aktionsstatus',
        SERVICE_TYPE: 'Service/Typ',
        DB_ACTIVE: 'dB Aktiv',
        REFERENCE_ID: 'Projekt #/Umfrage-ID',
        AMBIENT_TEMPERATURE: 'Umgebungstemperatur (°C)',
        LEAK_PRESSURE: 'Druck (kPag)',
        LEAK_TEMPERATURE: 'Gas Temperatur (°C)',
        LEAK_CATEGORY: 'Kategorie',
        DISTANCE: 'Entfernung (m)',
        GAS_COST: 'Gas-Kosten ($/m³)',
        COMPRESSOR_FLOW: 'm³/h pro BHP',
        DEVICE_ID: 'Geräte-ID',
        COMPRESSOR_BHP: 'Kompressor BHP',
        COMPRESSOR_TYPE: 'Kompressor Typ',
        MOTOR_EFFICIENCY: 'Motor-Effizienz (%)',
        ENERGY_COST: 'Energie-Kosten ($/kWh)',
        HOURS: 'Stunden Betrieb/Jahr',
        LOCATION: 'Ort',
        LAST_MODIFIED: 'Letztes Änderungsdatum',
        POWER_LOAD: '% Volllastleistung',
        CLIENT: 'Kunde',
        SERVICE: 'Service',
        CAPACITY: '% Kapazität',
        EQUIPMENT_TYPE: 'Equipment/Komponenten Typ',
        LEAK_TAG: 'Smart-Tag #/Arbeitsreferenz #',
        REPAIRED_BY: 'Repariert von',
        ALTITUDE: 'Höhe (m)',
        REQUIRED_ACTION: 'Erforderliche Aktion',
        FREQUENCY_LOW_BOUND: 'Frequenz (Niedrig)',
        FREQUENCY_HIGH_BOUND: 'Frequenz (Hoch)',
        SENSITIVITY: 'Sensibilität',
        KWH_PER_YEAR: 'kWh/Jahr',
        ASSIGNED_BY: 'Zugewiesen von',
        ASSIGNED_TO: 'Zugewiesen an',
        ASSIGNED_DATE: 'Zuweisungsdatum',
        LOSS_PER_YEAR: 'Verlust (pro Jahr)',
        ASSIGNED: 'Zugewiesen',
        CREATOR_USER_ID: 'Benutzer-ID',
        CURRENCY: 'Währung',
        COMPLETED_ON: 'Abgeschlossen am',
        IMAGES: 'Quellbilder',
        ACTION_STATUS_MODIFIED: 'Aktionstatus geändert',
        TYPE: 'Typ',
      },
      electricalRecordFields: {
        REGION: 'Region',
        LOCATION_TYPE: 'Standorttyp',
        VOLTAGE: 'Spannung',
        PHASE: 'Phase',
        FREQUENCY: 'Frequenz',
        ELECTRICAL_EQUIPMENT_TAG: 'Ausrüstung/Komponente',
        ELECTRICAL_EQUIPMENT_TYPE: 'Ausrüstung/Komponententyp',
        FAULT_TYPE: 'Fehlertyp',
        ELECTRICAL_NOTES: 'Notizen',
      },
      IMPERIAL_LABELS: {
        FLOW: 'Fluss (cfm)',
        AMBIENT_TEMPERATURE: 'Umgebungstemperatur (°F)',
        LEAK_PRESSURE: 'Druck (psi)',
        LEAK_TEMPERATURE: 'Gastemperatur (°F)',
        DISTANCE: 'Entfernung (ft)',
        GAS_COST: 'Gaskosten ($/ft³)',
        COMPRESSOR_FLOW: 'cfm pro BHP',
        ALTITUDE: 'Höhe (ft)',
      },
      userFields: {
        EMAIL: 'E-Mail',
        ROLE: 'Rolle',
        DEVICE_ID: 'Geräte-ID',
        SUBSCRIPTION_RENEWAL_DATE: 'Datum der Abonnementserneuerung',
        DATE_TIME: 'Erstellt',
        ACTIVE: 'Status',
        SOFTWARE_VERSION: 'Softwareversion',
        FIRMWARE_VERSION: 'Firmwareversion',
        FIRST_NAME: 'Vorname',
        LAST_NAME: 'Nachname',
        COMPANY_SLUG: 'Firma',
        COMPANY_NAME: 'Firma',
      },
      auditFields: {
        documentId: 'Datensatz-ID-Nummer',
        companyName: 'Firmenname',
        auditorName: 'Prüfername',
        auditorEmail: 'E-Mail des Prüfers',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        comment: 'Kommentare',
        isLocked: 'Prüfungssperre',
      },
      deviceFields: {
        DEVICE_ID: 'Geräte-ID',
        ACTIVE: 'Status',
        REGISTERED_ON: 'Aktiviert am',
        EVENT_COUNT: 'Datensätze',
        UNIQUE_COMPANIES: 'Firma',
        LAST_USED_AT: 'Zuletzt verwendet am',
        LAST_USED_BY_NAME: 'Zuletzt verwendeter Name',
        LAST_USED_BY_EMAIL: 'Zuletzt verwendete E-Mail',
        LOCATION: 'Zuletzt verwendeter Standort',
        FIRMWARE_VERSION: 'Firmwareversion',
      },
      statsFields: {
        labels: {
          TIMELINE: 'Zeitleiste',
          CRITERIA: 'Kriterien',
        },
        timeline: {
          LAST_3_MONTHS: 'Letzte 3 Monate',
          LAST_6_MONTHS: 'Letzte 6 Monate',
          LAST_12_MONTHS: 'Letzte 12 Monate',
          YEAR_TO_DATE: 'Jahr bis heute',
          ALL_TIME: 'Gesamte Zeit',
        },
        criteria: {
          LOSS: 'Verlust',
          FLOW: 'Durchfluss',
          KWH: 'kWh',
        },
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: '# Gefundene Lecks',
          NO_OF_LEAKS_REPAIRED: '# Reparierte Lecks',
          PERCENT_LEAKS_REPAIRED: '% Reparierte Lecks',
          VOL_FLOW_FOUND: 'Gefundener Volumenstrom (m³/h)',
          VOL_FLOW_REPAIRED: 'Reparierter Volumenstrom (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '% Reparierter Volumenstrom',
          VALUE_FOUND: 'Gefundener Wert',
          VALUE_REPAIRED: 'Reparierter Wert',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energieeinsparmöglichkeit (gefunden) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Energieeinsparmöglichkeit % (gefunden vs berechnet oder gemessen)',
          ENERGY_SAVING_REALIZED_FIXED: 'Energieeinsparung realisiert (repariert) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: '% Realisierte Energieeinsparung (repariert vs berechnet oder gemessen)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Systemsteuerungsdruck reduziert (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Gefundener Volumenstrom (cfm)',
          VOL_FLOW_REPAIRED: 'Reparierter Volumenstrom (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energieeinsparmöglichkeit (gefunden) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: 'Energieeinsparung realisiert (repariert) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Systemsteuerungsdruck reduziert (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Leistungsindikator',
          ENPI_VALUE: 'EnPI-Wert',
          ENB_VALUE: 'EnB-Wert',
          SUCCESS_THRESHOLD: 'Erfolgsschwelle (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Zugewiesen an',
        assignedBy: 'Zugewiesen von',
        assignedDate: 'Zuweisungsdatum',
        requestReassign: 'Neu zuweisen anfordern',
        actionStatus: 'Aktionsstatus',
        partsNeeded: 'Teil erforderlich',
        productionOutage: 'Abschaltung angefordert',
        timeToRepair: 'Reparaturzeit',
        repairCost: 'Reparaturkosten',
        closeOutRef: 'Abschluss-Referenz',
        'assignmentRecord.documentId': 'Datensatz-ID',
        assignmentId: 'Zuweisungs-ID',
        owner: 'Besitzer / Eigentümer',
        companyName: 'Firma',
        assignmentActive: 'Zuweisungsstatus',
        'assignmentRecord.leakStatus.building': 'Einheit/Ausrüstung',
        'assignmentRecord.leakStatus.facility': 'Einrichtung',
        'assignmentRecord.leakStatus.routeName': 'Route',
        'assignmentRecord.leakStatus.leakTag': 'Smart Tag #/Arbeitsreferenz #',
        'assignmentRecord.currency': 'Währung',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Maßnahme ergriffen',
        CLOSE_OUT_REF: 'Maßnahmenabschluss-Ref.#',
        PARTS_NEEDED: 'Neues Teil/Komponente erforderlich',
        PRODUCTION_OUTAGE: 'Produktion unterbrochen',
        REPAIR_COST: 'Kosten ($)',
        REQUEST_REASSIGN: 'Neuzuweisung anfordern',
        ACTION_STATUS: 'Maßnahmenstatus',
        TIME_TO_REPAIR: 'Zeit für die Reparatur (Minuten)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Durchfluss nach Leckkategorie',
          LOSS_BY_LEAK: 'Verlust nach Leckkategorie',
          TAGS_BY_LEAK: 'Tags nach Leckkategorie',
          AVG_FLOW_BY_LEAK: 'Durchschnittlicher Durchfluss nach Leckkategorie',
          SAVING_OPT_BY_REPAIR: 'Chancen und Einsparungen nach Reparaturzeit',
          FLOW_REPAIR_TIME: 'Durchfluss nach Reparaturzeit',
          LOSS_REPAIR_TIME: 'Verlust nach Reparaturzeit',
          FLOW_REPAIR_PROG: 'Durchfluss nach Reparaturfortschritt',
          TOTAL_REPAIR_PROG: 'Verlust nach Reparaturfortschritt',
          FLOW_BY_EQUIP_COMP: 'Durchfluss nach Gerät/Komponente',
          LOSS_BY_EQUIP_COMP: 'Verlust nach Gerät/Komponente',
          TAGS_BY_EQUIP_COMP: 'Tags nach Gerät/Komponente',
        },
        leakCategoryLabels: {
          LOW: 'Niedrig',
          MEDIUM: 'Mittel',
          HIGH: 'Hoch',
        },
        actionStatus: {
          OPEN: 'Öffnen',
          COMPLETE: 'Komplett',
        },
        equipmentTypes: {
          CONNECTOR: 'Verbinder',
          CONTROL_VALVE: 'Regelventil',
          HOSE: 'Schlauch',
          TUBE_FITTING: 'Rohrverbindung',
          METER: 'Messgerät',
          OPEN_ENDED_LINE: 'Offene Leitung',
          PRESSURE_RELIEF_VALVE: 'Druckentlastungsventil',
          PUMP_SEAL: 'Pumpendichtung',
          REGULATOR: 'Regler',
          VALVE: 'Ventil',
          LEVEL_CONTROLLER: 'Füllstandsregler',
          POSITIONER: 'Steller',
          PRESSURE_CONTROLLER: 'Druckregler',
          TRANSDUCER: 'Wandler',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Generisches pneumatisches Instrument',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Generischer Kolbenkompressor',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Generischer Zentrifugalkompressor',
        },
        mainGasValues: {
          AIR: 'Luft',
          METHANE: 'Methan (CH4)',
          CARBON_DIOXIDE: 'Kohlendioxid (CO2)',
          HYDROGEN: 'Wasserstoff (H2)',
          HELIUM: 'Helium (He)',
          NITROGEN: 'Stickstoff (N2)',
          REFRIGERANT: 'Kältemittel (HFK)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Sauerstoff (O)',
          WATER: 'Wasser (Dampf)',
        },
        leakTypeValues: {
          DEFAULT: 'Keine ausgewählt',
          HOLE: 'Loch',
          CRACK: 'Riss',
          FITTING: 'Anschluss',
          FLANGE: 'Flansch',
          'GLAND/SEAL': 'Dichtung/Dichtung', // Note: If "Gland" has a specific technical term, it should be added or replaced.
          THREAD: 'Gewinde',
          'TUBE/HOSE': 'Rohr/Schlauch',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Druckluft',
          VACUUM: 'Vakuum',
          STEAM: 'Dampf',
          PROCESS_GAS: 'Prozessgas',
          LIQUID: 'Flüssigkeit',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Schraubenverdichter',
          RECIPROCATING: 'Kolbenverdichter',
          SCROLL: 'Spiralverdichter',
          CENTRIFUGAL: 'Zentrifugal',
        },
        regionValues: {
          NORTH_AMERICA: 'Nordamerika',
          OUTSIDE_NORTH_AMERICA: 'Außerhalb von Nordamerika',
        },
        locationTypeValues: {
          RESIDENTIAL: 'Wohngebäude',
          COMMERCIAL: 'Gewerbe',
          INDUSTRIAL: 'Industriell',
          MEDIUM_VOLTAGE: 'Mittelspannung',
          HIGH_VOLTAGE: 'Hochspannung',
        },
        phaseValues: {
          SINGLE_PHASE: 'Einphasig',
          THREE_PHASE: 'Dreiphasig',
        },
        faultValues: {
          SELECT_FAULT_TYPE: 'Wählen Sie den Fehlertyp',
          KNICKS_OR_VOIDS_IN_INSULATION: 'Einkerbungen oder Hohlräume im Isoliermaterial',
          CORONA: 'Corona',
          TREEING: 'Baumbildung',
          TRACKING: 'Verfolgung',
          ARCING: 'Bogen',
          'DIRTY_POWER/POWER_DISTORTION': 'Schmutzstrom/Leistungsstörung',
          MECHANICAL_LOOSENESS: 'Mechanische Lockerheit',
          DELAMINATION_OF_WINDINGS: 'Delaminierung der Wicklungen',
          LOOSE_PANEL: 'Lockerer Panel',
          VIBRATING_NAMEPLATE: 'Vibrierende Namensschild',
          ANTAGONISTIC_ULTRASOUND: 'Antagonistischer Ultraschall',
          OTHER: 'Andere',
        },
        electricEquipmentTypeValues: {
          MCC: 'MCC (Motorsteuerung)',
          'TRANSFORMER,_DRY': 'Transformator, trocken',
          'TRANSFORMER,_OIL': 'Transformator, Öl',
          SWITCH_GEAR: 'Schaltgeräte',
          PANEL: 'Panel',
          BUSDUCT: 'Busduct',
          CABLE: 'Kabel',
          CONNECTOR: 'Verbindungselement',
          BREAKER: 'Schalter',
          BUSHING: 'Isolator',
          TRANSMISSION_LINE: 'Übertragungsleitung',
          'TRANSFORMER,_POLE': 'Transformator, Pol',
          BATTERY: 'Batterie',
          BATTERY_BANK: 'Batteriebank',
          TERMINALS: 'Terminals',
          'TRANSFORMER,_STEP_UP': 'Transformator, Step-up',
          'TRANSFORMER,_STEP_DOWN': 'Transformator, Step-down',
          DISTRIBUTION_PANEL: 'Verteilerpanel',
          BREAKER_PANEL: 'Schalttafelpaneel',
          RELAYS: 'Relais',
          CAPACITORS: 'Kondensatoren',
          REACTORS: 'Reaktoren',
          UPS: 'USV',
          GROUNDING: 'Erdung',
          POWER_METERS: 'Leistungsmesser',
          CURRENT_TRANSFORMERS: 'Stromwandler',
          POTENTIAL_TRANSFORMERS: 'Spannungstransformatoren',
          SURGE_PROTECTION: 'Überspannungsschutz',
          CABLE_TRAYS: 'Kabelrinnen',
          EMERGENCY_LIGHTING: 'Nothilfe',
          CONTACTORS: 'Kontaktoren',
          INVERTERS: 'Umrichter',
          SOLAR_PANELS: 'Solarzellen',
          BATTERY_STORAGE_SYSTEMS: 'Batteriespeichersysteme',
        },
        recordTypes: {
          leak: 'Leck',
          electrical: 'Elektrisch',
        },
        labels: {
          LOW: 'Niedrig',
          MEDIUM: 'Mittel',
          HIGH: 'Hoch',
          EQUIPMENT: 'Ausrüstung',
          SAVINGS: 'Einsparungen',
          OPPORTUNITY: 'Gelegenheit',
          LOSS: 'Verlust',
          REPAIRED: 'Repariert',
          UNREPAIRED: 'Nicht repariert',
          LESS_15_DAYS: 'Weniger als 15 Tage',
          DAYS_16_TO_30: '15 bis 30 Tage',
          DAYS_31_TO_60: '31 bis 60 Tage',
          DAYS_61_TO_90: 'Mehr als 60 Tage',
          LESS_THAN_15_DAYS: 'Repariert in weniger als 15 Tagen',
          '16_TO_30_DAYS': 'Repariert in 16-30 Tagen',
          '31_TO_60_DAYS': 'Repariert in 31-60 Tagen',
          MORE_THAN_60_DAYS: 'Repariert in 61-90 Tagen',
          AIR: 'Luft',
          METHANE: 'Methan (CH4)',
          CARBON_DIOXIDE: 'Kohlendioxid (CO2)',
          HYDROGEN: 'Wasserstoff (H2)',
          HELIUM: 'Helium (He)',
          NITROGEN: 'Stickstoff (N2)',
          REFRIGERANT: 'Kältemittel (HFK)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Sauerstoff (O)',
          WATER: 'Wasser (Dampf)',
          DEFAULT: 'Keine ausgewählt',
          HOLE: 'Loch',
          CRACK: 'Riss',
          FITTING: 'Anschluss',
          FLANGE: 'Flansch',
          'GLAND/SEAL': 'Dichtung/Dichtung',
          THREAD: 'Gewinde',
          'TUBE/HOSE': 'Rohr/Schlauch',
          COMPRESSED_AIR: 'Druckluft',
          VACUUM: 'Vakuum',
          STEAM: 'Dampf',
          PROCESS_GAS: 'Prozessgas',
          LIQUID: 'Flüssigkeit',
          ROTARY_SCREW: 'Schraubenverdichter',
          RECIPROCATING: 'Kolbenverdichter',
          SCROLL: 'Spiralverdichter',
          CENTRIFUGAL: 'Zentrifugal',
          CONNECTOR: 'Verbinder',
          CONTROL_VALVE: 'Regelventil',
          HOSE: 'Schlauch',
          TUBE_FITTING: 'Rohrverbindung',
          METER: 'Messgerät',
          OPEN_ENDED_LINE: 'Offene Leitung',
          PRESSURE_RELIEF_VALVE: 'Druckentlastungsventil',
          PUMP_SEAL: 'Pumpendichtung',
          REGULATOR: 'Regler',
          VALVE: 'Ventil',
          LEVEL_CONTROLLER: 'Füllstandsregler',
          POSITIONER: 'Steller',
          PRESSURE_CONTROLLER: 'Druckregler',
          TRANSDUCER: 'Wandler',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Generisches pneumatisches Instrument',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Generischer Kolbenkompressor',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Generischer Zentrifugalkompressor',
        },
        leakRecordPDF: 'Aufzeichnung • Exportiert am {{date}}',
        leakTag: 'Smart Tags • Exportiert am {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Kompressor-Tag',
          COMPRESSOR_MAKE: 'Kompressorhersteller',
          COMPRESSOR_MODEL: 'Kompressormodell',
          COMPRESSOR_TYPE: 'Kompressortyp',
          NAMEPLATE_HP: 'Nennleistung',
          NAMEPLATE_CAPACITY: 'Nennkapazität (m³/h)',
          MOTOR_EFFICIENCY: 'Motorenffizienz (%)',
          MOTOR_VOLTAGE: 'Motorenspannung',
          FULL_LOAD_CURRENT: 'Nennstrom (Ampere)',
          FULL_LOAD_INPUT_POWER: 'Eingangsleistung bei voller Last (kW)',
          RATED_PRESSURE: 'Nennüberdruck (kPag)',
          PRESSURE_SETPOINT: 'Druckeinstellung (kPag)',
          CONTROL: 'Steuerung',
          TOTAL_AIR_STORAGE_SIZE: 'Gesamtluftspeicherkapazität (l)',
          IS_ACTIVE: 'Kompressor Aktiv / Aus',
          OPERATION_TYPE: 'Trim / Basislast',
          PERCENT_LOAD: '% Last',
          COMPRESSOR_SETPOINT_SEU: 'Kompressor Druckpunkt (kPag)',
          COMPRESSOR_FLOW_SEU: 'Kompressor Durchfluss (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'System Druckeinstellung (kPag)',
          SYSTEM_MEASURED_FLOW: 'System gemessener Durchfluss (m³/h)',
          DATE_TIME_RUNNING_STATE: 'Datum und Uhrzeit des Betriebszustands',
          NOTES: 'Notizen',
          NAMEPLATE: 'Kompressortypenschild',
          DATASHEET: 'Kompressordatentabelle',
          BHP: 'BHP',
          OPERATING_HOURS: 'Betriebsstunden',
          LOADED_PERCENT_TIME_RUNNING: '% Laufzeit',
          LOADED_PERCENT_FULL_LOAD_BHP: '% Vollast BHP (Kapazität)',
          UNLOADED_PERCENT_TIME_RUNNING: '% Laufzeit',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% Vollast BHP (Kapazität)',
          ENERGY_COST: 'Aggregierter Strompreis ($)',
          ANNUAL_ENERGY_COST: 'Jährliche Energiekosten ($)',
          ANNUAL_ENERGY_KWH: 'Jährliche Energie kWh',
          COST_FULLY_LOADED: 'Kosten bei voller Beladung ($)',
          COST_UNLOADED: 'Kosten bei Entladung ($)',
          KWH_PER_YEAR_LOADED: 'kWh pro Jahr bei voller Beladung',
          KWH_PER_YEAR_UNLOADED: 'kWh pro Jahr bei Entladung',
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Nennkapazität (cfm)',
          FULL_LOAD_INPUT_POWER: 'Eingangsleistung bei voller Last (BTU/h)',
          RATED_PRESSURE: 'Nennüberdruck (psi)',
          PRESSURE_SETPOINT: 'Druckeinstellung (psi)',
          TOTAL_AIR_STORAGE_SIZE: 'Gesamtluftspeicherkapazität (gal)',
          COMPRESSOR_SETPOINT_SEU: 'Kompressor Druckpunkt (psi)',
          COMPRESSOR_FLOW_SEU: 'Kompressor Durchfluss (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'System Druckeinstellung (psi)',
          SYSTEM_MEASURED_FLOW: 'System gemessener Durchfluss (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: 'Energieversorger',
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: 'Einheit',
          REBATE_COST: '$/kWh',
          REBATE_UNIT: 'Einheit',
          ENERGY_SOURCES: 'Energiequellen',
          FE_HOURS_OPERATION: 'Betriebsstunden',
          FE_ENERGY_COST: 'Energiekosten ($/kWh)',
          FE_EFFICIENCY: 'Effizienz (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: 'Effizienz (kW/cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'Fehler bei der Anforderungsvalidierung. Bitte überprüfen Sie die Formularfelder',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Gerät registrieren',
          help: 'Hilfe',
          metric: 'Metrisch',
          imperial: 'Imperial',
          downloadApk: 'APK herunterladen',
          downloadOta: 'Datei herunterladen',
        },
        otaDialog: {
          title: 'Datei herunterladen',
          downloadButton: 'Herunterladen',
          serialNumberLabel: 'Geräte-Seriennummer',
          serialNumberRequired: 'Seriennummer erforderlich',
          fileDownloaded: 'Datei {{fileName}} erfolgreich heruntergeladen',
          errorFetchingFile: 'Fehler beim Abrufen der Datei. Bitte versuchen Sie es später erneut',
          selectSerialNumber: 'Wählen Sie eine Geräte-Seriennummer',
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'Sie sind im Essential-Plan.',
            dialogTwo: 'Klicken Sie',
            dialogThree: 'hier',
            dialogFour: 'um eine neue Admin-Lizenz zu erwerben, oder kontaktieren Sie ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable:
            'Wir haben technische Schwierigkeiten. Unser Service könnte betroffen sein. Entschuldigung für die Unannehmlichkeiten.',
          downForMaintenance: 'Wir führen Wartungsarbeiten durch. Wir sind bald wieder da!',
          tagsManagement: {
            dialogOne: 'Mehr kaufen',
            dialogTwo: 'PROSARIS intelligente Tags',
            dialogThree: 'hier！',
          },
        },
        mobileNav: {
          index: {
            menu: 'Menü',
            editProfile: 'Profil bearbeiten',
            logout: 'Abmelden',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Alles löschen',
            readAll: 'Alles als gelesen markieren',
            noNotifications: 'Keine Benachrichtigungen',
          },
        },
        index: {
          dashboard: 'Dashboard',
          records: 'Aufzeichnungen',
          actions: 'Aktionen',
          company: 'Firma',
          tags: 'Tags',
        },
        loggedInAs: 'Angemeldet als',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: 'Etikett kann nicht durch Feld {{field}} bestimmt werden',
        cannontDeterminByLabel: 'Feld kann nicht durch Etikett {{label}} bestimmt werden',
        active: 'Aktiv',
        closed: 'Geschlossen',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Dashboard',
        records: 'Aufzeichnungen',
        actions: 'Aktionen',
        company: 'Firma',
        tags: 'Tags',
        redirecting: 'Sie werden weitergeleitet...',
      },
      account: {
        index: {
          updatePassword: 'Passwort erfolgreich aktualisiert.',
          accountDeletedSuccess: 'Konto {{user}} wurde erfolgreich gelöscht',
          accountDeletedError: 'Konto kann derzeit nicht gelöscht werden. Bitte versuchen Sie es später erneut.',
          authenticationError: 'Benutzer konnte nicht authentifiziert werden',
          personalDetails: 'Persönliche Daten',
          currentPasswordIsRequired: 'Aktuelles Passwort ist erforderlich',
          username: 'Benutzername',
          changePassword: 'Passwort ändern',
          currentPassword: 'Aktuelles Passwort',
          profilePicture: 'Profilbild',
          dangerZone: 'Gefahrenzone',
          integrations: 'Integrationen',
          sosShipments: 'SOS Inventar',
          sosSyncMessage:
            'Dieser Prozess aktiviert die SOS-Auftragsverarbeitung in Prosaris mit den neuesten Daten aus dem SOS Inventar. Während dieser Prozess normalerweise alle 4 Stunden durch eine geplante Aufgabe automatisch verwaltet wird, ermöglicht das Drücken dieser Taste das manuelle Auslösen des Prozesses.',
          sosSyncSuccess: 'SOS-Synchronisierung wurde erfolgreich ausgelöst',
          triggerSosSync: 'SOS-Synchronisierung auslösen',
          hubspot: 'HubSpot',
          hubspotSyncMessage:
            'Dieser Prozess stößt die neuesten Daten aus der Plattform sofort zu HubSpot. Diese Synchronisierung erfolgt automatisch jede Stunde über eine geplante Aufgabe, aber durch das Klicken auf die Schaltfläche können Sie die Synchronisierung manuell auslösen, wann immer Sie möchten.',
          hubspotSyncSuccess: 'HubSpot-Synchronisierung wurde erfolgreich ausgelöst',
          triggerHubspotSync: 'HubSpot-Synchronisierung auslösen',
          deleteAccountTitle: 'Konto löschen',
          deleteAccountMessage: 'Das Konto wird nicht mehr verfügbar sein und alle persönlichen Informationen werden dauerhaft gelöscht.',
          alertTitle: 'Bitte bestätigen Sie die Kontolöschung',
          deleteDialog: {
            dialogTwo: 'Das Löschen Ihres Kontos wird alle Ihre persönlichen Informationen dauerhaft aus unserer Datenbank entfernen.',
            dialogThree: 'DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.',
            dialogFour: 'Bitte geben Sie Ihr Passwort ein, um fortzufahren.',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'Cache erfolgreich geleert!',
          editProfile: 'Profil bearbeiten',
          clearCache: 'Cache leeren',
          alertTitle: 'Bitte bestätigen Sie das Leeren des App-Caches',
          deleteAll: 'Alles löschen',
          accordionDialog: {
            dialogOne: 'Dies wird alle gespeicherten Einstellungen für die folgenden Seiten löschen:',
            dialogTwo: 'Diagramme, Diagrammfilter, Zeitrahmen',
            dialogThree: 'Filter, Sortierung, Spalten, Vorschau',
            dialogFour: 'Filter, Spalten',
            dialogFive: 'Ausgewähltes Firma',
            dialogSix: 'Filter, Spalten',
          },
          deleteSelective: 'Selektiv löschen',
          selectiveDialog: 'Bitte wählen Sie die gespeicherten Einstellungen aus, die Sie löschen möchten:',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Zeitrahmen',
            CHART_FILTERS: 'Diagrammfilter',
            CHARTS: 'Diagramme',
          },
          Records: {
            HIGHLIGHTED: 'Vorschau',
            COLUMNS: 'Spalten',
            FILTERS: 'Filter',
            SORT_COLUMN: 'Sortierung',
          },
          Actions: {
            FILTERS: 'Filter',
            COLUMNS: 'Spalten',
          },
          Company: {
            SELECTED: 'Ausgewähltes Firma',
          },
          Tags: {
            FILTERS: 'Filter',
            COLUMNS: 'Spalten',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Firma',
          'leakStatus.building': 'Einheit/Ausrüstung',
          'leakStatus.facility': 'Einrichtung',
          'leakStatus.leakTag': 'Smart-Tag #/Arbeitsreferenz #',
        },
        tableBody: {
          noAssignmentsToDisplay: 'Keine Zuweisungen anzuzeigen',
        },
        filtersCommonWords: {
          recordsFilter: 'Datensatzfilter',
          column: 'Spalte',
          selectAColumn: 'Wählen Sie eine Spalte...',
          addSearchTerm: 'Suchbegriff hinzufügen...',
          filtersArentAvailable: 'Filter sind nicht verfügbar',
          value: 'Wert',
          exportCSV: 'CSV exportieren',
          show: 'Anzeigen',
          loadMoreRecords: 'Mehr Datensätze laden',
          selectedAllRecords: 'Alle Datensätze ausgewählt ({{totalNum}})',
          selectedFromRecords: '{{selectedNum}} von {{totalNum}} Datensätzen ausgewählt',
          reset: 'Zurücksetzen',
        },
        invalidColumn: 'Ungültige Spalte {{column}}',
        assignedActionRecords: 'Zugewiesene Aktionsdatensätze',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'Passwort vergessen?',
          successMessage: 'Wir haben eine Bestätigungs-E-Mail gesendet an',
        },
        authSuccess: {
          thanks: 'Danke!',
          backToLogin: 'Zurück zur Anmeldung',
        },
        loginView: {
          forgotPass: 'Passwort vergessen?',
          createAccount: 'Ein Konto erstellen',
          passwordIsRequired: 'Passwort ist erforderlich',
          resetPassword: 'Passwort zurücksetzen',
          contactSupport: 'Bitte kontaktieren Sie support@prosaris.ca',
          incorrectEmailOrPassword: 'Falsche E-Mail oder Passwort',
          resetPassDialog: {
            dialogOne: 'Sie müssen Ihr Passwort zurücksetzen, bevor Sie fortfahren können.',
            dialogTwo: 'Eine E-Mail zum Zurücksetzen des Passworts wurde Ihnen mit Anweisungen gesendet.',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'Das Passwort wurde erfolgreich zurückgesetzt',
          enterNewPassword: 'Geben Sie unten Ihr neues Passwort ein.',
          passwordChanged: 'Ihr Passwort wurde geändert.',
        },
        createCompanyView: {
          createCompany: 'Ein Firma gründen',
          companyName: 'Firmenname',
          successCreatingCompany: 'Ihr Firma wurde erstellt.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Unerwarteter Fehler beim Laden der Leckdaten',
          calculatingError: 'Unerwarteter Fehler bei der Berechnung',
          dbMeasured: 'Gemessener Db',
          calculate: 'Berechnen',
          calculating: 'Berechnung läuft...',
          outflowCoefficient: 'Abflusskoeffizient',
          kwhPerYear: 'Kwh/Jahr',
        },
      },
      companies: {
        commonUsedWords: {
          assignedAdminLicenses: 'Zugewiesene Admin-Lizenzen',
          company: 'Firma',
          totalAccounts: 'Gesamtkonten',
          email: 'E-Mail',
          name: 'Name',
          loadCompanyUsersError: 'Laden der Firmenbenutzer fehlgeschlagen. Bitte versuchen Sie es später noch einmal',
          activated: 'Aktiviert',
          deactivated: 'Deaktiviert',
          activating: 'Aktivierung läuft',
          deactivating: 'Deaktivierung läuft',
          deactivate: 'Deaktivieren',
          activate: 'Aktivieren',
          set: 'Festlegen',
          youAreGoingTo: 'Sie werden',
          areYouSure: 'Sind Sie sicher?',
          companyNameIsRequired: 'Der Firmenname ist erforderlich',
          editCompany: 'Firma bearbeiten',
          createCompany: 'Firma erstellen',
          viewHistory: 'Verlauf anzeigen',
          onEssentialPlan: 'Sie sind auf einem Basisplan. Bitte kontaktieren Sie support@prosaris.ca',
          exportCSV: 'CSV exportieren',
        },
        groupPerformance: {
          title: 'Gruppenleistung',
          normalizeData: 'Daten Normalisieren',
          selectTimeline: 'Zeitachse Wählen',
          selectCriteria: 'Kriterien Wählen',
        },
        charts: {
          chart: {
            criteria: {
              KWH: 'Gesamte kWh/Jahr',
              LOSS: 'Gesamter Verlust ($/Jahr)',
              FLOW: 'Gesamter Massenstrom',
            },
            noDataAvailable: 'Keine Daten verfügbar',
            topNCompanies: 'Top-{{numCompanies}} Firma',
          },
        },
        accounts: {
          accountDialog: {
            companyIsRequired: 'Firma ist erforderlich',
            roleIsRequired: 'Rolle ist erforderlich',
            createAccountSuccessMessage: 'Konto erfolgreich erstellt',
            emailTakenErrorMessage: 'Ein Konto mit dieser E-Mail existiert bereits im System',
            errorWhileCreatingAccountMessage: 'Fehler beim Erstellen eines Kontos. Bitte versuchen Sie es später noch einmal.',
            changeAccountSuccessMessage: 'Konto erfolgreich aktualisiert',
            errorWhileChangingAccountMessage: 'Fehler beim Aktualisieren des Kontos. Bitte versuchen Sie es erneut.',
            editAccount: 'Konto bearbeiten',
            createAccount: 'Konto erstellen',
            role: 'Rolle',
          },
          changeOwner: {
            changeOwnerMessage: 'Möchten Sie auch den Besitzer der mit diesem Konto verbundenen Leckdatensätze ändern?',
            no: 'Nein, aktuelles Konto behalten',
          },
          contentCell: {
            loadAccountRolesError: 'Kontorolleninformationen konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal',
            changeRoleSuccessMessage: 'Konto {{email}} hat eine neue Rolle',
            changeRoleErrorMessage: 'Fehler beim Aktualisieren der Rolle für Konto {{email}}. Bitte versuchen Sie es später noch einmal',
            editUser: 'Benutzer bearbeiten',
            adminSetPassword: 'Administrator setzt Passwort',
            adminSetSubRenewalDate: 'Vom Administrator festgelegtes Abonnementserneuerungsdatum',
            resetPassword: 'Passwort zurücksetzen',
            user: 'Benutzer',
            deleteUser: 'Benutzer löschen',
          },
          index: {
            noUsersToDisplay: 'Keine Benutzer anzuzeigen',
            changeStatusSuccessMessage: 'Benutzer {{isActive}} erfolgreich',
            changeStatusErrorMessage: 'Fehler bei {{isActive}} Benutzer. Bitte versuchen Sie es erneut',
            changeOwnerSuccessMessage: 'Datensätze wurden an einen neuen Eigentümer übertragen',
            changeOwnerErrorMessage:
              'Datensätze können nicht an einen neuen Besitzer übertragen werden. Bitte versuchen Sie es später noch einmal',
            deleteAccountMutationSuccessMessage: 'Konto {{email}} wurde erfolgreich gelöscht',
            deleteAccountMutationErrorMessage:
              'Konto {{email}} kann im Moment nicht gelöscht werden. Bitte versuchen Sie es später noch einmal',
            resetAccountPassWordSuccessMessage: 'Passwort-Reset-E-Mail erfolgreich gesendet',
            users: 'Benutzer',
            noneAvailable: 'Keine verfügbar',
            of: 'von',
            contactSupport: 'Bitte kontaktieren Sie support@prosaris.ca für zusätzliche Informationen über Firmen-Administrator-Lizenzen',
            findAccount: 'Konto finden',
            placeholder: 'Name oder E-Mail eingeben...',
            tooltip: 'Sie benötigen mindestens eine Firmenadministrator-Lizenz, bevor Sie Benutzer einladen können',
            invite: 'Einladen',
            alertDialog: {
              messageOne: 'Bitte bestätigen Sie die Passwort-Reset-Anfrage des Benutzers',
              messageTwo: 'Bitte bestätigen Sie die Änderung des Kontostatus',
              messageThree: 'Bitte bestätigen Sie die Löschung des Kontos',
            },
            sendAPasswordResetEmail: 'Sie werden eine Passwort-Reset-E-Mail für das folgende Konto senden:',
            confirmMessage: 'Möchten Sie fortfahren?',
            followingAccount: 'folgendes Konto:',
            delete: 'LÖSCHEN',
            userCreatedDialogTitle: 'Link zum Zurücksetzen des Benutzerpassworts erfolgreich gesendet',
            userCreatedDialogDescription:
              'Dem Benutzer wurde ein Link zum Zurücksetzen des Passworts an seine E-Mail-Adresse gesendet. Sie können den Link auch unten kopieren.',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'Kontopasswort erfolgreich aktualisiert',
            changedUserPasswordErrorMessage: 'Fehler beim Aktualisieren des Kontos. Bitte versuchen Sie es erneut.',
            setNewPassword: 'Neues Passwort festlegen',
            accountPasswordUpdated: 'Kontopasswort aktualisiert',
            accountPasswordUpdatedDescription:
              'Das Kontopasswort wurde erfolgreich aktualisiert. Sie können auf die Schaltfläche unten klicken, um das neue Passwort zu kopieren.',
          },
          adminSetSubRenewalDate: {
            title: 'Abonnementserneuerungsdatum festlegen',
            subRenewalDate: 'Abonnementserneuerungsdatum',
            changedSubRenewalDateSuccessMessage: 'Abonnementserneuerungsdatum erfolgreich aktualisiert',
            changedSubRenewalDateErrorMessage: 'Fehler beim Aktualisieren des Kontos. Bitte versuchen Sie es erneut.',
          },
          setPassword: {
            automatic: 'Ein starkes Passwort automatisch generieren',
            manual: 'Passwort manuell erstellen',
            copyLinkInstruction: 'Sie können das Passwort im nächsten Schritt anzeigen und kopieren',
          },
          userCreatedDialog: {
            title: 'Benutzer erfolgreich erstellt',
            description:
              'Ihr neuer Benutzer kann nun die Plattform nutzen. Der Benutzer erhält außerdem einen Link per E-Mail, um sein Passwort zurückzusetzen.',
            passwordCopied: 'Passwort in die Zwischenablage kopiert',
            passwordResetLinkCopied: 'Link zum Zurücksetzen des Passworts in die Zwischenablage kopiert',
            copyPassword: 'Passwort kopieren',
            copyPasswordResetLink: 'Link zum Zurücksetzen des Passworts kopieren',
          },
          useRoleOptions: {
            notAvailable: '(nicht verfügbar)',
            adminSeatTaken: '(Admin-Sitz ist besetzt)',
            allSeatsAreTaken: '(alle {{numSeats}} Sitze sind besetzt)',
            adminRolesTitle: 'Admin-Rollen',
            roles: {
              superAdmin: 'Hauptadministrator',
              companyAdmin: 'Firma (Admin)',
              superCompanyAdmin: 'Unternehmen (Admin)',
              companyRead: 'Firma (Lesen)',
              superAdminRead: 'Hauptadministrator (nur Lesen)',
              integrator: 'Integrator',
              auditor: 'Auditor',
              user: 'Benutzer',
              essential: 'Prosaris Essential',
            },
            rolesDescriptions: {
              superAdmin: 'Darf volle Kontrolle über mehrere Firma haben.',
              companyAdmin: 'Darf Firmenaufzeichnungen anzeigen, bearbeiten und zuweisen.',
              superCompanyAdmin: 'Darf volle Kontrolle über Firmenaufzeichnungen und Benutzer haben.',
              companyRead: 'Darf Firmenaufzeichnungen im Nur-Lese-Modus anzeigen.',
              superAdminRead: 'Darf Nur-Lese-Zugriff auf alle Firma haben.',
              integrator: 'Ermöglicht Drittanbieter-Integrationen zur Verwaltung von Firma und Konten.',
              auditor: 'Zur Ermöglichung der Prüfung von Leckaufzeichnungen.',
              user: 'Darf eigene Aufzeichnungen erstellen/aktualisieren/löschen.',
              essential: 'Eine Rolle mit eingeschränkter Funktionalität.',
            },
            nonAdminRolesTitle: 'Nicht-Admin-Rollen',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Laden der Audits fehlgeschlagen. Bitte versuchen Sie es später erneut',
            audits: 'Audits',
            noAuditsToDisplay: 'Keine Audits zum Anzeigen',
            findByAuditorEmail: 'Nach Auditor-E-Mail suchen',
            placeholder: 'Auditor-E-Mail eingeben...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: 'Anlagenname ist erforderlich',
              hoursYearlyIsRequired: 'Gesamtstunden/Jahr sind erforderlich',
              totalHoursYearly: 'Gesamtstunden/Jahr dürfen 8760 nicht überschreiten',
              invalidPhoneNumber: 'Ungültige Telefonnummer',
              createErrorMessage: 'Stunden x Schichten dürfen 24 Stunden nicht überschreiten',
              loadFacilityErrorMessage: 'Laden der Anlage fehlgeschlagen. Bitte versuchen Sie es später erneut',
              createFacilitySuccessMessage: 'Anlage erfolgreich erstellt',
              createFacilityErrorMessage: 'Fehler beim Erstellen der Anlage. Bitte versuchen Sie es später erneut.',
              changeFacilitySuccessMessage: 'Anlage erfolgreich aktualisiert',
              changeFacilityErrorMessage: 'Fehler beim Aktualisieren der Anlage. Bitte versuchen Sie es erneut.',
              facilityName: 'Anlagenname',
              abbreviation: 'Abkürzung',
              address: 'Adresse',
              enterALocation: 'Standort eingeben',
              tooltip: 'für Energiemanagementaktivitäten, auch Berichtsgenehmiger',
              energyMgtOwner: 'Energiemgmt. Besitzer',
              title: 'Titel',
              phone: 'Telefon',
              tooltipTwo: 'für Lösungserneuerungen und allgemeine Kontoangelegenheiten',
              accountOwner: 'Kontoinhaber',
              manufacturingArea: 'Produktionsbereich',
              size: 'Größe',
              unit: 'Einheit',
              hoursOfOperation: 'Betriebsstunden',
              auditFrequency: 'Audit-Frequenz',
            },
            operationHours: {
              daysPerWeek: 'Tage/Woche',
              shiftsPerDay: 'Schichten/Tag',
              hoursPerShift: 'Stunden/Schicht',
              weeksPerYear: '52 Wochen/Jahr',
              totalHoursYearly: 'Gesamt = Stunden/Jahr',
            },
            constants: {
              constant: 'Konstant',
              monthly: 'Monatlich',
              threeMonth: '3 Monate',
              fourMonth: '4 Monate',
              sixMonth: '6 Monate',
              nineMonth: '9 Monate',
              annual: 'Jährlich',
              sqft: 'Quadratfuß [SQFT]',
              sqm: 'Quadratmeter [SQM]',
            },
          },
          index: {
            users: 'Benutzer',
            devices: 'Geräte',
            facility: 'Anlage',
            energy: 'Energie',
            seu: 'SEUs - Comp.',
            enpi: 'EnPIs/EnBs',
            routes: 'Routen',
            audit: 'Audits',
            comingSoon: 'Demnächst...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'Fehler beim Laden der EnPIs. Bitte versuchen Sie es später erneut',
            createEnpisSuccessMessage: 'EnPIs erfolgreich erstellt',
            createEnpisErrorMessage: 'Fehler beim Erstellen der EnPIs. Bitte versuchen Sie es später erneut.',
            changeEnpisSuccessMessage: 'EnPIs erfolgreich aktualisiert',
            changeEnpisErrorMessage: 'Fehler beim Aktualisieren der EnPIs. Bitte versuchen Sie es erneut.',
            showHistory: 'Verlauf anzeigen',
          },
          changeHistory: {
            bulkOperation: 'als Massenoperation',
            details: 'Details',
            created: 'erstellt',
            updated: 'aktualisiert',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'Verlauf',
            noHistory: 'Kein Verlauf',
          },
        },
        energy: {
          index: {
            utilProvider: 'Energieversorger',
            energyUse: 'Energieverbrauch und Verbrauchstyp',
            error: {
              loadFacility: 'Einrichtung konnte nicht geladen werden. Bitte versuchen Sie es später erneut',
              loadEnergy: 'Energiedaten konnten nicht geladen werden. Bitte versuchen Sie es später erneut',
              loadUtilProviderLogo: 'Logo des Energieversorgers konnte nicht geladen werden. Bitte versuchen Sie es später erneut',
              createEnergy: 'Energiedaten konnten nicht erstellt werden. Bitte versuchen Sie es später erneut',
              createUtilProviderLogo: 'Logo des Energieversorgers konnte nicht hochgeladen werden. Bitte versuchen Sie es später erneut',
              updateEnergy: 'Energiedaten konnten nicht aktualisiert werden. Bitte versuchen Sie es später erneut',
              deleteUtilProviderLogo: 'Logo des Energieversorgers konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut',
            },
            success: {
              createEnergy: 'Energiedaten erfolgreich erstellt',
              updateEnergy: 'Energiedaten erfolgreich aktualisiert',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: 'Energiekosten',
              energySources: 'Energiequellen',
              incentiveRate: 'Anreizrate',
              per: 'pro',
              validations: {
                utilProviderIsRequired: 'Energieversorger ist erforderlich',
                costOfEnergyIsRequired: 'Energiekosten sind erforderlich',
                costOfEnergyMustBeNumber: 'Energiekosten müssen eine Zahl sein',
                costOfEnergyMustBeGreaterThanZero: 'Energiekosten müssen größer 0 sein',
                costOfRebateMustBeAtLeatZero: 'Die Rückerstattungskosten müssen mindestens 0 betragen',
                costOfRebateMustBeNumber: 'Die Rückerstattungskosten müssen eine Zahl sein',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: 'Dateigröße muss weniger als 1 MB betragen',
                preview: 'Vorschau des Bildes fehlgeschlagen',
              },
              logo: 'Logo',
              addLogo: 'Logo hinzufügen',
              removeLogo: 'Logo entfernen',
            },
            EnergySources: {
              energySource: 'Energiequelle',
              percentage: 'Prozentsatz',
              selectEnergySource: 'Energiequelle auswählen',
              addEnergySource: 'Energiequelle hinzufügen',
              totalPercentage: 'Gesamtprozentsatz',
              mustBeEqualTo100: '(muss gleich 100% sein)',
              constants: {
                energySources: {
                  NATURAL_GAS: 'Erdgas',
                  FUEL_OIL: 'Heizöl',
                  DIESEL: 'Diesel',
                  GASOLINE: 'Benzin',
                  PROPANE: 'Propan',
                  COAL: 'Kohle',
                  WIND: 'Wind',
                  GEOTHERMAL: 'Geothermie',
                  BIOMASS: 'Biomasse',
                  STEAM: 'Dampf',
                  COMPRESSED_AIR: 'Druckluft',
                  CHILLED_WATER: 'Kaltwasser',
                  NUCLEAR: 'Kernenergie',
                  HYDRO: 'Wasserkraft',
                  SOLAR: 'Solar',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: 'Aktueller Berechnungsansatz',
              currentCalcApproachMessage:
                "Dieser Berechnungsansatz wird standardmäßig auf alle Felder 'Kosten der Energie' angewendet, es sei denn, der Benutzer hat ihn überschrieben.",
              alertTitle: 'Berechnungsansatz zurücksetzen',
              alertMessageOne:
                "Diese Aktion wird die Verwendung des aktuellen Berechnungsansatzes für alle 'Energiekosten'-Felder in Zukunft stoppen.",
              alertMessageTwo: 'Bitte beachten Sie, dass diese Änderung keine bestehenden Datensätze beeinflusst.',
              alertMessageThree: 'Sind Sie sicher, dass Sie mit dem Zurücksetzen fortfahren möchten?',
            },
            ReportType: {
              calcApproach: 'Berechnungsansatz',
              constants: {
                calculations: {
                  FIXED: 'Feste Effizienz',
                  USDOE: 'US DoE',
                  NREL: 'NREL Kapitel 22',
                },
              },
              setAsCurrent: 'Als Aktuell festlegen',
              setAsCurrentMessage: "Dieser Berechnungsansatz wird in Zukunft für alle Felder 'Kosten der Energie' verwendet.",
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: 'Effizienz muss größer oder gleich 0 sein',
                  efficiencyMustBeNumber: 'Effizienz muss eine Zahl sein',
                  operationHoursMustBeGreaterThanZero: 'Betriebsstunden müssen größer oder gleich 0 sein',
                  operationHoursMustBeLessThan8760: 'Betriebsstunden müssen weniger als 8760 sein',
                  operationHoursMustBeNumber: 'Betriebsstunden müssen eine Zahl sein',
                },
              },
              UsDoe: {
                loaded: 'Beladen',
                unloaded: 'Entladen',
                calcSummary: 'Berechnungszusammenfassung',
                currentOperatingSystems: 'Aktuelle Betriebssysteme',
                noCompsFoundAddCompOne: 'Keine Kompressoren gefunden. Fügen Sie einen Kompressor von der',
                noCompsFoundAddCompTwo: 'oberen Registerkarte hinzu.',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Fehler beim Hochladen des Typenschilds. Bitte versuchen Sie es später noch einmal.',
            dataSheetError: 'Fehler beim Hochladen des Datenblatts. Bitte versuchen Sie es später noch einmal.',
            createSuccess: 'Kompressor {{tag}} erstellt',
            createError: 'Kompressor konnte nicht erstellt werden. Bitte versuchen Sie es später noch einmal.',
            createCompressor: 'Kompressor erstellen',
          },
          compressorFile: {
            tip: 'Datei hinzufügen',
            removeFile: 'Datei entfernen',
            removeImage: 'Bild entfernen',
            title: 'Bitte bestätigen Sie die Löschung des Kompressors {{prop}}',
            proceed: 'Möchten Sie fortfahren?',
          },
          compressorForm: {
            compTagIsReq: 'Kompressor-Tag ist erforderlich',
            hpMustBeNum: 'Die Nennleistung muss eine Zahl sein',
            capMustBeNum: 'Die Nennkapazität muss eine Zahl sein',
            motorEffMustBeNum: 'Der Motorenwirkungsgrad muss eine Zahl sein',
            motorVoltMustBeNum: 'Die Motorspannung muss eine Zahl sein',
            loadMustBeNum: 'Die Nennstromstärke muss eine Zahl sein',
            loadInputMustBeNum: 'Die Eingangsleistung bei voller Last muss eine Zahl sein',
            pressureMustBeNum: 'Der Nennüberdruck muss eine Zahl sein',
            setpointMustBeNum: 'Der Drucksollwert muss eine Zahl sein',
            airMustBeNum: 'Die Gesamtluftspeicherkapazität muss eine Zahl sein',
            addImage: 'Bild hinzufügen',
            addFile: 'Datei hinzufügen',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Schraubenverdichter',
              RECIPROCATING: 'Kolbenverdichter',
              CENTRIFUGAL: 'Zentrifugal',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Laden/Entladen – Ölfrei',
              loadUnload1Gal: 'Laden/Entladen (1 Gal/CFM)',
              loadUnload3Gal: 'Laden/Entladen (3 Gal/CFM)',
              loadUnload5Gal: 'Laden/Entladen (5 Gal/CFM)',
              loadUnload10Gal: 'Laden/Entladen (10 Gal/CFM)',
              ivmWithBlowdown: 'Einlassventilmodulation (ohne Abblasen)',
              ivmWithoutBlowdown: 'Einlassventilmodulation (mit Abblasen)',
              variableDisplacement: 'Variable Verdrängung',
              vsdWithUnloading: 'Drehzahlregelung mit Entlastung',
              vsdWithStopping: 'Drehzahlregelung mit Stopp',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'Ein/Aus-Steuerung',
              reciprocatingLoadUnload: 'Laden/Entladen',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV mit Abblasen',
              centrifugalInletIbv: 'IGV mit Abblasen',
            },
          },
          contentCell: {
            min: '% muss mindestens 0 sein',
            max: '% darf nicht mehr als 100 sein',
            max150: '% darf nicht mehr als 150 sein',
            typeError: 'Die Last muss ein gültiger Prozentsatz sein',
            setpointMustBeNum: 'Der Kompressor-Sollwert muss eine Zahl sein',
            flowMustBeNum: 'Der Kompressor-Durchfluss muss eine Zahl sein',
            pressureSetpointMustBeNum: 'Der Systemdruck-Sollwert muss eine Zahl sein',
            measuredFlowMustBeNum: 'Der gemessene Systemdurchfluss muss eine Zahl sein',
            dateTime: 'Wählen Sie Datum und Uhrzeit',
            active: 'AKTIV',
            off: 'AUS',
            trim: 'Trimmen',
            baseLoad: 'Grundlast',
          },
          index: {
            error: 'Kompressoren konnten nicht geladen werden. Bitte versuchen Sie es später erneut',
            nameplateError: 'Typenschild konnte nicht geladen werden. Bitte versuchen Sie es später erneut',
            dataSheetError: 'Datenblatt konnte nicht geladen werden. Bitte versuchen Sie es später erneut',
            changeCompSuccess: 'Kompressor erfolgreich aktualisiert',
            changeCompError: 'Fehler beim Aktualisieren des Kompressors. Bitte versuchen Sie es erneut.',
            deleteCompSuccess: 'Kompressor erfolgreich gelöscht',
            deleteCompError: 'Fehler beim Löschen des Kompressors. Bitte versuchen Sie es erneut.',
            createCompNameplateSuccess: 'Kompressor-Typenschild erfolgreich hochgeladen',
            createCompNameplateError: 'Fehler beim Hochladen des Typenschilds. Bitte versuchen Sie es später erneut.',
            deleteCompNameplateSuccess: 'Kompressor-Typenschild erfolgreich gelöscht',
            deleteCompNameplateError: 'Fehler beim Löschen des Kompressor-Typenschilds. Bitte versuchen Sie es erneut.',
            createCompDataSheetSuccess: 'Kompressordatentabelle erfolgreich hochgeladen',
            createCompDataSheetError: 'Fehler beim Hochladen der Datentabelle. Bitte versuchen Sie es später erneut.',
            deleteCompDataSheetSuccess: 'Kompressordatentabelle erfolgreich gelöscht',
            deleteCompDataSheetError: 'Fehler beim Löschen der Kompressordatentabelle. Bitte versuchen Sie es erneut.',
            changeCompanyCompSuccess: 'Systemdaten erfolgreich aktualisiert',
            changeCompanyCompError: 'Fehler beim Aktualisieren der Systemdaten. Bitte versuchen Sie es erneut.',
            compDesignData: 'Kompressordesign-Daten',
            comp: 'Kompressoren:',
            addComp: 'Kompressor hinzufügen',
            editCompPermission: 'Sie benötigen eine Administratorlizenz, um den Kompressor zu bearbeiten. Bitte kontaktieren Sie',
            selectOption: 'Wählen Sie eine Option',
            undoChanges: 'Änderungen rückgängig machen',
            selectComp: 'Wählen Sie einen Kompressor aus oben.',
            noCompsFound: 'Keine Kompressoren gefunden.',
            compRunningState: 'Betriebszustand des Kompressors',
            noCompsFoundAddComp: 'Keine Kompressoren gefunden. Fügen Sie einen Kompressor aus dem obigen Abschnitt hinzu.',
            title: 'Bitte bestätigen Sie das Löschen des Kompressors',
            areYouSure: 'Sind Sie sicher, dass Sie',
            compressor: 'Kompressor',
            delete: 'löschen möchten',
          },
        },
        devices: {
          contentCell: {
            device: 'Gerät',
            usedByMultipleCompanies: 'wird von mehreren Firma verwendet',
          },
          index: {
            noDevicesToDisplay: 'Keine Geräte zum Anzeigen',
            loadingDevicesErrorMessage: 'Laden der Firmengeräte fehlgeschlagen. Bitte versuchen Sie es später erneut',
            changeStatusSuccessMessage: 'Gerät {{isActive}} erfolgreich',
            changeStatusErrorMessage: 'Fehler bei {{isActive}} Gerät. Bitte versuchen Sie es erneut',
            devices: 'Geräte',
            findDevice: 'Gerät suchen',
            placeholder: 'Geben Sie die Geräte-ID ein...',
            activatedOn: 'Aktiviert am',
            title: 'Bitte bestätigen Sie die Änderung des Gerätestatus',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} wurde erfolgreich gelöscht',
          deleteCompanyErrorMessage: 'Löschen von {{companyName}} fehlgeschlagen. Bitte versuchen Sie es später erneut.',
          needAdminLicense:
            'Sie benötigen eine Admin-Lizenz, um auf diese Funktion zuzugreifen. Bitte kontaktieren Sie support@prosaris.ca',
          deleteChildCompanyFirst:
            'Firma mit Tochtergesellschaften oder Konten können nicht gelöscht werden. Bitte löschen Sie zuerst alle Tochtergesellschaften und Konten.',
          editSettings: 'Einstellungen bearbeiten',
          company: 'Firma',
          createASubCompany: 'Erstellen Sie ein Tochterunternehmen für {{companyName}}',
          deleteCompany: 'Firma löschen',
          cannotCreateChildCompanyDialog: {
            messageOne:
              'Sie haben die maximale Anzahl von Firma erreicht, die von Ihren Unternehmen-Konten erstellt werden können. Bitte kontaktieren Sie ',
            messageTwo: 'für weitere Details.',
          },
          deleteCompanyDialog: {
            title: 'Bitte bestätigen Sie die Löschung des Firmen',
            delete: 'Löschen',
            deleting: 'Löschen',
            permanentlyDeleted: 'wird es dauerhaft aus unserer Datenbank entfernen.',
            cannotBeUndone: 'DIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN.',
          },
        },
        companyDialog: {
          industryIsRequired: 'Branche ist erforderlich',
          createLogoErroerMessage: 'Fehler beim Hochladen des Bildes. Bitte versuchen Sie es später erneut.',
          deleteLogoErrorMessage: 'Fehler beim Löschen des Bildes. Bitte versuchen Sie es später erneut.',
          createCompanySuccessMessage: 'Firma {{name}} erstellt',
          createCompanyErrorMessage: 'Firma konnte nicht erstellt werden. Bitte versuchen Sie es später erneut.',
          changeCompanySuccessMessage: 'Firma {{name}} aktualisiert',
          changeCompanyErrorMessage: 'Fehler beim Ändern des Firmenparameters. Bitte versuchen Sie es später erneut.',
          language: 'Sprache',
          industry: 'Industrie',
          country: 'Land',
          currency: 'Währung',
        },
        companyLogo: {
          setImageErrorMessage: 'Die Dateigröße muss kleiner als 1 MB sein',
          logo: 'Logo',
          add: 'Hinzufügen',
          removeLogo: 'Logo entfernen',
          addLogo: 'Logo hinzufügen',
        },
        index: {
          loadCompaniesErrorMessage: 'Laden der Firmenliste fehlgeschlagen. Bitte versuchen Sie es später erneut',
          changeCompaniesSectionWidth: 'Breite des Firmenbereichs ändern',
        },
        sidebar: {
          filterCompanies: 'Firma filtern',
          placeholder: 'Firmennamen eingeben',
          myCompany: 'Mein Firma',
          childCompanies: 'Tochterunternehmen',
        },
        routes: {
          index: {
            noFacility: 'Sie haben keine Einrichtung definiert',
            createAFacility: 'Erstellen Sie eine Einrichtung, um mit der Routenplanung zu beginnen',
            goToCreateAFacility: 'Gehe zur Einrichtungserstellung',
            createAFacilityTooltip: 'Sie müssen eine Einrichtung erstellen, bevor Sie mit der Routenplanung beginnen können.',
            save: 'Speichern',
            reset: 'Zurücksetzen',
            routePlans: 'Routenpläne',
            loading: 'Laden...',
            noRoutesAdded: 'Keine Routen hinzugefügt.',
            buildARouteTooltip: 'Erstellen Sie eine Route mit dem Routenplaner.',
            createFloorplanSuccess: 'Erfolg! Grundriss hochgeladen!',
            createRouteSuccess: 'Erfolg! Route erstellt!',
          },
          routeBuilder: {
            missingFields: 'Fehlende Felder in der Excel-Datei',
            title: 'Routenplaner',
            manuallyBuildARoute: 'Manuell einen neuen Routenplan erstellen',
            manualInstructions: 'Sie können Routen manuell erstellen oder eine Excel-Datei hochladen.',
            facility: 'Einrichtung',
            route: 'Route',
            enterRoute: 'Route eingeben',
            addFloorplan: 'Grundriss hinzufügen',
            floorplanInstructionsTooltip:
              'Fügen Sie ein Bild eines Grundrisses hinzu, um Ihnen bei der Routenplanung zu helfen. Die Route wird beim Speichern auf den Grundriss gelegt.',
            change: 'Ändern',
            location: 'Ort',
            enterLocation: 'Ort eingeben',
            equipment: 'Ausrüstung',
            enterEquipment: 'Ausrüstung eingeben',
            criticality: 'Kritikalität',
            enterCriticality: 'Kritikalität eingeben',
            description: 'Beschreibung',
            enterDescription: 'Beschreibung eingeben',
            addEquipment: 'Ausrüstung hinzufügen',
            addRoute: 'Route hinzufügen',
            downloadTemplate: 'Vorlage herunterladen',
          },
          excelReader: {
            loadFromFile: 'Aus Datei laden',
            instructionsTooltip:
              'Sie können Routen erstellen, indem Sie eine Excel-Datei hochladen, bei der jedes Blatt einen anderen Standort in einer Einrichtung darstellt. Jeder Standort kann mehrere Routen haben.',
          },
          graph: {
            controls: {
              addFloorplan: 'Grundriss hinzufügen',
              toggleLabels: 'Etiketten umschalten',
              addWaypoint: 'Wegpunkt hinzufügen',
              addEquipment: 'Ausrüstung hinzufügen',
              swapEquipment: 'Ausrüstung tauschen',
              showLeakStats: 'Datensatzstatistiken anzeigen',
              save: 'Speichern',
              delete: 'Löschen',
            },
            routeStats: {
              title: 'Routenstatistiken',
              totalDollarLossPerYear: 'Gesamtverlust pro Jahr in $:',
              totalKwhLossPerYear: 'Gesamtverlust pro Jahr in Kwh:',
              totalLeakCount: 'Gesamtanzahl der Datensätze:',
              totalActiveOpen: 'Gesamtanzahl der offenen Lecks:',
              totalActiveClosed: 'Gesamtanzahl der geschlossenen Lecks:',
              noStatsAvailable: 'Keine Statistiken verfügbar:',
              close: 'Schließen',
            },
            nodeDetails: {
              edit: 'Bearbeiten',
              name: 'Name',
              description: 'Beschreibung',
              criticality: 'Kritikalität',
              equipmentInformation: 'Ausrüstungsinformationen',
              thisIsAWaypoint: 'Dies ist ein Wegpunkt',
              activeOpenLeakCount: 'Anzahl aktiver offener Datensätze:',
              noActiveLeaksDetected: 'Keine aktiven Lecks erkannt',
              close: 'Schließen',
              nothingToSeeHere: 'Nichts zu sehen hier',
              seeLeakRecords: 'Datensätze anzeigen',
            },
            swapEquipment: {
              title: 'Ausrüstung tauschen',
              selectEquipment: 'Wählen Sie die Ausrüstung zum Tauschen aus:',
              selectFirst: 'Wählen Sie die erste Ausrüstung',
              selectSecond: 'Wählen Sie die zweite Ausrüstung',
              swap: 'Tauschen',
              cancel: 'Abbrechen',
            },
            addNode: {
              title: 'Hinzufügen',
              waypoint: 'Wegpunkt',
              equipment: 'Ausrüstung',
              afterWhich: 'Nach welcher Ausrüstung?',
              selectPreceding: 'Wählen Sie die vorhergehende Ausrüstung:',
              selectEquipment: 'Ausrüstung auswählen',
              name: 'Name',
              description: 'Beschreibung',
              criticality: 'Kritikalität',
              add: 'Hinzufügen',
              cancel: 'Abbrechen',
            },
            save: 'Speichern',
            delete: 'Löschen',
            successRouteUpdated: 'Route erfolgreich aktualisiert',
            errorRouteUpdated: 'Fehler beim Aktualisieren der Route. Bitte versuchen Sie es später erneut.',
            successRouteDeleted: 'Route erfolgreich gelöscht',
            errorRouteDeleted: 'Fehler beim Löschen der Route. Bitte versuchen Sie es später erneut.',
            successFloorplanUpdated: 'Grundriss erfolgreich aktualisiert',
            errorFloorplanUpdated: 'Fehler beim Aktualisieren des Grundrisses. Bitte versuchen Sie es später erneut.',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Zugewiesene Administratorlizenzen',
            of: 'von',
            edit: 'Bearbeiten',
          },
          essentialMode: {
            disable: 'deaktivieren',
            enable: 'aktivieren',
            essential: 'Essentieller Modus:',
            enabled: 'AKTIVIERT',
            disabled: 'DEAKTIVIERT',
            moveCompany: 'Firma {{company}} in den essentiellen Modus versetzen',
            affectedCompanies: 'Anzahl der betroffenen Firma',
            affectedAccounts: 'Anzahl der betroffenen Konten',
            archivedLeak: 'Anzahl der archivierten Leckereignisprotokolle',
            applyingChanges:
              'Durch Anwenden dieser Änderungen erhalten Konten die Rolle "Essentiell". Benutzer können die neuesten {{quota}} Leckereignisprotokolle auf einem Dashboard und in einer mobilen App sehen. Andere Protokolle werden archiviert.',
            essentialLabel: 'Wesentliches für Tochterunternehmen ändern',
            turnOffEssential: 'Wesentlichen Modus für Firma {{company}} ausschalten',
            byApplyingChanges:
              'Durch Anwenden dieser Änderungen erhalten alle Konten die Rolle "Benutzer". Benutzer können alle ihre Protokolle sehen. Alle Protokolle werden aus dem Archiv geholt.',
          },
          hubspotSync: {
            disable: 'deaktivieren',
            enable: 'aktivieren',
            hubspot: 'HubSpot-Synchronisierung',
            enabled: 'AKTIVIERT',
            disabled: 'DEAKTIVIERT',
            enableHubspotSync: 'HubSpot-Synchronisierung für Unternehmen {{company}} aktivieren',
            disableHubspotSync: 'HubSpot-Synchronisierung für Unternehmen {{company}} deaktivieren',
            affectedCompanies: 'Anzahl der betroffenen Unternehmen',
            applyingChanges: 'Durch das Anwenden dieser Änderungen wird die HubSpot-Synchronisierung {{status}}.',
            hubspotLabel: 'HubSpot-Synchronisierung für Unterunternehmen ändern',
          },
          index: 'Einstellungen',
        },
        index: {
          loadCompanyError: 'Firmeninformationen können nicht geladen werden. Bitte versuchen Sie es später noch einmal',
          companySlug: 'Firmen-Kurzname',
          notSelected: 'Nicht ausgewählt',
          country: 'Land',
          accounts: 'Konten',
          currency: 'Währung',
          adminLicenses: 'Administratorlizenzen',
          totalCustomer: 'Gesamte Kundenkonten im Baum',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Registrieren Sie Ihr Gerät',
          toolTip: 'Bitte lokalisieren Sie die Seriennummer auf Ihrer Registrierungskarte oder auf der Box.',
          serialNumberIsRequired: 'Seriennummer ist erforderlich',
          oops: 'Hoppla!',
          deviceNotFound: 'Geräteseriennummer nicht gefunden',
          deviceFoundNdAssociatedCompany: 'Ihr Gerät wurde gefunden und ist mit einem Firma verbunden.',
          deviceFound: 'Ihr Gerät wurde gefunden.',
          goToLogin: 'Zum Login gehen',
          contactSupport: 'Bitte kontaktieren Sie den Support',
        },
        deviceErrorCodes: {
          'B13:001': 'Geräteseriennummer nicht gefunden',
          'B13:002': 'Gerät bereits aktiviert',
          'B13:003': 'Admin-E-Mail nicht gesendet',
          'B13:004': 'Firma nicht gefunden',
          'B13:005': 'Firma ungültig',
          'B13:006': 'Ungültiger Typ',
        },
        registerAccountView: {
          registerAccount: 'Konto erstellen',
          termsMustBeAccepted: 'Die Allgemeinen Geschäftsbedingungen müssen akzeptiert werden.',
          saleType012Success: 'Sie können sich anmelden, um Ihr Gerät zu beginnen.',
          saleType3Success: 'Sie wurden als Basisbenutzer hinzugefügt. Ihre Firmenadministratoren wurden benachrichtigt.',
          saleType4Success: {
            companyPlatformFound: 'Firmenplattformlizenz gefunden.',
            dialogOne: 'Dieses Gerät ist mit einer neuen Firmenplattformlizenz verbunden.',
            dialogTwo: 'Ihr Firma könnte bereits in unserem System existieren.',
            dialogThree: 'Wenn ja, kontaktieren Sie bitte Ihren Administrator.',
          },
          termsAndConditions: {
            dialogOne: 'Ich stimme den',
            dialogTwo: 'Nutzungsbedingungen',
            dialogThree:
              'von Prosaris zu und bestätige, dass ich genügend Zeit hatte, sie zu lesen und zu verstehen, dass sie wichtige Bedingungen über meine Nutzung der Prosaris-Software enthalten, wie die Begrenzung der Haftung von Prosaris und wie meine Daten gehandhabt werden.',
          },
          acceptTerms: 'Bedingungen akzeptieren',
          registerAccountErrorCodes: {
            'B02:001': 'Ungültiger Registrierungscode',
            'B02:002': 'Ungültiges Benutzerunternehmen',
            'B02:003': 'Ungültige Benutzerrolle',
            'B02:004': 'Die E-Mail ist bereits vergeben',
            'B02:005': 'Die E-Mail ist bereits vergeben',
            'B02:006': 'Passwort für Ihr Konto kann nicht zurückgesetzt werden',
            'B02:007': 'Ungültiges Benutzerunternehmen',
            'B02:008': 'Profilfoto fehlt',
            'B02:009': 'Konto-Reset erforderlich. Der Vorgang wurde gestartet',
            'B02:010': 'Das Konto wurde deaktiviert',
            'B02:011': 'Ungültiges Format des Profilfotos',
            'B02:012': 'Gerät bereits einem Firma zugeordnet',
            'B02:013': 'Seriennummer ist nicht definiert',
            'B02:014': 'Ungültiger Typ',
            'B02:015': 'Link zum Zurücksetzen des Passworts abgelaufen',
            'B02:016': 'Kann kein Konto für dieses Firma erstellen',
            'B02:017': 'Ungültige Rolle',
            'B02:018': 'Ungültige Rolle',
            'B02:019': 'Ungültiges Benutzerunternehmen',
            'B02:020': 'Ungültige Benutzerrolle',
            'B02:021': 'Benutzer gehört nicht zu diesem Firma',
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Seriennummern sind erforderlich',
          failedToCopy: 'Fehlgeschlagene Seriennummern in die Zwischenablage kopiert',
          invalidFormat: 'Ein oder mehrere Geräte haben ein ungültiges Format. Bitte überprüfen Sie es und versuchen Sie es erneut.',
          devices: 'Registrieren Sie Ihre Geräte',
          deviceSerialNumber: 'Seriennummer(n) des Geräts',
          note: 'Geben Sie jede Seriennummer in einer neuen Zeile oder durch ein Komma getrennt ein.',
          title: 'Bitte finden Sie die Seriennummer auf Ihrer Registrierungskarte oder der Verpackung.',
          register: 'Registrieren',
          deviceRegistrationStatus: 'Gerätestatus registrieren',
          success: 'Erfolg',
          noDeviceFound: 'Keine Geräte gefunden.',
          failed: 'Fehlgeschlagen',
          copyFailed: 'Fehlgeschlagene Seriennummern in die Zwischenablage kopieren',
        },
      },
      errors: {
        notFoundView: {
          title: 'Die gesuchte Seite ist nicht hier',
          description:
            'Entweder hast du eine fragwürdige Route versucht oder du bist aus Versehen hierhergekommen. Was auch immer es ist, versuche die Navigation zu benutzen.',
        },
        accountNotFound: {
          title: 'Konto nicht gefunden',
          description:
            'Bitte versuchen Sie es erneut! Wenn das Problem weiterhin besteht, ist Ihr Konto entweder derzeit inaktiv oder wurde möglicherweise nicht vom Administrator initialisiert. Bitte kontaktieren Sie Ihren Administrator.',
          logout: 'Abmelden',
        },
      },
      integration: {
        index: {
          loggingInError: 'Fehler beim Anmelden bei Prosaris.',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'Keine Bilder verfügbar',
          activeActionRecord: {
            updateAssignmentSuccess: 'Die zugewiesene Maßnahme wurde aktualisiert.',
            updateAssignmentError: 'Fehler beim Aktualisieren der zugewiesenen Maßnahme.',
            deleteImageSuccess: 'Das zugewiesene Bild wurde gelöscht.',
            deleteImageError: 'Fehler beim Löschen des zugewiesenen Maßnahmenbildes.',
            confirmMarkAction: 'Möchten Sie diese Maßnahme wirklich als abgeschlossen markieren?',
            confirmDeleteImage: 'Möchten Sie dieses Bild wirklich löschen?',
          },
          index: 'Fehler beim Laden der zugewiesenen Maßnahmen für einen Datensatz.',
        },
        audio: {
          noAudioAvailable: 'Keine Audiodaten verfügbar',
          errorDownloadingAudio: 'Fehler beim Herunterladen der Audiodatei. Bitte versuchen Sie es später erneut.',
          playPause: 'Abspielen / Pause',
          generateRegion: 'Bereich Erstellen',
          removeLastRegion: 'Letzten Bereich Entfernen',
          generateMarker: 'Marker Erstellen',
          removeLastMarker: 'Letzten Marker Entfernen',
          toggleTimeline: 'Zeitleiste Umschalten',
          download: 'Herunterladen',
        },
        audit: {
          updateAuditSuccess: 'Audit erfolgreich aktualisiert',
          updateAuditError: 'Fehler beim Speichern des Kommentars. Bitte versuchen Sie es später erneut.',
          createAuditSuccess: 'Audit erfolgreich erstellt',
          createAuditError: 'Fehler beim Erstellen des Audits. Bitte versuchen Sie es später erneut.',
          deleteAuditSuccess: 'Audit wurde erfolgreich gelöscht',
          deleteAuditError: 'Audit konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.',
          noComment: 'Keine Kommentare',
          reviewComments: 'Kommentare überprüfen',
          reviewedBy: 'Bewertet von:',
          auditDate: 'Audit-Datum:',
          deleteAudit: 'Audit löschen',
          reviewedNoComments: 'Keine Kommentare überprüft',
          auditComments: 'Audit-Kommentare',
          saveComment: 'Kommentar speichern',
          discardChanges: 'Änderungen verwerfen',
        },
        changeHistory: {
          created: 'erstellt',
          updated: 'aktualisiert',
          deleted: 'gelöscht',
          transferred: 'übertragen',
          leak: 'Datensatz',
          images: 'Bild',
          audit: 'Audit',
          audio: 'Audio',
          history: 'Verlauf',
          noHistory: 'Kein Verlauf',
          bulk: 'als Massenoperation',
          details: 'Details',
          unset: 'nicht festgelegt',
        },
        fft: {
          fft: 'FFT (Schnelle Fourier-Transformation)',
          fftAvg: 'FFT-Durchschnitt',
          notApplicable: 'Nicht zutreffend',
          chartLayers: 'Diagrammschichten',
          avg: 'Durchschnitt',
          all: 'Alle',
          playing: 'Spielt...',
          showTimeSeries: 'Zeitreihe anzeigen',
        },
        form: {
          updateLeakSuccess: 'Erfolgreich aktualisiert.',
          updateLeakError: 'Fehler beim Aktualisieren des Lecks. Bitte versuchen Sie es später erneut.',
          alertTitle: {
            partOne: 'Datensatz ist in',
            partTwo: 'schreibgeschützten',
            partThree: 'Modus.',
          },
          alertDialog: {
            dialogOne: 'Folgende Eigenschaften können jedoch geändert werden:',
            dialogTwo: 'Maßnahmenstatus',
          },
          GPSLocation: 'GPS-Position',
          completedOn: 'Abgeschlossen am:',
          lastSaved: 'Zuletzt gespeichert:',
        },
        images: {
          removeSuccess: 'Bild erfolgreich gelöscht.',
          removeError: 'Das Bild des Leckdatensatzes kann nicht gelöscht werden. Bitte versuchen Sie es später erneut.',
          text: 'Sind Sie sicher, dass Sie dieses Bild löschen möchten?',
        },
        index: {
          title: 'Datensatz',
          loadingError: 'Fehler beim Laden des Leckdatensatzes. Bitte versuchen Sie es erneut.',
          unsavedMessage: "Durch Drücken von 'Ok' bestätigen Sie, dass alle nicht gespeicherten Änderungen gelöscht werden",
          notAnAuditor: 'Sie sind kein Prüfer',
          anEssential: 'Sie sind auf einem Basisplan',
          showHistory: 'Verlauf anzeigen',
        },
        massFlow: {
          recalculating: 'Neukalkulation von Durchfluss/Verlust gemäß den Leckparameter-Einstellungen...',
          warning:
            'Eingabewerte haben sich geändert oder die Berechnung ist veraltet. Drücken Sie "Neuberechnen", um die Werte für Durchfluss und Verlust zu aktualisieren',
          calculatingError: 'Fehler bei der Berechnung des Massenstroms. Bitte versuchen Sie es erneut.',
          noValue: 'Kein Wert',
          calculatingSuccess: 'Durchfluss/Verlust neu berechnet',
          recalculate: 'Neuberechnen',
          calculatorVersion: 'Berechnungsversion:',
          noteDialog: {
            dialogOne: 'Durch Ändern der Leckparameter-Einstellungen wird die Durchfluss-/Verlustberechnung aktualisiert. Überprüfen',
            dialogTwo: 'Berechnungserkenntnisse',
            dialogThree: 'Seite für weitere Informationen',
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'Datensatz wurde geändert',
          close: 'Schließen',
          unsavedChanges:
            'Dieser Eintrag enthält ungespeicherte Änderungen. Bitte speichern Sie diese Änderungen oder entfernen Sie sie vollständig.',
          discardChanges: 'Änderungen verwerfen',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: 'Geräte-ID ist erforderlich',
          dbPeakIsRequired: 'Gemessener Spitzen-DB ist erforderlich',
          dbMin: 'Gemessener Spitzen-DB muss größer oder gleich 0 sein',
          dbMax: 'Gemessener Spitzen-DB muss kleiner oder gleich 95 sein',
          dbError: 'Gemessener Spitzen-DB muss eine Zahl sein',
          imageUploadError: 'Fehler beim Hochladen des Bildes. Bitte versuchen Sie es später erneut.',
          createLeakSuccess: 'Datensatz erfolgreich hinzugefügt',
          createLeakError: 'Fehler beim Erstellen eines Datensatzes. Bitte versuchen Sie es später erneut.',
          basicInfo: 'Grundlegende Informationen',
          flowInput: 'Durchfluss-Eingabe',
          mainGas: 'Hauptgas/Komponente von Interesse',
          selectComponent: 'Komponente auswählen',
          selectLeakType: 'Lecktyp auswählen',
          mightBeSomethingHere: 'noch nicht sicher',
          energyLoss: 'Energieverlust',
          selectCompressorType: 'Kompressortyp auswählen',
          noRoutes: 'Keine Routen gefunden',
          noEquipment: 'Kein Ausrüstung gefunden',
          noLocations: 'Keine Standorte gefunden',
          energyCost: 'Energiekosten',
          audioRecording: 'Audioaufnahme',
          leakSourceEquipment: 'Quelle',
          selectComponentType: 'Komponententyp auswählen',
          selectService: 'Dienst auswählen',
          locationAndUser: 'Standort und Benutzer',
          leakEventStatus: 'Ereignisstatus',
          low: 'Niedrig',
          medium: 'Mittel',
          high: 'Hoch',
          open: 'Öffnen',
          complete: 'Komplett',
          reset: 'Zurücksetzen',
        },
        formElectrical: {
          systemVoltages: 'Systemspannungen',
          source: 'Quelle',
        },
        images: {
          leakSourceImageCapture: 'Quellbildaufnahme',
          add: 'Hinzufügen',
        },
        index: {
          title: 'Erstellen Sie ein Aufzeichnung',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'Von',
          to: 'Bis',
          qualifier: 'Qualifikator',
          savedFilters: 'Gespeicherte Filter',
          filter: 'Filter',
          selectFilter: 'Wählen Sie einen Filter...',
          filterName: 'Filtername',
          filterNameRequired: 'Filtername ist erforderlich',
          filtersNotAvailable: 'Filter nicht verfügbar',
          saveFilterTitle: 'Filter speichern',
          deleteSavedFilterTitle: 'Bitte bestätigen Sie das Löschen des Filters',
          deleteSavedFilterMessage: 'Möchten Sie den Filter "{{savedFilterName}}" wirklich löschen?',
          createFilterSuccess:
            "Filter '{{filter}}' wurde erfolgreich erstellt. Sie können es unter Datensatzfilter → Gespeicherte Filter finden",
          createFilterError: 'Fehler beim Erstellen des Filters. Bitte versuchen Sie es später erneut.',
          deleteFilterSuccess: 'Filter erfolgreich gelöscht',
          deleteFilterError: 'Fehler beim Löschen des Filters. Bitte versuchen Sie es später erneut.',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Zuordnen der Aufzeichnung',
          selectAUserToAssign: 'Wählen Sie einen Benutzer aus, der den ausgewählten Datensätzen zugewiesen werden soll',
          filterByName: 'Nach Namen filtern',
          filterByEmployeeName: 'Nach Mitarbeitername filtern',
          unexpectedError: 'Unerwarteter Fehler aufgetreten',
          noUserFound: 'Keine Benutzer gefunden',
          assigningActionForSelectedRecords: 'Zuweisung von Aktionen für ausgewählte Datensätze...',
        },
        modalBulkEdit: {
          titleSingleRecord: 'Massenbearbeitung von {{selectedRecordsCount}} Eintrag',
          title: 'Massenbearbeitung von {{selectedRecordsCount}} Einträgen',
          selectAField: 'Wählen Sie ein Feld aus',
          verifyAction:
            'Sie sind dabei, Datensätze zu aktualisieren, die anderen Benutzern gehören. Bitte bestätigen Sie dies, indem Sie unten die Anzahl der ausgewählten Datensätze eingeben.',
          matchCountError: 'Muss mit der Anzahl der ausgewählten Datensätze übereinstimmen ({{selectedRecordsCount}})',
          moreThanOneType:
            'Die ausgewählten Datensätze haben mehr als einen Typ. Einige Felder sind möglicherweise für die Massenbearbeitung nicht verfügbar.',
        },
        tableHead: {
          youAreSelecting: 'Sie wählen:',
          visibleRecords: 'sichtbare Datensätze',
          andAllUnloadedRecords: 'und alle nicht geladenen Datensätze.',
          allRecordsWillBeLoaded: 'Alle Datensätze werden geladen und ausgewählt.',
          thisCanTakeAWhile: 'Dies kann eine Weile dauern, wenn Sie viele Datensätze haben.',
          allRecords: 'Alle Datensätze, einschließlich der unsichtbaren',
          selectAllRecords: 'Alle Datensätze auswählen.',
        },
        tableBody: {
          noRecordsToDisplay: 'Es gibt keine Datensätze zur Anzeige.',
        },
        toolBar: {
          assign: 'Zuweisen',
          onEssentialPlan: 'Sie sind auf einem Basisplan. Bitte kontaktieren Sie support@prosaris.ca',
          bulkEdit: 'Massenbearbeitung',
          bulkEditSuccess: '{{len}} Eintrag erfolgreich aktualisiert',
          bulkEditSuccessPlural: '{{len}} Einträge erfolgreich aktualisiert',
          bulkEditError: 'Fehler beim Aktualisieren der Einträge. Bitte versuchen Sie es später erneut',
          exportPDF: 'PDF exportieren',
          needAdminLicense:
            'Sie benötigen eine Admin-Lizenz, um auf diese Funktion zuzugreifen. Bitte kontaktieren Sie support@prosaris.ca',
          create: 'Erstellen',
          deleteRecordMessage: 'Sind Sie sicher, dass Sie den ausgewählten Datensatz löschen möchten?',
          deleteRecordMessagePlural: 'Sind Sie sicher, dass Sie die ausgewählten Datensätze löschen möchten?',
          permissionDenied: 'Erlaubnis verweigert!',
          notAllowedToDelete:
            'Sie haben einen Datensatz ausgewählt, den Sie nicht löschen dürfen. Bitte entfernen Sie ihn aus der Auswahl.',
          generatingPDF: 'PDF wird erstellt...',
          cannotGeneratePDF:
            'Leider können wir derzeit nicht {{numberSelected}} Datensätze in einem PDF-Dokument exportieren. Bitte wählen Sie bis zu {{limitRecords}} Datensätze aus.',
          cannotExportMessage: 'Kann {{numberSelected}} Datensätze nicht exportieren',
          bulkEditLimitExceeded:
            'Es können nicht mehr als {{maxAllowed}} Datensätze bearbeitet werden. Bitte kontaktieren Sie support@prosaris.ca',
        },
        index: {
          cannotLoadLeakRecord: 'Entschuldigung, wir können den Leckdatensatz momentan nicht laden',
          successfullyAssignedAllRecords: 'Alle Datensätze erfolgreich zugewiesen',
          actionRecordAssigned: 'Aktionsdatensatz zugewiesen!',
          actionRecordAssignedPlural: 'Aktionsdatensätze zugewiesen!',
          errorWhileAssigningLeak: 'Fehler bei der Zuweisung des Lecks. Bitte versuchen Sie es später erneut.',
          successfullyDeletedAllRecords: 'Alle Datensätze erfolgreich gelöscht',
          successfullyDeletedRecord: '{{length}} Leckdatensatz erfolgreich gelöscht',
          successfullyDeletedRecordPlural: '{{length}} Leckdatensätze erfolgreich gelöscht',
          errorOnRecordsDelete: 'Fehler beim Löschen der Datensätze. Bitte versuchen Sie es später erneut',
          leakPreview: 'Vorschau',
          leakRecord: 'Aufzeichnung: ',
        },
      },
      tagList: {
        resetTag: 'Tag zurücksetzen',
        dataExport: {
          smartLeakTagsFormat: 'Smart Tags-Format',
          smartLeakTagsDescription: 'Enthält die Standardfelder, die auf der Smart Tags-Seite angezeigt werden',
          standardLeakRecordFormat: 'Standardaufzeichnungsformat',
          standardLeakRecordDescription: 'Enthält alle Felder, die mit den Aufzeichnungsdaten von Lecks zusammenhängen',
        },
        filters: { smartLeakTagFilters: 'Smart Tag-Filter' },
        index: {
          cannotLoadTags: 'Es tut uns leid, wir können derzeit keine Smart Tags laden',
          updateLeakSuccess: 'Smart Tag-Zurücksetzen erfolgreich.',
          updateLeakError: 'Fehler beim Zurücksetzen von Smart Tags. Bitte versuchen Sie es später noch einmal.',
          bulkEditSuccess: 'Smart Tags erfolgreich zurückgesetzt {{len}}',
          bulkEditSuccessPlural: 'Smart Tags erfolgreich zurückgesetzt {{len}}',
          bulkEditError: 'Fehler beim Zurücksetzen von Smart Tags. Bitte versuchen Sie es später noch einmal.',
          tagsFileName: 'Smart Tags',
          leakFileName: 'Aufzeichnungen',
        },
        tableBody: {
          title: 'Bitte bestätigen Sie das Zurücksetzen des Tags',
          alertDialog: {
            dialogOne: 'Sie versuchen zu',
            dialogTwo: 'Tag zurücksetzen',
            dialogThree: 'aus einer Leckaufzeichnung mit',
            dialogFour: 'Aktionsstatus: OFFEN',
            dialogFive: 'Möchten Sie fortfahren?',
          },
        },
        toolBar: {
          resetTags: 'Tags zurücksetzen',
          title: 'Bitte bestätigen Sie das Massen-Zurücksetzen der Tags',
          alertDialog: {
            dialogOne: 'Ihre Auswahl enthält',
            dialogTwo: 'Eintrag mit',
            dialogTwoPlural: 'Einträge mit',
            dialogThree: 'Aktionsstatus: OFFEN',
            dialogFour: 'Möchten Sie fortfahren?',
          },
          exportFormatCSV: 'Wählen Sie ein Exportformat für CSV',
          exportFormatPDF: 'Wählen Sie ein Exportformat für PDF',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Fehler beim Laden der zugewiesenen Aktionen. Bitte versuchen Sie es erneut.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Verfügbare Diagramme',
            selectAChart: 'Wählen Sie ein Diagramm...',
            chartFilters: 'Diagrammfilter',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Fehler beim Laden der Leckaufzeichnungen. Bitte versuchen Sie es erneut.',
              errorMessageTwo: 'Bitte wählen Sie ein Datum, das vor dem Enddatum liegt.',
              errorMessageThree: 'Bitte wählen Sie einen Zeitraum von weniger als 6 Monaten.',
              errorMessageFour: 'Bitte wählen Sie ein Datum, das nach dem Startdatum liegt.',
            },
            charts: 'Diagramme',
            chartAlreadyExists: 'Dieses Diagramm existiert bereits!',
          },
          filters: {
            select: 'Wählen...',
            startDate: 'Startdatum',
            endDate: 'Enddatum',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Durchfluss nach Leckkategorie (${unit})`,
              LOSS_BY_LEAK: (currency) => `Verlust nach Leckkategorie (${currency})`,
              TAGS_BY_LEAK: 'Tags nach Leckkategorie',
              AVG_FLOW_BY_LEAK: (unit) => `Durchschnittlicher Durchfluss nach Leckkategorie (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Verlust- und Einsparungsmöglichkeiten nach Reparaturzeit (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Durchfluss nach Reparaturzeit (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Verlust nach Reparaturzeit (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Durchflussfortschritt – Repariert vs. Nicht repariert (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Verlustfortschritt – Repariert vs Nicht repariert (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Durchfluss nach Gerät/Komponente (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `Verlust nach Gerät/Komponente (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Tags nach Gerät/Komponente',
            },
            datasetLabel: {
              totalLeakFlow: 'Flusso totale di perdita trovato in',
              totalLoss: 'Totale delle perdite in',
              totalRecords: 'Totale dei record trovati in',
              avgLeakFlow: 'Flusso medio di perdita trovato in',
              totalLossIn: 'Perdita totale in',
              flowByRepairTime: 'Flusso per tempo di riparazione in',
              lossByRepairTime: 'Perdita per tempo di riparazione in',
              flowByRepairProgress: 'Flusso trovato per avanzamento delle riparazioni in',
              recordsByCompletion: 'Record per completamento in',
              flowByEquipment: 'Flusso trovato per attrezzatura in',
              totalLossByEquipment: 'Perdita totale per attrezzatura in',
              totalRecordsByEquipment: 'Record totali per attrezzatura in',
            },
            labels: {
              low: 'Niedrig',
              medium: 'Mittel',
              high: 'Hoch',
              equipment: 'Ausrüstung',
              savings: 'Einsparungen',
              opportunity: 'Gelegenheit',
              loss: 'Verlust',
              repaired: 'Repariert',
              unrepaired: 'Nicht repariert',
              LESS_15_DAYS: 'Weniger als 15 Tage',
              DAYS_16_TO_30: '15 bis 30 Tage',
              DAYS_31_TO_60: '31 bis 60 Tage',
              DAYS_61_TO_90: 'Mehr als 60 Tage',
            },
          },
        },
        index: {
          assignedActions: 'Ihnen zugewiesene Aktionen',
        },
      },
      export: {
        imageUrlOne: 'Aufzeichnungsbild-URL 1',
        imageUrlTwo: 'Aufzeichnungsbild-URL 2',
        leakTag: 'Smart Tag/Arbeitsverweis.',
        per: 'pro ',
      },
    },
    languages: {
      English: 'Englisch',
      Chinese: 'Chinesisch',
      Spanish: 'Spanisch',
      French: 'Französisch',
      German: 'Deutsch',
      Norwegian: 'Norwegisch',
      Italian: 'Italienisch',
      Turkish: 'Türkisch',
      Japanese: 'Japanisch',
    },
  },
};
