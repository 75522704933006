export default {
  translation: {
    commonUsedWords: {
      login: 'Connexion',
      email: 'Adresse électronique',
      password: 'Mot de passe',
      emailIsRequired: "L'adresse électronique est requise",
      mustBeAValidEmail: 'Doit être un email valide',
      deviceSerialNumber: "Numéro de série de l'appareil",
      next: 'Suivant',
      success: 'Succès',
      submit: 'Soumettre',
      firstName: 'Prénom',
      firstNameIsRequired: 'Le prénom est requis',
      lastName: 'Nom de famille',
      lastNameIsRequired: 'Le nom est requis',
      save: 'Enregistrer',
      delete: 'Supprimer',
      undoChanges: 'Annuler les modifications',
      cancel: 'Annuler',
      loading: 'Chargement...',
      yes: 'Oui',
      no: 'Non',
      clear: 'Effacer',
      confirm: 'Confirmer',
      noOption: 'Aucune option',
      openText: 'Ouvrir',
      edit: 'Modifier',
      logout: 'Déconnexion',
      latitude: 'Latitude',
      longitude: 'Longitude',
      ok: "D'accord",
      okay: "D'accord",
      done: 'Terminé',
      comingSoon: 'Bientôt disponible...',
      reset: 'Réinitialiser',
      attention: 'Attention',
      note: 'Remarque',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Les champs obligatoires sont manquants',
        userNotFound: 'Utilisateur non trouvé',
        missingRefreshToken: 'Le jeton de rafraîchissement est manquant',
        cannotRefreshToken: 'Impossible de rafraîchir le jeton',
      },
      helpers: {
        error: 'Erreur',
        invalidHTTPMethod: 'Méthode HTTP invalide',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: "Confirmer l'adresse électronique",
        confirmEmailIsRequired: "L'email confirmé est requis",
        emailNotMatched: "Les entrées d'email ne correspondent pas",
      },
      confirmPassword: {
        confirmPassword: 'Confirmer le mot de passe',
        confirmPasswordIsRequired: 'Un mot de passe confirmé est requis',
        newPasswordIsRequired: 'Un nouveau mot de passe est requis',
        showPassword: 'Afficher le mot de passe',
        passwordLength: 'Les mots de passe doivent contenir au moins 6 caractères',
        passwordContainSpaces: "Le mot de passe ne doit pas contenir d'espaces",
        passwordNotMatch: 'Les entrées de mot de passe ne correspondent pas',
        newPassword: 'Nouveau mot de passe',
      },
      confirmModal: {
        areYouSure: 'Êtes-vous sûr ?',
      },
      appliedFilters: {
        between: 'Entre {{firstValue}} et {{secondValue}}',
        bottom: 'Bas {{primaryValue}}%',
        top: 'Haut {{primaryValue}}%',
        activeFilters: 'Filtres actifs',
        active: 'actif',
        closed: 'fermé',
      },
      editTable: {
        editTable: 'Modifier le tableau',
        selectDeselect: 'Sélectionner/Désélectionner les valeurs du tableau',
        resetToDefault: 'Réinitialiser par défaut',
      },
      errorBoundary: {
        oops: "Oups ! Quelque chose d'inattendu s'est produit.",
        tryAgain: 'Veuillez réessayer ! Si le problème persiste, veuillez contacter votre administrateur.',
        refresh: 'Actualiser',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Désolé, aucun résultat trouvé. Veuillez réessayer.',
      },
      permissionGuard: {
        invalidPermission: 'Structure de permission invalide, 3 éléments attendus dans le tableau',
      },
      preview: {
        findInView: 'Trouver dans la vue',
        showDetails: 'Afficher les détails',
        closeEsc: 'Fermer (Échap)',
        prevLeftArrow: 'Précédent (Flèche gauche)',
        nextRightArrow: 'Suivant (Flèche droite)',
        proceedWithoutSaving: 'Naviguer sans enregistrer',
        continueEditing: "Continuer l'édition",
        unsavedChanges: {
          partTwo: 'Vous avez ',
          partThree: 'DES CHANGEMENTS NON ENREGISTRÉS',
          partFour: '. Naviguer sans enregistrer annulera toutes les modifications. Êtes-vous sûr ?',
        },
      },
      printRecord: {
        title: 'Enregistrement',
        date: 'Date:',
        location: 'Emplacement',
        leakDetails: 'Détails',
        leakParameters: 'Paramètres',
        energyLoss: 'Perte d’énergie',
        leakSourceEquipment: 'Équipement source',
        notes: 'Remarques',
        leakEventStatus: 'Statut',
        requiredAction: 'Action requise',
        leakSourceImage: 'Image source',
        smartLeakTag: 'Étiquette intelligente',
        lossPerYear: 'Perte ({{currency}}/an)',
        costOfEnergy: "Coût de l'énergie ({{currency}}/kWh)",
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'La tâche a été supprimée',
          deleteAssignmentError: 'La tâche ne peut pas être supprimée. Veuillez réessayer plus tard',
          currentlyAssigned: 'Action actuellement assignée',
          repairedBy: 'Réparé par',
          text: 'Vous allez supprimer cette tâche. Cette action est irréversible',
        },
        actionSaveDate: 'Dernière sauvegarde',
      },
      visibleColumnsPopper: {
        tableValues: 'Sélectionner / Désélectionner les valeurs du tableau',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Égal à',
        LESS_THAN: 'Moins que',
        GREATER_THAN: 'Plus que',
        LESS_THAN_OR_EQUAL_TO: 'Inférieur ou égal à',
        GREATER_THAN_OR_EQUAL_TO: 'Supérieur ou égal à',
        BETWEEN_A_AND_Y: 'Entre',
        TOP_PERCENT: 'Haut "x"%',
        BOTTOM_PERCENT: 'Bas "x"%',
      },
      recordFields: {
        CREATED_AT: 'Date de création',
        RECORD_ID: "Numéro d'enregistrement",
        DOCUMENT_ID: "Numéro d'enregistrement",
        DOLLARS_PER_YEAR: 'Perte',
        GAS_COMPONENT: 'Gaz/Composant',
        LEAK_TYPE: 'Profil de fuite',
        DATE_TIME: 'Date/Heure',
        DB_PEAK: 'dB Pic',
        COMPANY: 'Société',
        NOTES: 'Remarques',
        USER: 'Utilisateur',
        EQUIPMENT_TAG: "Étiquette d'équipement/Composant",
        FLOW: 'Débit (m³/h)',
        EQUIPMENT: 'Unité/Équipement',
        ROUTE: 'Itinéraire',
        ROUTE_LOCATION: "Lieu de l'itinéraire",
        PROJECT_SURVEY: "Enquête/Référence de l'audit",
        OUTAGE_STATUS: 'Arrêt/Arrêt nécessaire?',
        FACILITY: 'Installation/Emplacement',
        ACTION_STATUS: "Statut de l'action",
        SERVICE_TYPE: 'Service/Type',
        DB_ACTIVE: 'dB Actif',
        REFERENCE_ID: "Référence du projet/ID de l'enquête",
        AMBIENT_TEMPERATURE: 'Température ambiante (°C)',
        LEAK_PRESSURE: 'Pression (kPag)',
        LEAK_TEMPERATURE: 'Température du gaz (°C)',
        LEAK_CATEGORY: 'Catégorie',
        DISTANCE: 'Distance (m)',
        GAS_COST: 'Coût du gaz ($/m³)',
        COMPRESSOR_FLOW: 'm³/h par BHP',
        DEVICE_ID: "ID de l'appareil",
        COMPRESSOR_BHP: 'BHP du compresseur',
        COMPRESSOR_TYPE: 'Type de compresseur',
        MOTOR_EFFICIENCY: 'Efficacité du moteur (%)',
        ENERGY_COST: "Coût de l'énergie ($/kWh)",
        HOURS: 'Heures de fonctionnement/an',
        LOCATION: 'Emplacement',
        LAST_MODIFIED: 'Dernière date de sauvegarde',
        POWER_LOAD: '% Charge complète',
        CLIENT: 'Client',
        SERVICE: 'Service',
        CAPACITY: '% Capacité',
        EQUIPMENT_TYPE: "Type d'équipement/Composant",
        LEAK_TAG: 'Numéro de tag intelligent/Référence de travail',
        REPAIRED_BY: 'Réparé par',
        ALTITUDE: 'Altitude (m)',
        REQUIRED_ACTION: 'Action requise',
        FREQUENCY_LOW_BOUND: 'Fréquence (basse)',
        FREQUENCY_HIGH_BOUND: 'Fréquence (haute)',
        SENSITIVITY: 'Sensibilité',
        KWH_PER_YEAR: 'kWh/an',
        ASSIGNED_BY: 'Attribué par',
        ASSIGNED_TO: 'Attribué à',
        ASSIGNED_DATE: "Date d'attribution",
        LOSS_PER_YEAR: 'Perte (par an)',
        ASSIGNED: 'Attribué',
        CREATOR_USER_ID: "ID de l'utilisateur",
        CURRENCY: 'Devise',
        COMPLETED_ON: 'Complété le',
        IMAGES: 'Images sources',
        ACTION_STATUS_MODIFIED: "Statut de l'action modifié",
        TYPE: 'Type',
      },
      electricalRecordFields: {
        REGION: 'Région',
        LOCATION_TYPE: 'Type de Localisation',
        VOLTAGE: 'Tension',
        PHASE: 'Phase',
        FREQUENCY: 'Fréquence',
        ELECTRICAL_EQUIPMENT_TAG: 'Équipement/Composant',
        ELECTRICAL_EQUIPMENT_TYPE: "Type d'Équipement/Composant",
        FAULT_TYPE: 'Type de Défaillance',
        ELECTRICAL_NOTES: 'Notes',
      },
      IMPERIAL_LABELS: {
        FLOW: 'Flux (cfm)',
        AMBIENT_TEMPERATURE: 'Température ambiante (°F)',
        LEAK_PRESSURE: 'Pression (psi)',
        LEAK_TEMPERATURE: 'Température du gaz (°F)',
        DISTANCE: 'Distance (ft)',
        GAS_COST: 'Coût du gaz ($/ft³)',
        COMPRESSOR_FLOW: 'cfm par BHP',
        ALTITUDE: 'Altitude (ft)',
      },
      userFields: {
        EMAIL: 'E-mail',
        ROLE: 'Rôle',
        DEVICE_ID: "ID de l'appareil",
        SUBSCRIPTION_RENEWAL_DATE: "Date de renouvellement de l'abonnement",
        DATE_TIME: 'Créé',
        ACTIVE: 'Statut',
        SOFTWARE_VERSION: 'Version du logiciel',
        FIRMWARE_VERSION: 'Version du firmware',
        FIRST_NAME: 'Prénom',
        LAST_NAME: 'Nom de famille',
        COMPANY_SLUG: 'Entreprise',
        COMPANY_NAME: 'Entreprise',
      },
      auditFields: {
        documentId: "Numéro d'ID d'enregistrement",
        companyName: "Nom de l'entreprise",
        auditorName: "Nom de l'auditeur",
        auditorEmail: "E-mail de l'auditeur",
        createdAt: 'Créé le',
        updatedAt: 'Mis à jour le',
        comment: 'Commentaires',
        isLocked: "Verrouillage d'audit",
      },
      deviceFields: {
        DEVICE_ID: "ID de l'appareil",
        ACTIVE: 'Statut',
        REGISTERED_ON: 'Activé le',
        EVENT_COUNT: 'Enregistrements',
        UNIQUE_COMPANIES: 'Entreprises',
        LAST_USED_AT: 'Dernière utilisation le',
        LAST_USED_BY_NAME: 'Dernier nom utilisé',
        LAST_USED_BY_EMAIL: 'Dernier e-mail utilisé',
        LOCATION: 'Dernier emplacement utilisé',
        FIRMWARE_VERSION: 'Version du firmware',
      },
      statsFields: {
        labels: {
          TIMELINE: 'Chronologie',
          CRITERIA: 'Critères',
        },
        timeline: {
          LAST_3_MONTHS: '3 derniers mois',
          LAST_6_MONTHS: '6 derniers mois',
          LAST_12_MONTHS: '12 derniers mois',
          YEAR_TO_DATE: "Depuis le début de l'année",
          ALL_TIME: 'Depuis toujours',
        },
        criteria: {
          LOSS: 'Perte',
          FLOW: 'Débit',
          KWH: 'kWh',
        },
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: 'Nombre de fuites trouvées',
          NO_OF_LEAKS_REPAIRED: 'Nombre de fuites réparées',
          PERCENT_LEAKS_REPAIRED: '% Fuites réparées',
          VOL_FLOW_FOUND: 'Débit volumétrique trouvé (m³/h)',
          VOL_FLOW_REPAIRED: 'Débit volumétrique réparé (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '% Débit réparé',
          VALUE_FOUND: 'Valeur trouvée',
          VALUE_REPAIRED: 'Valeur réparée',
          ENERGY_SAVING_OPPORTUNITY_FOUND: "Opportunité d'économie d'énergie (trouvée) (kWh)",
          ENERGY_SAVING_OPPORTUNITY_PERCENT: "Opportunité d'économie d'énergie % (trouvée vs calculée ou mesurée)",
          ENERGY_SAVING_REALIZED_FIXED: "Économie d'énergie réalisée (réparée) (kWh)",
          ENERGY_SAVING_REALIZED_PERCENT: "% Économie d'énergie réalisée (réparée vs calculée ou mesurée)",
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Pression de contrôle du système réduite (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Débit volumétrique trouvé (cfm)',
          VOL_FLOW_REPAIRED: 'Débit volumétrique réparé (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: "Opportunité d'économie d'énergie (trouvée) (BTU)",
          ENERGY_SAVING_REALIZED_FIXED: "Économie d'énergie réalisée (réparée) (BTU)",
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Pression de contrôle du système réduite (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Indicateur de performance',
          ENPI_VALUE: 'Valeur EnPI',
          ENB_VALUE: 'Valeur EnB',
          SUCCESS_THRESHOLD: 'Seuil de réussite (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Assigné à',
        assignedBy: 'Assigné par',
        assignedDate: "Date d'assignation",
        requestReassign: 'Demander une réaffectation',
        actionStatus: "Statut de l'action",
        partsNeeded: 'Pièce requise',
        productionOutage: 'Arrêt demandé',
        timeToRepair: 'Temps de réparation',
        repairCost: 'Coût de réparation',
        closeOutRef: 'Réf. de clôture',
        'assignmentRecord.documentId': "ID de l'enregistrement",
        assignmentId: "ID d'assignation",
        owner: 'Propriétaire',
        companyName: 'Entreprise',
        assignmentActive: "Statut de l'assignation",
        'assignmentRecord.leakStatus.building': 'Unité/Équipement',
        'assignmentRecord.leakStatus.facility': 'Installation',
        'assignmentRecord.leakStatus.routeName': 'Itinéraire',
        'assignmentRecord.leakStatus.leakTag': 'Étiquette intelligente #/Réf. de travail #',
        'assignmentRecord.currency': 'Devise',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Mesure prise',
        CLOSE_OUT_REF: "Référence de clôture de l'action n°",
        PARTS_NEEDED: 'Nouvelle pièce/composant requis',
        PRODUCTION_OUTAGE: 'Production interrompue',
        REPAIR_COST: 'Coût ($)',
        REQUEST_REASSIGN: 'Demande de réaffectation',
        ACTION_STATUS: "Statut de l'action",
        TIME_TO_REPAIR: 'Temps nécessaire pour la réparation (minutes)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Débit par catégorie de fuite',
          LOSS_BY_LEAK: 'Perte par catégorie de fuite',
          TAGS_BY_LEAK: 'Étiquettes par catégorie de fuite',
          AVG_FLOW_BY_LEAK: 'Débit moyen par catégorie de fuite',
          SAVING_OPT_BY_REPAIR: 'Opportunités et économies par temps de réparation',
          FLOW_REPAIR_TIME: 'Débit par temps de réparation',
          LOSS_REPAIR_TIME: 'Perte par temps de réparation',
          FLOW_REPAIR_PROG: 'Débit par avancement des réparations',
          TOTAL_REPAIR_PROG: 'Perte par avancement des réparations',
          FLOW_BY_EQUIP_COMP: 'Débit par équipement/composant',
          LOSS_BY_EQUIP_COMP: 'Perte par équipement/composant',
          TAGS_BY_EQUIP_COMP: 'Étiquettes par équipement/composant',
        },
        leakCategoryLabels: {
          LOW: 'Bas',
          MEDIUM: 'Moyen',
          HIGH: 'Haut',
        },
        actionStatus: {
          OPEN: 'Ouvrir',
          COMPLETE: 'Complet',
        },
        equipmentTypes: {
          CONNECTOR: 'Connecteur',
          CONTROL_VALVE: 'Vanne de contrôle',
          HOSE: 'Tuyau',
          TUBE_FITTING: 'Raccord de tube',
          METER: 'Compteur',
          OPEN_ENDED_LINE: 'Ligne ouverte',
          PRESSURE_RELIEF_VALVE: 'Soupape de décharge',
          PUMP_SEAL: 'Joint de pompe',
          REGULATOR: 'Régulateur',
          VALVE: 'Valve',
          LEVEL_CONTROLLER: 'Régulateur de niveau',
          POSITIONER: 'Positionneur',
          PRESSURE_CONTROLLER: 'Régulateur de pression',
          TRANSDUCER: 'Transducteur',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Instrument pneumatique générique',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Compresseur alternatif générique',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Compresseur centrifuge générique',
        },
        mainGasValues: {
          AIR: 'Air',
          METHANE: 'Méthane (CH4)',
          CARBON_DIOXIDE: 'Dioxyde de carbone (CO2)',
          HYDROGEN: 'Hydrogène (H2)',
          HELIUM: 'Hélium (He)',
          NITROGEN: 'Azote (N2)',
          REFRIGERANT: 'Réfrigérant (HFCs)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oxygène (O)',
          WATER: 'Eau (Vapeur)',
        },
        leakTypeValues: {
          DEFAULT: 'Aucun sélectionné',
          HOLE: 'Trou',
          CRACK: 'Fissure',
          FITTING: 'Raccord',
          FLANGE: 'Bride',
          'GLAND/SEAL': 'Presse-étoupe/Joint',
          THREAD: 'Filetage',
          'TUBE/HOSE': 'Tube/Tuyau',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Air comprimé',
          VACUUM: 'Vide',
          STEAM: 'Vapeur',
          PROCESS_GAS: 'Gaz de procédé',
          LIQUID: 'Liquide',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Vis rotative',
          RECIPROCATING: 'Alternatif',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrifuge',
        },
        regionValues: {
          NORTH_AMERICA: 'Amérique du Nord',
          OUTSIDE_NORTH_AMERICA: 'Hors Amérique du Nord',
        },
        locationTypeValues: {
          RESIDENTIAL: 'Résidentiel',
          COMMERCIAL: 'Commercial',
          INDUSTRIAL: 'Industriel',
          MEDIUM_VOLTAGE: 'Moyenne Tension',
          HIGH_VOLTAGE: 'Haute Tension',
        },
        phaseValues: {
          SINGLE_PHASE: 'Monophasé',
          THREE_PHASE: 'Triphasé',
        },
        faultValues: {
          SELECT_FAULT_TYPE: 'Sélectionnez le type de défaut',
          KNICKS_OR_VOIDS_IN_INSULATION: "Fissures ou vides dans l'isolation",
          CORONA: 'Corona',
          TREEING: "Formation d'arbre",
          TRACKING: 'Suivi',
          ARCING: 'Arcing',
          'DIRTY_POWER/POWER_DISTORTION': 'Puissance sale/distorsion',
          MECHANICAL_LOOSENESS: 'Lâche mécanique',
          DELAMINATION_OF_WINDINGS: 'Délamination des enroulements',
          LOOSE_PANEL: 'Panneau lâche',
          VIBRATING_NAMEPLATE: 'Plaque signalétique vibrante',
          ANTAGONISTIC_ULTRASOUND: 'Ultrason antagoniste',
          OTHER: 'Autre',
        },
        electricEquipmentTypeValues: {
          MCC: 'MCC (Contrôle de moteur)',
          'TRANSFORMER,_DRY': 'Transformateur (sec)',
          'TRANSFORMER,_OIL': 'Transformateur (huile)',
          SWITCH_GEAR: 'Équipement de coupure',
          PANEL: 'Panneau',
          BUSDUCT: 'Busduct',
          CABLE: 'Câble',
          CONNECTOR: 'Connecteur',
          BREAKER: 'Disjoncteur',
          BUSHING: 'Douille',
          TRANSMISSION_LINE: 'Ligne de transmission',
          'TRANSFORMER,_POLE': 'Transformateur (poteau)',
          BATTERY: 'Batterie',
          BATTERY_BANK: 'Bloc de batteries',
          TERMINALS: 'Bornes',
          'TRANSFORMER,_STEP_UP': 'Transformateur (haussant)',
          'TRANSFORMER,_STEP_DOWN': 'Transformateur (abaissant)',
          DISTRIBUTION_PANEL: 'Panneau de distribution',
          BREAKER_PANEL: 'Panneau de disjoncteur',
          RELAYS: 'Relais',
          CAPACITORS: 'Condensateurs',
          REACTORS: 'Réacteurs',
          UPS: 'Onduleur (UPS)',
          GROUNDING: 'Mise à la terre',
          POWER_METERS: "Compteurs d'énergie",
          CURRENT_TRANSFORMERS: 'Transformateurs de courant',
          POTENTIAL_TRANSFORMERS: 'Transformateurs de potentiel',
          SURGE_PROTECTION: 'Protection contre les surtensions',
          CABLE_TRAYS: 'Trays de câbles',
          EMERGENCY_LIGHTING: "Éclairage d'urgence",
          CONTACTORS: 'Contacteurs',
          INVERTERS: 'Onduleurs',
          SOLAR_PANELS: 'Panneaux solaires',
          BATTERY_STORAGE_SYSTEMS: 'Systèmes de stockage de batterie',
        },
        recordTypes: {
          leak: 'Fuite',
          electrical: 'Électrique',
        },
        labels: {
          LOW: 'Bas',
          MEDIUM: 'Moyen',
          HIGH: 'Haut',
          EQUIPMENT: 'Équipement',
          SAVINGS: 'Économies',
          OPPORTUNITY: 'Opportunité',
          LOSS: 'Perte',
          REPAIRED: 'Réparé',
          UNREPAIRED: 'Non réparé',
          LESS_15_DAYS: 'Moins de 15 jours',
          DAYS_16_TO_30: '15 à 30 jours',
          DAYS_31_TO_60: '31 à 60 jours',
          DAYS_61_TO_90: 'Plus de 60 jours',
          LESS_THAN_15_DAYS: 'Réparé en moins de 15 jours',
          '16_TO_30_DAYS': 'Réparé en 16-30 jours',
          '31_TO_60_DAYS': 'Réparé en 31-60 jours',
          MORE_THAN_60_DAYS: 'Réparé en 61-90 jours',
          AIR: 'Air',
          METHANE: 'Méthane (CH4)',
          CARBON_DIOXIDE: 'Dioxyde de carbone (CO2)',
          HYDROGEN: 'Hydrogène (H2)',
          HELIUM: 'Hélium (He)',
          NITROGEN: 'Azote (N2)',
          REFRIGERANT: 'Réfrigérant (HFCs)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oxygène (O)',
          WATER: 'Eau (Vapeur)',
          DEFAULT: 'Aucun sélectionné',
          HOLE: 'Trou',
          CRACK: 'Fissure',
          FITTING: 'Raccord',
          FLANGE: 'Bride',
          'GLAND/SEAL': 'Presse-étoupe/Joint',
          THREAD: 'Filetage',
          'TUBE/HOSE': 'Tube/Tuyau',
          COMPRESSED_AIR: 'Air comprimé',
          VACUUM: 'Vide',
          STEAM: 'Vapeur',
          PROCESS_GAS: 'Gaz de procédé',
          LIQUID: 'Liquide',
          ROTARY_SCREW: 'Vis rotative',
          RECIPROCATING: 'Alternatif',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrifuge',
          CONNECTOR: 'Connecteur',
          CONTROL_VALVE: 'Vanne de contrôle',
          HOSE: 'Tuyau',
          TUBE_FITTING: 'Raccord de tube',
          METER: 'Compteur',
          OPEN_ENDED_LINE: 'Ligne ouverte',
          PRESSURE_RELIEF_VALVE: 'Soupape de décharge',
          PUMP_SEAL: 'Joint de pompe',
          REGULATOR: 'Régulateur',
          VALVE: 'Valve',
          LEVEL_CONTROLLER: 'Régulateur de niveau',
          POSITIONER: 'Positionneur',
          PRESSURE_CONTROLLER: 'Régulateur de pression',
          TRANSDUCER: 'Transducteur',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Instrument pneumatique générique',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Compresseur alternatif générique',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Compresseur centrifuge générique',
        },
        leakRecordPDF: 'Enregistrement • Exporté le {{date}}',
        leakTag: 'Étiquettes intelligentes • Exporté le {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Tag du compresseur',
          COMPRESSOR_MAKE: 'Fabricant du compresseur',
          COMPRESSOR_MODEL: 'Modèle du compresseur',
          COMPRESSOR_TYPE: 'Type de compresseur',
          NAMEPLATE_HP: 'Puissance nominale (HP)',
          NAMEPLATE_CAPACITY: 'Capacité nominale (m³/h)',
          MOTOR_EFFICIENCY: 'Efficacité du moteur (%)',
          MOTOR_VOLTAGE: 'Tension du moteur',
          FULL_LOAD_CURRENT: 'Courant à pleine charge (Ampères)',
          FULL_LOAD_INPUT_POWER: "Puissance d'entrée à pleine charge (kW)",
          RATED_PRESSURE: 'Pression nominale (kPag)',
          PRESSURE_SETPOINT: 'Point de consigne de pression (kPag)',
          CONTROL: 'Contrôle',
          TOTAL_AIR_STORAGE_SIZE: "Taille totale de stockage d'air (l)",
          IS_ACTIVE: 'Compresseur actif / hors service',
          OPERATION_TYPE: 'Trim / Charge de base',
          PERCENT_LOAD: '% Charge',
          COMPRESSOR_SETPOINT_SEU: 'Point de consigne du compresseur (kPag)',
          COMPRESSOR_FLOW_SEU: 'Débit du compresseur (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'Point de consigne de pression du système (kPag)',
          SYSTEM_MEASURED_FLOW: 'Débit mesuré du système (m³/h)',
          DATE_TIME_RUNNING_STATE: "Date et heure de l'état de fonctionnement",
          NOTES: 'Remarques',
          NAMEPLATE: 'Plaque signalétique du compresseur',
          DATASHEET: 'Fiche technique du compresseur',
          BHP: 'BHP',
          OPERATING_HOURS: 'Heures de fonctionnement',
          LOADED_PERCENT_TIME_RUNNING: '% Temps de fonctionnement',
          LOADED_PERCENT_FULL_LOAD_BHP: '% BHP en pleine charge (Capacité)',
          UNLOADED_PERCENT_TIME_RUNNING: '% Temps de fonctionnement',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% BHP en pleine charge (Capacité)',
          ENERGY_COST: 'Tarif électrique agrégé ($)',
          ANNUAL_ENERGY_COST: 'Coût énergétique annuel ($)',
          ANNUAL_ENERGY_KWH: 'Énergie annuelle en kWh',
          COST_FULLY_LOADED: 'Coût en pleine charge ($)',
          COST_UNLOADED: 'Coût à vide ($)',
          KWH_PER_YEAR_LOADED: 'kWh par an en charge',
          KWH_PER_YEAR_UNLOADED: 'kWh par an à vide',
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Capacité nominale (cfm)',
          FULL_LOAD_INPUT_POWER: "Puissance d'entrée à pleine charge (BTU/h)",
          RATED_PRESSURE: 'Pression nominale (psi)',
          PRESSURE_SETPOINT: 'Point de consigne de pression (psi)',
          TOTAL_AIR_STORAGE_SIZE: "Taille totale de stockage d'air (gal)",
          COMPRESSOR_SETPOINT_SEU: 'Point de consigne du compresseur (psi)',
          COMPRESSOR_FLOW_SEU: 'Débit du compresseur (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'Point de consigne de pression du système (psi)',
          SYSTEM_MEASURED_FLOW: 'Débit mesuré du système (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: "Fournisseur d'énergie",
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: 'Unité',
          REBATE_COST: '$/kWh',
          REBATE_UNIT: 'Unité',
          ENERGY_SOURCES: "Sources d'énergie",
          FE_HOURS_OPERATION: 'Heures de fonctionnement',
          FE_ENERGY_COST: "Coût de l'énergie ($/kWh)",
          FE_EFFICIENCY: 'Efficacité (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: 'Efficacité (kW/cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'Erreur de validation de la requête. Veuillez vérifier les champs du formulaire',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Enregistrer un appareil',
          help: 'Aide',
          metric: 'Métrique',
          imperial: 'Impérial',
          downloadApk: 'Télécharger APK',
          downloadOta: 'Télécharger le fichier',
        },
        otaDialog: {
          title: 'Télécharger le fichier',
          downloadButton: 'Télécharger',
          serialNumberLabel: "Numéro de série de l'appareil",
          serialNumberRequired: 'Numéro de série requis',
          fileDownloaded: 'Fichier {{fileName}} téléchargé avec succès',
          errorFetchingFile: 'Erreur lors de la récupération du fichier. Veuillez réessayer plus tard',
          selectSerialNumber: "Sélectionnez un numéro de série d'appareil",
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'Vous êtes sur le plan Essentiel.',
            dialogTwo: 'Cliquez ',
            dialogThree: 'ici',
            dialogFour: 'pour acheter une nouvelle licence administrateur ou contactez',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable: 'Nous rencontrons des difficultés techniques. Notre service peut être affecté. Désolé pour le dérangement.',
          downForMaintenance: 'Nous sommes en maintenance. Nous serons de retour bientôt.',
          tagsManagement: {
            dialogOne: 'Acheter plus',
            dialogTwo: 'Étiquettes intelligentes PROSARIS',
            dialogThree: 'ici！',
          },
        },
        mobileNav: {
          index: {
            menu: 'Menu',
            editProfile: 'Modifier le profil',
            logout: 'Déconnexion',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Tout effacer',
            readAll: 'Tout marquer comme lu',
            noNotifications: 'Pas de notifications',
          },
        },
        index: {
          dashboard: 'Tableau de bord',
          records: 'Enregistrements',
          actions: 'Actions',
          company: 'Entreprise',
          tags: 'Étiquettes',
        },
        loggedInAs: 'Connecté en tant que',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: "Impossible de déterminer l'étiquette par le champ {{field}}",
        cannontDeterminByLabel: "Impossible de déterminer le champ par l'étiquette {{label}}",
        active: 'Actif',
        closed: 'Fermé',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Tableau de bord',
        records: 'Enregistrements',
        actions: 'Actions',
        company: 'Entreprise',
        tags: 'Étiquettes',
        redirecting: 'Redirection en cours...',
      },
      account: {
        index: {
          updatePassword: 'Mot de passe mis à jour avec succès.',
          accountDeletedSuccess: 'Le compte {{user}} a été supprimé avec succès',
          accountDeletedError: 'Impossible de supprimer le compte pour le moment. Veuillez réessayer plus tard.',
          authenticationError: "Échec de l'authentification de l'utilisateur",
          personalDetails: 'Détails personnels',
          currentPasswordIsRequired: 'Le mot de passe actuel est requis',
          username: "Nom d'utilisateur",
          changePassword: 'Changer le mot de passe',
          currentPassword: 'Mot de passe actuel',
          profilePicture: 'Photo de profil',
          dangerZone: 'Zone de danger',
          integrations: 'Intégrations',
          sosShipments: 'SOS Inventaire',
          sosSyncMessage:
            "Ce processus déclenche le traitement des commandes SOS dans Prosaris avec les dernières données de SOS Inventaire. Bien que ce processus soit généralement géré automatiquement toutes les 4 heures via une tâche planifiée, utiliser ce bouton vous permet d'initier le processus manuellement.",
          sosSyncSuccess: 'La synchronisation SOS a été déclenchée avec succès',
          triggerSosSync: 'Démarrer la synchronisation SOS',
          hubspot: 'HubSpot',
          hubspotSyncMessage:
            'Ce processus pousse instantanément les dernières données de la plateforme vers HubSpot. Cette synchronisation se fait automatiquement toutes les heures via une tâche planifiée, mais en cliquant sur le bouton, vous pouvez déclencher la synchronisation manuellement à tout moment.',
          hubspotSyncSuccess: 'La synchronisation HubSpot a été déclenchée avec succès',
          triggerHubspotSync: 'Démarrer la synchronisation HubSpot',
          deleteAccountTitle: 'Supprimer le compte',
          deleteAccountMessage:
            'Le compte ne sera plus disponible et toutes les informations personnelles seront définitivement supprimées.',
          alertTitle: 'Veuillez confirmer la suppression du compte',
          deleteDialog: {
            dialogTwo:
              'La suppression de votre compte supprimera définitivement toutes vos informations personnelles de notre base de données.',
            dialogThree: 'CETTE ACTION NE PEUT PAS ÊTRE ANNULÉE.',
            dialogFour: 'Veuillez entrer votre mot de passe pour continuer.',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'Cache vidé avec succès !',
          editProfile: 'Modifier le profil',
          clearCache: 'Vider le cache',
          alertTitle: "Veuillez confirmer la vidange du cache de l'application",
          deleteAll: 'Tout supprimer',
          accordionDialog: {
            dialogOne: 'Cela supprimera tous les paramètres stockés pour les pages suivantes :',
            dialogTwo: 'Graphiques, filtres de graphiques, période',
            dialogThree: 'Filtres, Tri, Colonnes, Aperçu',
            dialogFour: 'Filtres, colonnes',
            dialogFive: 'Entreprise sélectionnée',
            dialogSix: 'Filtres, colonnes',
          },
          deleteSelective: 'Suppression sélective',
          selectiveDialog: 'Veuillez sélectionner les paramètres stockés que vous souhaitez supprimer :',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Période',
            CHART_FILTERS: 'Filtres de graphique',
            CHARTS: 'Graphiques',
          },
          Records: {
            HIGHLIGHTED: 'Aperçu',
            COLUMNS: 'Colonnes',
            FILTERS: 'Filtres',
            SORT_COLUMN: 'Tri',
          },
          Actions: {
            FILTERS: 'Filtres',
            COLUMNS: 'Colonnes',
          },
          Company: {
            SELECTED: 'Entreprise sélectionnée',
          },
          Tags: {
            FILTERS: 'Filtres',
            COLUMNS: 'Colonnes',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Entreprise',
          'leakStatus.building': 'Unité/Équipement',
          'leakStatus.facility': 'Installation',
          'leakStatus.leakTag': 'Étiquette intelligente n°/Référence de travail n°',
        },
        tableBody: {
          noAssignmentsToDisplay: 'Aucune assignation à afficher',
        },
        filtersCommonWords: {
          recordsFilter: 'Filtre des enregistrements',
          column: 'Colonne',
          selectAColumn: 'Sélectionnez une colonne...',
          addSearchTerm: 'Ajouter un terme de recherche...',
          filtersArentAvailable: 'Les filtres ne sont pas disponibles',
          value: 'Valeur',
          exportCSV: 'Exporter en CSV',
          show: 'Afficher',
          loadMoreRecords: "Charger plus d'enregistrements",
          selectedAllRecords: 'Tous les enregistrements sélectionnés ({{totalNum}})',
          selectedFromRecords: 'Sélectionné {{selectedNum}} parmi {{totalNum}} enregistrements',
          reset: 'Réinitialiser',
        },
        invalidColumn: 'Colonne invalide {{column}}',
        assignedActionRecords: "Enregistrements d'actions assignées",
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'Vous avez oublié votre mot de passe ?',
          successMessage: 'Nous avons envoyé un e-mail de confirmation à',
        },
        authSuccess: {
          thanks: 'Merci !',
          backToLogin: 'Retour à la connexion',
        },
        loginView: {
          forgotPass: 'Mot de passe oublié?',
          createAccount: 'Créer un compte',
          passwordIsRequired: 'Le mot de passe est requis',
          resetPassword: 'Réinitialiser le mot de passe',
          contactSupport: 'Veuillez contacter support@prosaris.ca',
          incorrectEmailOrPassword: 'Email ou mot de passe incorrect',
          resetPassDialog: {
            dialogOne: 'Vous devez réinitialiser votre mot de passe avant de continuer.',
            dialogTwo: 'Un e-mail de réinitialisation de mot de passe vous a été envoyé avec des instructions.',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'Le mot de passe a été réinitialisé avec succès',
          enterNewPassword: 'Entrez votre nouveau mot de passe ci-dessous.',
          passwordChanged: 'Votre mot de passe a été changé.',
        },
        createCompanyView: {
          createCompany: 'Créer une entreprise',
          companyName: "Nom de l'entreprise",
          successCreatingCompany: 'Votre entreprise a été créée.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Erreur inattendue lors du chargement des données de fuite',
          calculatingError: 'Erreur inattendue lors du calcul',
          dbMeasured: 'Db mesuré',
          calculate: 'Calculer',
          calculating: 'Calcul en cours...',
          outflowCoefficient: 'Coefficient de décharge',
          kwhPerYear: 'Kwh/an',
        },
      },
      companies: {
        commonUsedWords: {
          assignedAdminLicenses: 'Licences administrateur assignées',
          company: 'Entreprise',
          totalAccounts: 'Comptes totaux',
          email: 'E-mail',
          name: 'Nom',
          loadCompanyUsersError: "Échec du chargement des utilisateurs de l'entreprise. Veuillez réessayer plus tard.",
          activated: 'Activé',
          deactivated: 'Désactivé',
          activating: 'Activation en cours',
          deactivating: 'Désactivation en cours',
          deactivate: 'Désactiver',
          activate: 'Activer',
          set: 'Définir',
          youAreGoingTo: 'Vous allez',
          areYouSure: 'Êtes-vous sûr ?',
          companyNameIsRequired: "Le nom de l'entreprise est requis",
          editCompany: "Modifier l'entreprise",
          createCompany: 'Créer une entreprise',
          viewHistory: "Voir l'historique",
          onEssentialPlan: 'Vous êtes sur un plan essentiel. Veuillez contacter support@prosaris.ca',
          exportCSV: 'Exporter en CSV',
        },
        groupPerformance: {
          title: 'Performance du Groupe',
          normalizeData: 'Normaliser les Données',
          selectTimeline: 'Sélectionner la Chronologie',
          selectCriteria: 'Sélectionner les Critères',
        },
        charts: {
          chart: {
            criteria: {
              KWH: 'kWh Total/an',
              LOSS: 'Perte Totale ($/an)',
              FLOW: 'Flux Massique Total',
            },
            noDataAvailable: 'Aucune donnée disponible',
            topNCompanies: 'Top {{numCompanies}} Entreprises',
          },
        },
        accounts: {
          accountDialog: {
            companyIsRequired: " L'entreprise est requise",
            roleIsRequired: 'Le rôle est requis',
            createAccountSuccessMessage: 'Compte créé avec succès',
            emailTakenErrorMessage: 'Un compte avec cet e-mail existe déjà dans le système',
            errorWhileCreatingAccountMessage: 'Erreur lors de la création du compte. Veuillez réessayer plus tard.',
            changeAccountSuccessMessage: 'Compte mis à jour avec succès',
            errorWhileChangingAccountMessage: 'Erreur lors de la mise à jour du compte. Veuillez réessayer.',
            editAccount: 'Modifier le compte',
            createAccount: 'Créer un compte',
            role: 'Rôle',
          },
          changeOwner: {
            changeOwnerMessage: 'Voulez-vous également changer le propriétaire des enregistrements de fuite liés à ce compte ?',
            no: 'Non, garder le compte actuel en place',
          },
          contentCell: {
            loadAccountRolesError: 'Échec du chargement des informations sur les rôles des comptes. Veuillez réessayer plus tard',
            changeRoleSuccessMessage: 'Le compte {{email}} a un nouveau rôle',
            changeRoleErrorMessage: 'Erreur lors de la mise à jour du rôle pour le compte {{email}}. Veuillez réessayer plus tard',
            editUser: "Modifier l'utilisateur",
            adminSetPassword: 'Administrateur définit le mot de passe',
            adminSetSubRenewalDate: 'Date de renouvellement définie par l’administrateur',
            resetPassword: 'Réinitialiser le mot de passe',
            user: 'Utilisateur',
            deleteUser: "Supprimer l'utilisateur",
          },
          index: {
            noUsersToDisplay: 'Aucun utilisateur à afficher',
            changeStatusSuccessMessage: 'Utilisateur {{isActive}} avec succès',
            changeStatusErrorMessage: "Erreur lors de {{isActive}} l'utilisateur. Veuillez réessayer",
            changeOwnerSuccessMessage: 'Les enregistrements ont été transférés à un nouveau propriétaire',
            changeOwnerErrorMessage: 'Impossible de transférer les enregistrements à un nouveau propriétaire. Veuillez réessayer plus tard',
            deleteAccountMutationSuccessMessage: 'Le compte {{email}} a été supprimé avec succès',
            deleteAccountMutationErrorMessage: 'Impossible de supprimer le compte {{email}} pour le moment. Veuillez réessayer plus tard',
            resetAccountPassWordSuccessMessage: 'E-mail de réinitialisation du mot de passe envoyé avec succès',
            users: 'utilisateurs',
            noneAvailable: 'Aucun disponible',
            of: 'de',
            contactSupport:
              "Veuillez contacter support@prosaris.ca pour plus d'informations sur les licences d'administrateur d'entreprise",
            findAccount: 'Trouver un compte',
            placeholder: "Tapez le nom ou l'e-mail...",
            tooltip: "Vous avez besoin d'au moins une licence d'administrateur d'entreprise avant de pouvoir inviter des utilisateurs",
            invite: 'Inviter',
            alertDialog: {
              messageOne: "Veuillez confirmer la demande de réinitialisation du mot de passe de l'utilisateur",
              messageTwo: 'Veuillez confirmer le changement de statut du compte',
              messageThree: 'Veuillez confirmer la suppression du compte',
            },
            sendAPasswordResetEmail: 'Vous allez envoyer un e-mail de réinitialisation de mot de passe pour le compte suivant :',
            confirmMessage: 'Voulez-vous continuer ?',
            followingAccount: 'compte suivant :',
            delete: 'SUPPRIMER',
            userCreatedDialogTitle: 'Lien de réinitialisation du mot de passe utilisateur envoyé avec succès',
            userCreatedDialogDescription:
              "Un lien de réinitialisation du mot de passe a été envoyé à l'adresse e-mail de l'utilisateur. Vous pouvez également copier le lien ci-dessous.",
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'Mot de passe du compte mis à jour avec succès',
            changedUserPasswordErrorMessage: 'Erreur lors de la mise à jour du compte. Veuillez réessayer.',
            setNewPassword: 'Définir un nouveau mot de passe',
            accountPasswordUpdated: 'Mot de passe du compte mis à jour',
            accountPasswordUpdatedDescription:
              'Le mot de passe du compte a été mis à jour avec succès. Vous pouvez cliquer sur le bouton ci-dessous pour copier le nouveau mot de passe.',
          },
          adminSetSubRenewalDate: {
            title: 'Définir la date de renouvellement de l’abonnement',
            subRenewalDate: 'Date de renouvellement de l’abonnement',
            changedSubRenewalDateSuccessMessage: 'Date de renouvellement de l’abonnement mise à jour avec succès',
            changedSubRenewalDateErrorMessage: 'Erreur lors de la mise à jour du compte. Veuillez réessayer.',
          },
          setPassword: {
            automatic: 'Générer automatiquement un mot de passe fort',
            manual: 'Créer un mot de passe manuellement',
            copyLinkInstruction: "Vous pourrez voir et copier le mot de passe à l'étape suivante",
          },
          userCreatedDialog: {
            title: 'Utilisateur créé avec succès',
            description:
              "Votre nouvel utilisateur peut maintenant commencer à utiliser la plateforme. L'utilisateur recevra également un lien par e-mail pour réinitialiser son mot de passe.",
            passwordCopied: 'Mot de passe copié dans le presse-papiers',
            passwordResetLinkCopied: 'Lien de réinitialisation du mot de passe copié dans le presse-papiers',
            copyPassword: 'Copier le mot de passe',
            copyPasswordResetLink: 'Copier le lien de réinitialisation du mot de passe',
          },
          useRoleOptions: {
            notAvailable: '(non disponible)',
            adminSeatTaken: '(le siège administrateur est pris)',
            allSeatsAreTaken: '(tous les {{numSeats}} sièges sont pris)',
            adminRolesTitle: "Rôles d'administrateur",
            roles: {
              superAdmin: 'Administrateur principal',
              companyAdmin: 'Entreprise (Administrateur)',
              superCompanyAdmin: 'Société  (Administrateur)',
              companyRead: 'Entreprise (Lecture)',
              superAdminRead: 'Administrateur principal (lecture seule)',
              integrator: 'Intégrateur',
              auditor: 'Auditeur',
              user: 'Utilisateur',
              essential: 'Prosaris Essentiel',
            },
            rolesDescriptions: {
              superAdmin: 'Autorisé à avoir un contrôle total sur plusieurs entreprises.',
              companyAdmin: "Autorisé à visualiser, éditer et assigner des enregistrements d'entreprise.",
              superCompanyAdmin: "Autorisé à avoir un contrôle total sur les enregistrements d'entreprise et les utilisateurs.",
              companyRead: "Autorisé à visualiser les enregistrements d'entreprise en mode lecture seule.",
              superAdminRead: 'Autorisé à avoir un accès en lecture seule à toutes les entreprises.',
              integrator: 'Permet aux intégrations tierces de gérer les entreprises et les comptes.',
              auditor: "Permettre l'audit des enregistrements de fuite.",
              user: 'Autorisé à créer/mettre à jour/supprimer ses propres enregistrements.',
              essential: 'Un rôle avec des fonctionnalités limitées.',
            },
            nonAdminRolesTitle: 'Rôles non administrateur',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Échec du chargement des audits. Veuillez réessayer plus tard',
            audits: 'audits',
            noAuditsToDisplay: 'Aucun audit à afficher',
            findByAuditorEmail: "Rechercher par e-mail de l'auditeur",
            placeholder: "Tapez l'e-mail de l'auditeur...",
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: "Le nom de l'installation est requis",
              hoursYearlyIsRequired: "Le nombre total d'heures/an est requis",
              totalHoursYearly: "Le nombre total d'heures/an ne peut pas dépasser 8760",
              invalidPhoneNumber: 'Numéro de téléphone invalide',
              createErrorMessage: 'Les heures x les quarts ne peuvent pas dépasser 24 heures',
              loadFacilityErrorMessage: "Échec du chargement de l'installation. Veuillez réessayer plus tard",
              createFacilitySuccessMessage: 'Installation créée avec succès',
              createFacilityErrorMessage: "Erreur lors de la création de l'installation. Veuillez réessayer plus tard.",
              changeFacilitySuccessMessage: 'Installation mise à jour avec succès',
              changeFacilityErrorMessage: "Erreur lors de la mise à jour de l'installation. Veuillez réessayer.",
              facilityName: "Nom de l'installation",
              abbreviation: 'Abréviation',
              address: 'Adresse',
              enterALocation: 'Entrez un lieu',
              tooltip: "pour les activités de gestion de l'énergie, également approbateur de rapport",
              energyMgtOwner: "Responsable de la gestion de l'énergie",
              title: 'Titre',
              phone: 'Téléphone',
              tooltipTwo: 'pour les renouvellements de solution et les problèmes généraux de compte',
              accountOwner: 'Propriétaire du compte',
              manufacturingArea: 'Zone de production',
              size: 'Taille',
              unit: 'Unité',
              hoursOfOperation: "Heures d'ouverture",
              auditFrequency: "Fréquence d'audit",
            },
            operationHours: {
              daysPerWeek: 'Jours/Semaine',
              shiftsPerDay: 'Quarts/Jour',
              hoursPerShift: 'Heures/Quart',
              weeksPerYear: '52 semaines/an',
              totalHoursYearly: 'Total = Heures/An',
            },
            constants: {
              constant: 'Constant',
              monthly: 'Mensuel',
              threeMonth: '3 Mois',
              fourMonth: '4 Mois',
              sixMonth: '6 Mois',
              nineMonth: '9 Mois',
              annual: 'Annuel',
              sqft: 'Pieds carrés [SQFT]',
              sqm: 'Mètres carrés [SQM]',
            },
          },
          index: {
            users: 'Utilisateurs',
            devices: 'Dispositifs',
            facility: 'Installation',
            energy: 'Énergie',
            seu: 'Équipements énergétiques essentiels - Comp.',
            enpi: 'Indicateurs de performance énergétique/Repères énergétiques',
            routes: 'Itinéraires',
            audit: 'Audits',
            comingSoon: 'Bientôt disponible...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'Échec du chargement des EnPIs. Veuillez réessayer plus tard',
            createEnpisSuccessMessage: 'EnPIs créés avec succès',
            createEnpisErrorMessage: 'Erreur lors de la création des EnPIs. Veuillez réessayer plus tard.',
            changeEnpisSuccessMessage: 'EnPIs mis à jour avec succès',
            changeEnpisErrorMessage: 'Erreur lors de la mise à jour des EnPIs. Veuillez réessayer.',
            showHistory: "Afficher l'historique",
          },
          changeHistory: {
            bulkOperation: "en tant qu'opération en masse",
            details: 'détails',
            created: 'créé',
            updated: 'mis à jour',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'Historique',
            noHistory: 'Aucun historique',
          },
        },
        energy: {
          index: {
            utilProvider: "Fournisseur d'énergie",
            energyUse: "Utilisation de l'énergie et type de consommation",
            error: {
              loadFacility: "Échec du chargement de l'installation. Veuillez réessayer plus tard",
              loadEnergy: 'Échec du chargement des données énergétiques. Veuillez réessayer plus tard',
              loadUtilProviderLogo: "Échec du chargement du logo du fournisseur d'énergie. Veuillez réessayer plus tard",
              createEnergy: 'Échec de la création des données énergétiques. Veuillez réessayer plus tard',
              createUtilProviderLogo: "Échec du téléchargement du logo du fournisseur d'énergie. Veuillez réessayer plus tard",
              updateEnergy: 'Échec de la mise à jour des données énergétiques. Veuillez réessayer plus tard',
              deleteUtilProviderLogo: "Échec de la suppression du logo du fournisseur d'énergie. Veuillez réessayer plus tard",
            },
            success: {
              createEnergy: 'Données énergétiques créées avec succès',
              updateEnergy: 'Données énergétiques mises à jour avec succès',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: "Coût de l'énergie",
              incentiveRate: "Taux d'Incitation",
              energySources: "Sources d'énergie",
              per: 'par',
              validations: {
                utilProviderIsRequired: "Le fournisseur d'énergie est requis",
                costOfEnergyIsRequired: "Le coût de l'énergie est requis",
                costOfEnergyMustBeNumber: "Le coût de l'énergie doit être un nombre",
                costOfEnergyMustBeGreaterThanZero: 'Le coût énergétique doit être supérieur 0',
                costOfRebateMustBeAtLeatZero: 'Le coût de la remise doit être au moins égal à 0',
                costOfRebateMustBeNumber: 'Le coût de la remise doit être un nombre',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: 'La taille du fichier doit être inférieure à 1 Mo',
                preview: "Échec de l'aperçu de l'image",
              },
              logo: 'Logo',
              addLogo: 'Ajouter un logo',
              removeLogo: 'Supprimer le logo',
            },
            EnergySources: {
              energySource: "Source d'énergie",
              percentage: 'Pourcentage',
              selectEnergySource: "Sélectionner une source d'énergie",
              addEnergySource: "Ajouter une source d'énergie",
              totalPercentage: 'Pourcentage total',
              mustBeEqualTo100: '(doit être égal à 100%)',
              constants: {
                energySources: {
                  NATURAL_GAS: 'Gaz naturel',
                  FUEL_OIL: 'Fioul',
                  DIESEL: 'Diesel',
                  GASOLINE: 'Essence',
                  PROPANE: 'Propane',
                  COAL: 'Charbon',
                  WIND: 'Éolien',
                  GEOTHERMAL: 'Géothermie',
                  BIOMASS: 'Biomasse',
                  STEAM: 'Vapeur',
                  COMPRESSED_AIR: 'Air comprimé',
                  CHILLED_WATER: 'Eau réfrigérée',
                  NUCLEAR: 'Nucléaire',
                  HYDRO: 'Hydro',
                  SOLAR: 'Solaire',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: 'Approche de calcul actuelle',
              currentCalcApproachMessage:
                "Cette méthode de calcul s'applique actuellement par défaut à tous les champs 'Coût de l'énergie', sauf si l'utilisateur l'a remplacée.",
              alertTitle: "Réinitialiser l'approche de calcul",
              alertMessageOne:
                "Cette action cessera d'utiliser l'approche de calcul actuelle pour tous les champs 'Coût de l'énergie' à l'avenir.",
              alertMessageTwo: "Veuillez noter que ce changement n'affectera aucun enregistrement existant.",
              alertMessageThree: 'Êtes-vous sûr de vouloir procéder à la réinitialisation ?',
            },
            ReportType: {
              calcApproach: 'Approche de calcul',
              constants: {
                calculations: {
                  FIXED: 'Efficacité fixe',
                  USDOE: 'DoE des États-Unis',
                  NREL: 'NREL Chapitre 22',
                },
              },
              setAsCurrent: 'Définir comme actuel',
              setAsCurrentMessage: "Cette méthode de calcul sera utilisée pour tous les champs 'Coût de l'énergie' à l'avenir.",
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: "L'efficacité doit être supérieure ou égale à 0",
                  efficiencyMustBeNumber: "L'efficacité doit être un nombre",
                  operationHoursMustBeGreaterThanZero: 'Les heures de fonctionnement doivent être supérieures ou égales à 0',
                  operationHoursMustBeLessThan8760: 'Les heures de fonctionnement doivent être inférieures à 8760',
                  operationHoursMustBeNumber: 'Les heures de fonctionnement doivent être un nombre',
                },
              },
              UsDoe: {
                loaded: 'Chargé',
                unloaded: 'Déchargé',
                calcSummary: 'Résumé du calcul',
                currentOperatingSystems: "Systèmes d'exploitation actuels",
                noCompsFoundAddCompOne: "Aucun compresseur trouvé. Ajoutez un compresseur à partir de l'",
                noCompsFoundAddCompTwo: 'onglet ci-dessus.',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Erreur lors du téléchargement de la plaque signalétique. Veuillez réessayer plus tard.',
            dataSheetError: 'Erreur lors du téléchargement de la fiche technique. Veuillez réessayer plus tard.',
            createSuccess: 'Compresseur {{tag}} créé',
            createError: 'Échec de la création du compresseur. Veuillez réessayer plus tard.',
            createCompressor: 'Créer un compresseur',
          },
          compressorFile: {
            tip: 'Ajouter un fichier',
            removeFile: 'Supprimer le fichier',
            removeImage: "Supprimer l'image",
            title: 'Veuillez confirmer la suppression du compresseur {{prop}}',
            proceed: 'Voulez-vous continuer ?',
          },
          compressorForm: {
            compTagIsReq: 'Le tag du compresseur est requis',
            hpMustBeNum: 'La puissance nominale doit être un nombre',
            capMustBeNum: 'La capacité nominale doit être un nombre',
            motorEffMustBeNum: "L'efficacité du moteur doit être un nombre",
            motorVoltMustBeNum: 'La tension du moteur doit être un nombre',
            loadMustBeNum: 'Le courant à pleine charge doit être un nombre',
            loadInputMustBeNum: "La puissance d'entrée à pleine charge doit être un nombre",
            pressureMustBeNum: 'La pression nominale doit être un nombre',
            setpointMustBeNum: 'Le point de consigne de pression doit être un nombre',
            airMustBeNum: "La taille du stockage d'air doit être un nombre",
            addImage: 'Ajouter une image',
            addFile: 'Ajouter un fichier',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Vis rotative',
              RECIPROCATING: 'Alternatif',
              CENTRIFUGAL: 'Centrifuge',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Chargement/Déchargement – Sans huile',
              loadUnload1Gal: 'Chargement/Déchargement (1 gal/CFM)',
              loadUnload3Gal: 'Chargement/Déchargement (3 gal/CFM)',
              loadUnload5Gal: 'Chargement/Déchargement (5 gal/CFM)',
              loadUnload10Gal: 'Chargement/Déchargement (10 gal/CFM)',
              ivmWithBlowdown: "Modulation de la vanne d'admission (sans décharge)",
              ivmWithoutBlowdown: "Modulation de la vanne d'admission (avec décharge)",
              variableDisplacement: 'Déplacement variable',
              vsdWithUnloading: 'Variateur de fréquence avec déchargement',
              vsdWithStopping: 'Variateur de fréquence avec arrêt',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'Contrôle marche/arrêt',
              reciprocatingLoadUnload: 'Chargement/Déchargement',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV avec décharge',
              centrifugalInletIbv: 'IGV avec décharge',
            },
          },
          contentCell: {
            min: '% doit être au moins 0',
            max: '% ne peut pas dépasser 100',
            max150: '% ne peut pas dépasser 150',
            typeError: 'La charge doit être un pourcentage valide',
            setpointMustBeNum: 'Le point de consigne du compresseur doit être un nombre',
            flowMustBeNum: 'Le débit du compresseur doit être un nombre',
            pressureSetpointMustBeNum: 'Le point de consigne de la pression du système doit être un nombre',
            measuredFlowMustBeNum: 'Le débit mesuré du système doit être un nombre',
            dateTime: "Sélectionnez la date et l'heure",
            active: 'ACTIF',
            off: 'ÉTEINT',
            trim: 'Régulation',
            baseLoad: 'Charge de base',
          },
          index: {
            error: 'Échec du chargement des compresseurs. Veuillez réessayer plus tard',
            nameplateError: 'Échec du chargement de la plaque signalétique. Veuillez réessayer plus tard',
            dataSheetError: 'Échec du chargement de la fiche technique. Veuillez réessayer plus tard',
            changeCompSuccess: 'Compresseur mis à jour avec succès',
            changeCompError: 'Erreur lors de la mise à jour du compresseur. Veuillez réessayer.',
            deleteCompSuccess: 'Compresseur supprimé avec succès',
            deleteCompError: 'Erreur lors de la suppression du compresseur. Veuillez réessayer.',
            createCompNameplateSuccess: 'Plaque signalétique du compresseur téléchargée avec succès',
            createCompNameplateError: 'Erreur lors du téléchargement de la plaque signalétique. Veuillez réessayer plus tard.',
            deleteCompNameplateSuccess: 'Plaque signalétique du compresseur supprimée avec succès',
            deleteCompNameplateError: 'Erreur lors de la suppression de la plaque signalétique du compresseur. Veuillez réessayer.',
            createCompDataSheetSuccess: 'Fiche technique du compresseur téléchargée avec succès',
            createCompDataSheetError: 'Erreur lors du téléchargement de la fiche technique. Veuillez réessayer plus tard.',
            deleteCompDataSheetSuccess: 'Fiche technique du compresseur supprimée avec succès',
            deleteCompDataSheetError: 'Erreur lors de la suppression de la fiche technique du compresseur. Veuillez réessayer.',
            changeCompanyCompSuccess: 'Données système mises à jour avec succès',
            changeCompanyCompError: 'Erreur lors de la mise à jour des données système. Veuillez réessayer.',
            compDesignData: 'Données de conception du compresseur',
            comp: 'Compresseurs :',
            addComp: 'Ajouter un compresseur',
            editCompPermission: "Vous avez besoin d'une licence administrateur pour modifier le compresseur. Veuillez contacter",
            selectOption: 'Sélectionnez une option',
            undoChanges: 'Annuler les modifications',
            selectComp: 'Sélectionnez un compresseur ci-dessus.',
            noCompsFound: 'Aucun compresseur trouvé.',
            compRunningState: 'État de fonctionnement du compresseur',
            noCompsFoundAddComp: 'Aucun compresseur trouvé. Ajoutez un compresseur dans la section ci-dessus.',
            title: 'Veuillez confirmer la suppression du compresseur',
            areYouSure: 'Êtes-vous sûr de vouloir',
            compressor: 'compresseur',
            delete: 'supprimer',
          },
        },
        devices: {
          contentCell: {
            device: 'Dispositif',
            usedByMultipleCompanies: 'est utilisé par plusieurs entreprises',
          },
          index: {
            noDevicesToDisplay: "Il n'y a pas de dispositifs à afficher",
            loadingDevicesErrorMessage: "Échec du chargement des dispositifs de l'entreprise. Veuillez réessayer plus tard",
            changeStatusSuccessMessage: 'Dispositif {{isActive}} avec succès',
            changeStatusErrorMessage: 'Erreur lors de {{isActive}} le dispositif. Veuillez réessayer',
            devices: 'dispositifs',
            findDevice: 'Trouver un dispositif',
            placeholder: "Tapez l'ID du dispositif...",
            activatedOn: 'Activé le',
            title: 'Veuillez confirmer le changement de statut du dispositif',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} a été supprimé avec succès',
          deleteCompanyErrorMessage: 'Échec de la suppression de {{companyName}}. Veuillez réessayer plus tard.',
          needAdminLicense:
            "Vous avez besoin d'une licence administrateur pour accéder à cette fonctionnalité. Veuillez contacter support@prosaris.ca",
          deleteChildCompanyFirst:
            "Impossible de supprimer l'entreprise avec des filiales ou des comptes. Veuillez d'abord supprimer toutes les filiales et les comptes.",
          editSettings: 'Modifier les paramètres',
          company: 'Entreprise',
          createASubCompany: 'Créer une filiale pour {{companyName}}',
          deleteCompany: "Supprimer l'entreprise",
          cannotCreateChildCompanyDialog: {
            messageOne:
              "Vous avez atteint le nombre maximal d'entreprises pouvant être créées par vos comptes Société. Veuillez contacter ",
            messageTwo: 'pour plus de détails.',
          },
          deleteCompanyDialog: {
            title: "Veuillez confirmer la suppression de l'entreprise",
            delete: 'Supprimer',
            deleting: 'Suppression',
            permanentlyDeleted: 'le supprimera définitivement de notre base de données.',
            cannotBeUndone: 'CETTE ACTION NE PEUT PAS ÊTRE ANNULÉE.',
          },
        },
        companyDialog: {
          industryIsRequired: "L'industrie est requise",
          createLogoErroerMessage: "Erreur lors du téléchargement de l'image. Veuillez réessayer plus tard.",
          deleteLogoErrorMessage: "Erreur lors de la suppression de l'image. Veuillez réessayer plus tard.",
          createCompanySuccessMessage: 'Entreprise {{name}} créée',
          createCompanyErrorMessage: "Échec de la création de l'entreprise. Veuillez réessayer plus tard.",
          changeCompanySuccessMessage: 'Entreprise {{name}} mise à jour',
          changeCompanyErrorMessage: "Échec de la modification du paramètre de l'entreprise. Veuillez réessayer plus tard.",
          language: 'Langue',
          industry: 'Industrie',
          country: 'Pays',
          currency: 'Monnaie',
        },
        companyLogo: {
          setImageErrorMessage: 'La taille du fichier doit être inférieure à 1 Mo',
          logo: 'Logo',
          add: 'Ajouter',
          removeLogo: 'Supprimer le logo',
          addLogo: 'Ajouter un logo',
        },
        index: {
          loadCompaniesErrorMessage: 'Échec du chargement de la liste des entreprises. Veuillez réessayer plus tard',
          changeCompaniesSectionWidth: 'Changer la largeur de la section des entreprises',
        },
        sidebar: {
          filterCompanies: 'Filtrer les entreprises',
          placeholder: "Entrez le nom de l'entreprise",
          myCompany: 'Mon entreprise',
          childCompanies: 'Filiales',
        },
        routes: {
          index: {
            noFacility: "Vous n'avez aucune installation définie",
            createAFacility: 'Créez une installation pour commencer à planifier des itinéraires',
            goToCreateAFacility: 'Aller créer une installation',
            createAFacilityTooltip: 'Vous devez créer une installation avant de pouvoir commencer à planifier des itinéraires.',
            save: 'Sauvegarder',
            reset: 'Réinitialiser',
            routePlans: "Plans d'itinéraire",
            loading: 'Chargement...',
            noRoutesAdded: 'Aucun itinéraire ajouté.',
            buildARouteTooltip: "Construisez un itinéraire à l'aide du constructeur d'itinéraire.",
            createFloorplanSuccess: "Succès ! Plan d'étage téléchargé !",
            createRouteSuccess: 'Succès ! Itinéraire créé !',
          },
          routeBuilder: {
            title: "Constructeur d'itinéraire",
            missingFields: 'Champs manquants dans le fichier Excel',
            manuallyBuildARoute: "Construire manuellement un nouveau plan d'itinéraire",
            manualInstructions: 'Vous pouvez construire des itinéraires manuellement ou en téléchargeant un fichier Excel.',
            facility: 'Installation',
            route: 'Itinéraire',
            enterRoute: "Entrer l'itinéraire",
            addFloorplan: "Ajouter un plan d'étage",
            floorplanInstructionsTooltip:
              "Ajoutez une image d'un plan d'étage pour vous aider à construire des itinéraires. L'itinéraire sera superposé sur le plan d'étage une fois enregistré.",
            change: 'Modifier',
            location: 'Emplacement',
            enterLocation: "Entrer l'emplacement",
            equipment: 'Équipement',
            enterEquipment: "Entrer l'équipement",
            criticality: 'Criticité',
            enterCriticality: 'Entrer la criticité',
            description: 'Description',
            enterDescription: 'Entrer la description',
            addEquipment: "Ajouter l'équipement",
            addRoute: "Ajouter l'itinéraire",
            downloadTemplate: 'Télécharger le modèle',
          },
          excelReader: {
            loadFromFile: 'Charger depuis un fichier',
            instructionsTooltip:
              'Vous pouvez créer des itinéraires en téléchargeant un fichier Excel où chaque feuille représente un emplacement différent dans une installation. Chaque emplacement peut avoir plusieurs itinéraires.',
          },
          graph: {
            controls: {
              addFloorplan: "Ajouter un plan d'étage",
              toggleLabels: 'Basculer les étiquettes',
              addWaypoint: 'Ajouter un point de passage',
              addEquipment: 'Ajouter un équipement',
              swapEquipment: "Échanger l'équipement",
              showLeakStats: 'Afficher les statistiques des enregistrements',
              save: 'Sauvegarder',
              delete: 'Supprimer',
            },
            routeStats: {
              title: "Statistiques de l'itinéraire",
              totalDollarLossPerYear: 'Perte totale en $ par an :',
              totalKwhLossPerYear: 'Perte totale en kWh par an :',
              totalLeakCount: 'Nombre total d’enregistrements :',
              totalActiveOpen: 'Total ouvert actif :',
              totalActiveClosed: 'Total fermé actif :',
              noStatsAvailable: 'Aucune statistique disponible :',
              close: 'Fermer',
            },
            nodeDetails: {
              edit: 'Éditer',
              name: 'Nom',
              description: 'Description',
              criticality: 'Criticité',
              equipmentInformation: "Informations sur l'équipement",
              thisIsAWaypoint: 'Ceci est un point de passage',
              activeOpenLeakCount: 'Nombre d’enregistrements ouverts actifs :',
              noActiveLeaksDetected: 'Aucune fuite active détectée',
              close: 'Fermer',
              nothingToSeeHere: 'Rien à voir ici',
              seeLeakRecords: 'Voir les enregistrements',
            },
            swapEquipment: {
              title: "Échanger l'équipement",
              selectEquipment: "Sélectionnez l'équipement à échanger :",
              selectFirst: 'Sélectionnez le premier équipement',
              selectSecond: 'Sélectionnez le second équipement',
              swap: 'Échanger',
              cancel: 'Annuler',
            },
            addNode: {
              title: 'Ajouter',
              waypoint: 'Point de passage',
              equipment: 'Équipement',
              afterWhich: 'Après quel équipement ?',
              selectPreceding: "Sélectionnez l'équipement précédent :",
              selectEquipment: "Sélectionner l'équipement",
              name: 'Nom',
              description: 'Description',
              criticality: 'Criticité',
              add: 'Ajouter',
              cancel: 'Annuler',
            },
            save: 'Sauvegarder',
            delete: 'Supprimer',
            successRouteUpdated: 'Itinéraire mis à jour avec succès',
            errorRouteUpdated: "Erreur lors de la mise à jour de l'itinéraire. Veuillez réessayer plus tard.",
            successRouteDeleted: 'Itinéraire supprimé avec succès',
            errorRouteDeleted: "Erreur lors de la suppression de l'itinéraire. Veuillez réessayer plus tard.",
            successFloorplanUpdated: "Plan d'étage mis à jour avec succès",
            errorFloorplanUpdated: "Erreur lors de la mise à jour du plan d'étage. Veuillez réessayer plus tard.",
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Licences administrateur attribuées',
            of: 'de',
            edit: 'Modifier',
          },
          essentialMode: {
            disable: 'désactiver',
            enable: 'activer',
            essential: 'Mode essentiel :',
            enabled: 'ACTIVÉ',
            disabled: 'DÉSACTIVÉ',
            moveCompany: "Déplacer l'entreprise {{company}} en mode essentiel",
            affectedCompanies: "Nombre d'entreprises concernées",
            affectedAccounts: 'Nombre de comptes concernés',
            archivedLeak: "Nombre d'enregistrements d'événements de fuite archivés",
            applyingChanges:
              'En appliquant ces changements, les comptes obtiendront le rôle "Essentiel". Les utilisateurs pourront voir les {{quota}} derniers enregistrements d\'événements de fuite sur un tableau de bord et une application mobile. Les autres enregistrements seront archivés.',
            essentialLabel: 'Modifier le mode essentiel pour les filiales',
            turnOffEssential: "Désactiver le mode essentiel pour l'entreprise {{company}}",
            byApplyingChanges:
              'En appliquant ces changements, tous les comptes obtiendront le rôle "Utilisateur". Les utilisateurs pourront voir tous leurs enregistrements. Tous les enregistrements seront désarchivés.',
          },
          hubspotSync: {
            disable: 'désactiver',
            enable: 'activer',
            hubspot: 'Synchronisation HubSpot',
            enabled: 'ACTIVÉ',
            disabled: 'DÉSACTIVÉ',
            enableHubspotSync: 'Activer la synchronisation HubSpot pour l’entreprise {{company}}',
            disableHubspotSync: 'Désactiver la synchronisation HubSpot pour l’entreprise {{company}}',
            affectedCompanies: "Nombre d'entreprises affectées",
            applyingChanges: 'En appliquant ces modifications, la synchronisation HubSpot sera {{status}}.',
            hubspotLabel: 'Modifier la synchronisation HubSpot pour les sous-entreprises',
          },
          index: 'Paramètres',
        },
        index: {
          loadCompanyError: "Impossible de charger les informations de l'entreprise. Veuillez réessayer plus tard",
          companySlug: "Identifiant de l'entreprise",
          notSelected: 'Non sélectionné',
          country: 'Pays',
          accounts: 'Comptes',
          currency: 'Devise',
          adminLicenses: 'Licences administrateur',
          totalCustomer: "Nombre total de comptes clients dans l'arborescence",
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Enregistrez votre appareil',
          toolTip: "Veuillez localiser le numéro de série sur votre carte d'enregistrement ou sur la boîte.",
          serialNumberIsRequired: 'Le numéro de série est requis',
          oops: 'Oups!',
          deviceNotFound: "Numéro de série de l'appareil non trouvé",
          deviceFoundNdAssociatedCompany: 'Votre appareil a été trouvé et est associé à une entreprise.',
          deviceFound: 'Votre appareil a été trouvé.',
          goToLogin: 'Aller à la connexion',
          contactSupport: 'Veuillez contacter le support',
        },
        deviceErrorCodes: {
          'B13:001': "Numéro de série de l'appareil non trouvé",
          'B13:002': 'Appareil déjà activé',
          'B13:003': 'Email administrateur non envoyé',
          'B13:004': 'Entreprise non trouvée',
          'B13:005': 'Entreprise invalide',
          'B13:006': 'Type invalide',
        },
        registerAccountView: {
          registerAccount: 'Créer un compte',
          termsMustBeAccepted: 'Les termes et conditions doivent être acceptés.',
          saleType012Success: 'Vous pouvez vous connecter pour commencer à utiliser votre appareil.',
          saleType3Success: "Vous avez été ajouté en tant qu'utilisateur de base. Vos administrateurs d'entreprise ont été informés.",
          saleType4Success: {
            companyPlatformFound: "Licence de plateforme d'entreprise trouvée.",
            dialogOne: "Cet appareil est associé à une nouvelle licence de plateforme d'entreprise.",
            dialogTwo: 'Votre entreprise existe peut-être déjà dans notre système.',
            dialogThree: "Si c'est le cas, veuillez contacter votre administrateur.",
          },
          termsAndConditions: {
            dialogOne: "J'accepte les",
            dialogTwo: "Conditions d'Utilisation",
            dialogThree:
              "de Prosaris et je confirme avoir eu suffisamment de temps pour les lire et comprendre qu'elles contiennent des termes importants concernant mon utilisation du logiciel Prosaris, comme la limitation de la responsabilité de Prosaris et la manière dont mes données seront traitées.",
          },
          acceptTerms: 'Accepter les conditions',
          registerAccountErrorCodes: {
            'B02:001': "Code d'enregistrement invalide",
            'B02:002': 'Entreprise utilisateur invalide',
            'B02:003': 'Rôle utilisateur invalide',
            'B02:004': "L'email est déjà utilisé",
            'B02:005': "L'email est déjà utilisé",
            'B02:006': 'Impossible de réinitialiser le mot de passe pour votre compte',
            'B02:007': 'Entreprise utilisateur invalide',
            'B02:008': 'Photo de profil manquante',
            'B02:009': 'Réinitialisation du compte requise. Le processus a été lancé',
            'B02:010': 'Le compte a été désactivé',
            'B02:011': 'Format de photo de profil invalide',
            'B02:012': 'Appareil déjà enregistré à une entreprise',
            'B02:013': 'Numéro de série non défini',
            'B02:014': 'Type invalide',
            'B02:015': 'Lien de réinitialisation du mot de passe expiré',
            'B02:016': 'Impossible de créer un compte pour cette entreprise',
            'B02:017': 'Rôle invalide',
            'B02:018': 'Rôle invalide',
            'B02:019': 'Entreprise utilisateur invalide',
            'B02:020': 'Rôle utilisateur invalide',
            'B02:021': "L'utilisateur n'appartient pas à cette entreprise",
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Les numéros de série sont requis',
          failedToCopy: 'Numéros de série échoués copiés dans le presse-papiers',
          invalidFormat: 'Un ou plusieurs appareils ont un format invalide. Veuillez vérifier et réessayer.',
          devices: 'Enregistrez vos appareils',
          deviceSerialNumber: "Numéro(s) de série de l'appareil",
          note: 'Entrez chaque numéro de série sur une nouvelle ligne ou séparé par une virgule.',
          title: "Veuillez localiser le numéro de série sur votre carte d'enregistrement ou la boîte.",
          register: 'Enregistrer',
          deviceRegistrationStatus: "Statut de l'enregistrement de l'appareil",
          success: 'Succès',
          noDeviceFound: 'Aucun appareil trouvé.',
          failed: 'Échec',
          copyFailed: 'Copier les numéros de série échoués dans le presse-papiers',
        },
      },
      errors: {
        notFoundView: {
          title: "La page que vous recherchez n'est pas ici",
          description:
            "Vous avez peut-être emprunté une route douteuse ou vous êtes arrivé ici par erreur. Quoi qu'il en soit, essayez d'utiliser la navigation.",
        },
        accountNotFound: {
          title: 'Compte introuvable',
          description:
            "Veuillez réessayer ! Si le problème persiste, soit votre compte est actuellement inactif, soit il n'a pas été initialisé par l'administrateur. Veuillez contacter votre administrateur.",
          logout: 'Déconnexion',
        },
      },
      integration: {
        index: {
          loggingInError: 'Erreur lors de la connexion à Prosaris.',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'Aucune image disponible',
          activeActionRecord: {
            updateAssignmentSuccess: "L'action assignée a été mise à jour.",
            updateAssignmentError: "Erreur lors de la mise à jour de l'action assignée.",
            deleteImageSuccess: "L'image assignée a été supprimée.",
            deleteImageError: "Erreur lors de la suppression de l'image de l'action assignée.",
            confirmMarkAction: 'Voulez-vous vraiment marquer cette action comme terminée ?',
            confirmDeleteImage: 'Voulez-vous vraiment supprimer cette image ?',
          },
          index: 'Erreur lors du chargement des actions assignées pour un enregistrement.',
        },
        audio: {
          noAudioAvailable: 'Aucune donnée audio disponible',
          errorDownloadingAudio: "Erreur lors du téléchargement de l'audio. Veuillez réessayer plus tard.",
          playPause: 'Lire / Pause',
          generateRegion: 'Générer une Région',
          removeLastRegion: 'Supprimer la Dernière Région',
          generateMarker: 'Générer un Marqueur',
          removeLastMarker: 'Supprimer le Dernier Marqueur',
          toggleTimeline: 'Basculer la Chronologie',
          download: 'Télécharger',
        },
        audit: {
          updateAuditSuccess: 'Audit mis à jour avec succès',
          updateAuditError: "Erreur lors de l'enregistrement du commentaire. Veuillez réessayer plus tard.",
          createAuditSuccess: 'Audit créé avec succès',
          createAuditError: "Erreur lors de la création de l'audit. Veuillez réessayer plus tard.",
          deleteAuditSuccess: "L'audit a été supprimé avec succès",
          deleteAuditError: "Échec de la suppression de l'audit. Veuillez réessayer plus tard.",
          noComment: 'Aucun commentaire',
          reviewComments: 'Commentaires de révision',
          reviewedBy: 'Examiné par :',
          auditDate: "Date de l'audit :",
          deleteAudit: "Supprimer l'audit",
          reviewedNoComments: 'Examiné sans commentaires',
          auditComments: "Commentaires de l'audit",
          saveComment: 'Enregistrer le commentaire',
          discardChanges: 'Annuler les modifications',
        },
        changeHistory: {
          created: 'créé',
          updated: 'mis à jour',
          deleted: 'supprimé',
          transferred: 'transféré',
          leak: 'Enregistrement',
          images: 'Image',
          audit: 'Audit',
          audio: 'Audio',
          history: 'Historique',
          noHistory: "Pas d'historique",
          bulk: "en tant qu'opération groupée",
          details: 'détails',
          unset: 'non défini',
        },
        fft: {
          fft: 'FFT (Transformée de Fourier rapide)',
          fftAvg: 'Moyenne FFT',
          notApplicable: 'Non applicable',
          chartLayers: 'Couches de graphique',
          avg: 'Moyenne',
          all: 'Tout',
          playing: 'Lecture en cours...',
          showTimeSeries: 'Afficher la série temporelle',
        },
        form: {
          updateLeakSuccess: 'Mis à jour avec succès.',
          updateLeakError: 'Erreur lors de la mise à jour de la fuite. Veuillez réessayer plus tard.',
          alertTitle: {
            partOne: "L'enregistrement est dans",
            partTwo: 'mode lecture seule',
            partThree: '.',
          },
          alertDialog: {
            dialogOne: 'Cependant, les propriétés suivantes peuvent être modifiées :',
            dialogTwo: "Statut de l'action",
          },
          GPSLocation: 'Position GPS',
          completedOn: 'Terminé le :',
          lastSaved: 'Dernière sauvegarde :',
        },
        images: {
          removeSuccess: 'Image supprimée avec succès.',
          removeError: "Impossible de supprimer l'image de l'enregistrement de fuite. Veuillez réessayer plus tard.",
          text: 'Êtes-vous sûr de vouloir supprimer cette image ?',
        },
        index: {
          title: 'Enregistrement',
          loadingError: "Erreur lors du chargement de l'enregistrement de fuite. Veuillez réessayer.",
          unsavedMessage: 'En appuyant sur "Ok", vous confirmez que toutes les modifications non enregistrées seront supprimées',
          notAnAuditor: "Vous n'êtes pas auditeur",
          anEssential: 'Vous êtes sur un plan essentiel',
          showHistory: "Afficher l'historique",
        },
        massFlow: {
          recalculating: 'Recalcul du débit/perte en fonction des paramètres de fuite...',
          warning:
            'Les valeurs d\'entrée ont changé ou le calcul est obsolète, appuyez sur "Recalculer" pour actualiser les valeurs de débit et de perte',
          calculatingError: 'Erreur lors du calcul du débit massique. Veuillez réessayer.',
          noValue: 'Aucune valeur',
          calculatingSuccess: 'Débit/perte recalculé',
          recalculate: 'Recalculer',
          calculatorVersion: 'Version du calcul:',
          noteDialog: {
            dialogOne: 'La modification des paramètres de fuite mettra à jour le calcul du débit/perte. Vérifier',
            dialogTwo: 'Aperçus du calcul',
            dialogThree: "page pour plus d'informations",
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'Enregistrement a été modifié',
          close: 'Fermer',
          unsavedChanges:
            'Cet enregistrement comporte des modifications non enregistrées. Veuillez envisager de les enregistrer ou de les supprimer complètement.',
          discardChanges: 'Annuler les modifications',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: "L'ID de l'appareil est requis",
          dbPeakIsRequired: 'Le DB de pointe mesuré est requis',
          dbMin: 'Le DB de pointe mesuré doit être supérieur ou égal à 0',
          dbMax: 'Le DB de pointe mesuré doit être inférieur ou égal à 95',
          dbError: 'Le DB de pointe mesuré doit être un nombre',
          imageUploadError: "Erreur lors du téléchargement de l'image. Veuillez réessayer plus tard.",
          createLeakSuccess: 'Enregistrement ajouté avec succès',
          createLeakError: "Erreur lors de la création d'un enregistrement. Veuillez réessayer plus tard.",
          basicInfo: 'Informations de base',
          flowInput: 'Entrée de flux',
          mainGas: "Gaz/Composant principal d'intérêt",
          selectComponent: 'Sélectionner le composant',
          selectLeakType: 'Sélectionner le type de fuite',
          mightBeSomethingHere: 'pas encore sûr',
          energyLoss: "Perte d'énergie",
          selectCompressorType: 'Sélectionner le type de compresseur',
          noRoutes: 'Aucune route trouvée',
          noEquipment: 'Aucun équipement trouvé',
          noLocations: 'Aucun emplacement trouvé',
          energyCost: 'Coût énergétique',
          audioRecording: 'Enregistrement Audio',
          leakSourceEquipment: 'Source',
          selectComponentType: 'Sélectionner le type de composant',
          selectService: 'Sélectionner le service',
          locationAndUser: 'Emplacement et utilisateur',
          leakEventStatus: "État de l'événement",
          low: 'Bas',
          medium: 'Moyen',
          high: 'Haut',
          open: 'Ouvrir',
          complete: 'Complet',
          reset: 'Réinitialiser',
        },
        formElectrical: {
          systemVoltages: 'Tensions de système',
          source: 'Source',
        },
        images: {
          leakSourceImageCapture: 'Capturer une image de la source',
          add: 'Ajouter',
        },
        index: {
          title: 'Créer un enregistrement',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'De',
          to: 'À',
          qualifier: 'Qualificateur',
          savedFilters: 'Filtres enregistrés',
          filter: 'Filtre',
          selectFilter: 'Sélectionnez un filtre...',
          filterName: 'Nom du filtre',
          filterNameRequired: 'Le nom du filtre est requis',
          filtersNotAvailable: 'Filtres non disponibles',
          saveFilterTitle: 'Enregistrer le filtre',
          deleteSavedFilterTitle: 'Veuillez confirmer la suppression du filtre',
          deleteSavedFilterMessage: 'Êtes-vous sûr de vouloir supprimer le filtre "{{savedFilterName}}" ?',
          createFilterSuccess:
            "Le filtre '{{filter}}' a été créé avec succès. Vous pouvez y accéder sous Filtres d'enregistrement → Filtres enregistrés",
          createFilterError: 'Erreur lors de la création du filtre. Veuillez réessayer plus tard.',
          deleteFilterSuccess: 'Filtre supprimé avec succès',
          deleteFilterError: 'Erreur lors de la suppression du filtre. Veuillez réessayer plus tard.',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Attribuer le dossier',
          selectAUserToAssign: 'Sélectionnez un utilisateur à assigner aux enregistrements sélectionnés',
          filterByName: 'Filtrer par nom',
          filterByEmployeeName: 'Filtrer par le nom des employés',
          unexpectedError: "Une erreur inattendue s'est produite",
          noUserFound: 'Aucun utilisateur trouvé',
          assigningActionForSelectedRecords: "Attribution d'action pour les enregistrements sélectionnés...",
        },
        modalBulkEdit: {
          titleSingleRecord: 'Modification en masse de {{selectedRecordsCount}} enregistrement',
          title: 'Modification en masse de {{selectedRecordsCount}} enregistrements',
          selectAField: 'Sélectionnez un champ',
          verifyAction:
            'Vous êtes sur le point de mettre à jour des enregistrements appartenant à d’autres utilisateurs. Veuillez confirmer en saisissant le nombre d’enregistrements sélectionnés ci-dessous.',
          matchCountError: 'Doit correspondre au nombre d’enregistrements sélectionnés ({{selectedRecordsCount}})',
          moreThanOneType:
            'Les enregistrements sélectionnés ont plusieurs types. Certains champs peuvent ne pas être disponibles pour la modification en masse.',
        },
        tableHead: {
          youAreSelecting: 'Vous sélectionnez :',
          visibleRecords: 'enregistrements visibles',
          andAllUnloadedRecords: 'et tous les enregistrements non chargés.',
          allRecordsWillBeLoaded: 'Tous les enregistrements seront chargés et sélectionnés.',
          thisCanTakeAWhile: "Cela peut prendre un certain temps si vous avez beaucoup d'enregistrements.",
          allRecords: 'Tous les enregistrements, y compris ceux qui ne sont pas visibles',
          selectAllRecords: 'Sélectionner tous les enregistrements.',
        },
        tableBody: {
          noRecordsToDisplay: "Il n'y a aucun enregistrement à afficher.",
        },
        toolBar: {
          assign: 'Assigner',
          onEssentialPlan: 'Vous êtes sur un plan essentiel. Veuillez contacter support@prosaris.ca',
          bulkEdit: 'Modification en masse',
          bulkEditSuccess: '{{len}} enregistrement mis à jour avec succès',
          bulkEditSuccessPlural: '{{len}} enregistrements mis à jour avec succès',
          bulkEditError: 'Erreur lors de la mise à jour des enregistrements. Veuillez réessayer plus tard',
          exportPDF: 'Exporter en PDF',
          needAdminLicense:
            "Vous avez besoin d'une licence d'administrateur pour accéder à cette fonctionnalité. Veuillez contacter support@prosaris.ca",
          create: 'Créer',
          deleteRecordMessage: "Êtes-vous sûr de vouloir supprimer l'enregistrement sélectionné ?",
          deleteRecordMessagePlural: "Êtes-vous sûr de vouloir supprimer l'enregistrements sélectionnés ?",
          permissionDenied: 'Permission refusée !',
          notAllowedToDelete:
            "Vous avez sélectionné un enregistrement de quelqu'un que vous n'êtes pas autorisé à supprimer. Veuillez le retirer de la sélection.",
          generatingPDF: 'Génération du PDF en cours...',
          cannotGeneratePDF:
            "Malheureusement, nous ne pouvons pas exporter {{numberSelected}} enregistrements dans un document PDF en ce moment. Veuillez sélectionner jusqu'à {{limitRecords}} enregistrements.",
          cannotExportMessage: "Impossible d'exporter {{numberSelected}} enregistrements.",
          bulkEditLimitExceeded: 'Impossible de modifier plus de {{maxAllowed}} enregistrements. Veuillez contacter support@prosaris.ca',
        },
        index: {
          cannotLoadLeakRecord: "Désolé, nous ne pouvons pas charger l'enregistrement de fuite pour le moment",
          successfullyAssignedAllRecords: 'Tous les enregistrements ont été attribués avec succès',
          actionRecordAssigned: "Enregistrement d'action attribué !",
          actionRecordAssignedPlural: "Enregistrements d'action attribués !",
          errorWhileAssigningLeak: "Erreur lors de l'attribution de la fuite. Veuillez réessayer plus tard.",
          successfullyDeletedAllRecords: 'Tous les enregistrements ont été supprimés avec succès',
          successfullyDeletedRecord: '{{length}} enregistrement de fuite supprimé avec succès',
          successfullyDeletedRecordPlural: '{{length}} enregistrements de fuite supprimés avec succès',
          errorOnRecordsDelete: 'Erreur lors de la suppression des enregistrements. Veuillez réessayer plus tard',
          leakPreview: 'Aperçu',
          leakRecord: 'Enregistrement: ',
        },
      },
      tagList: {
        resetTag: "Réinitialiser l'étiquette",
        dataExport: {
          smartLeakTagsFormat: 'Format des Smart Tags',
          smartLeakTagsDescription: 'Contient les champs par défaut présents sur la page Smart Tags',
          standardLeakRecordFormat: "Format de l'enregistrement standard",
          standardLeakRecordDescription: 'Contient tous les champs liés aux enregistrements de fuite',
        },
        filters: { smartLeakTagFilters: 'Filtres des Smart Tags' },
        index: {
          cannotLoadTags: 'Désolé, nous ne pouvons pas charger les Smart Tags pour le moment',
          updateLeakSuccess: 'Réinitialisation des Smart Tags réussie.',
          updateLeakError: 'Erreur lors de la réinitialisation des Smart Tags. Veuillez réessayer plus tard.',
          bulkEditSuccess: 'Réinitialisé avec succès {{len}} Smart Tag',
          bulkEditSuccessPlural: 'Réinitialisé avec succès {{len}} Smart Tags',
          bulkEditError: 'Erreur lors de la réinitialisation des Smart Tags. Veuillez réessayer plus tard.',
          tagsFileName: 'Smart Tags',
          leakFileName: 'Enregistrements',
        },

        tableBody: {
          title: "Veuillez confirmer la réinitialisation de l'étiquette",
          alertDialog: {
            dialogOne: 'Vous essayez de',
            dialogTwo: "réinitialiser l'étiquette",
            dialogThree: "à partir d'un enregistrement de fuite avec",
            dialogFour: "Statut de l'action: OUVERT",
            dialogFive: 'Voulez-vous continuer?',
          },
        },
        toolBar: {
          resetTags: 'Réinitialiser les étiquettes',
          title: 'Veuillez confirmer la réinitialisation en vrac des étiquettes',
          alertDialog: {
            dialogOne: 'Votre sélection contient',
            dialogTwo: 'enregistrement avec',
            dialogTwoPlural: 'enregistrements avec',
            dialogThree: "Statut de l'action: OUVERT",
            dialogFour: 'Voulez-vous continuer?',
          },
          exportFormatCSV: "Sélectionnez un format d'exportation pour CSV",
          exportFormatPDF: "Sélectionnez un format d'exportation pour PDF",
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Erreur lors du chargement des actions assignées. Veuillez réessayer.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Graphiques disponibles',
            selectAChart: 'Sélectionnez un graphique...',
            chartFilters: 'Filtres de graphique',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Erreur lors du chargement des enregistrements de fuite. Veuillez réessayer.',
              errorMessageTwo: 'Veuillez sélectionner une date antérieure à la date de fin.',
              errorMessageThree: 'Veuillez sélectionner une période inférieure à 6 mois.',
              errorMessageFour: 'Veuillez sélectionner une date postérieure à la date de début.',
            },
            charts: 'Graphiques',
            chartAlreadyExists: 'Ce graphique existe déjà !',
          },
          filters: {
            select: 'Sélectionner...',
            startDate: 'Date de début',
            endDate: 'Date de fin',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Débit par catégorie de fuite (${unit})`,
              LOSS_BY_LEAK: (currency) => `Perte par catégorie de fuite (${currency})`,
              TAGS_BY_LEAK: 'Étiquettes par catégorie de fuite',
              AVG_FLOW_BY_LEAK: (unit) => `Débit moyen par catégorie de fuite (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Opportunité de perte et d'économies par temps de réparation (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Débit par temps de réparation (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Perte par temps de réparation (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Progression du débit – Réparé vs Non réparé (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Progression des pertes – Réparé vs Non réparé (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Débit par équipement/composant (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `Perte par équipement/composant (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Étiquettes par équipement/composant',
            },
            datasetLabel: {
              totalLeakFlow: 'Débit total de fuite trouvé en',
              totalLoss: 'Total de la perte en',
              totalRecords: 'Total des enregistrements trouvés en',
              avgLeakFlow: 'Débit moyen de fuite trouvé en',
              totalLossIn: 'Perte totale en',
              flowByRepairTime: 'Débit par temps de réparation en',
              lossByRepairTime: 'Perte par temps de réparation en',
              flowByRepairProgress: 'Débit trouvé par progression des réparations en',
              recordsByCompletion: 'Enregistrements par achèvement en',
              flowByEquipment: 'Débit trouvé par équipement en',
              totalLossByEquipment: 'Perte totale par équipement en',
              totalRecordsByEquipment: 'Total des enregistrements par équipement en',
            },
            labels: {
              low: 'Bas',
              medium: 'Moyen',
              high: 'Haut',
              equipment: 'Équipement',
              savings: 'Économies',
              opportunity: 'Opportunité',
              loss: 'Perte',
              repaired: 'Réparé',
              unrepaired: 'Non réparé',
              LESS_15_DAYS: 'Moins de 15 jours',
              DAYS_16_TO_30: '15 à 30 jours',
              DAYS_31_TO_60: '31 à 60 jours',
              DAYS_61_TO_90: 'Plus de 60 jours',
            },
          },
        },
        index: {
          assignedActions: 'Actions qui vous sont assignées',
        },
      },
      export: {
        imageUrlOne: "URL de l'image du fichier",
        imageUrlTwo: "URL de l'image du fichier",
        leakTag: 'Tag intelligent/Référence de travail.',
        per: 'par ',
      },
    },
    languages: {
      English: 'Anglais',
      Chinese: 'Chinois',
      Spanish: 'Espagnol',
      French: 'Français',
      German: 'Allemand',
      Norwegian: 'Norvégien',
      Italian: 'Italien',
      Turkish: 'Turc',
      Japanese: 'Japonais',
    },
  },
};
