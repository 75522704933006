export default {
  translation: {
    commonUsedWords: {
      login: 'ログイン',
      email: 'メールアドレス',
      password: 'パスワード',
      emailIsRequired: 'メールアドレスは必須です',
      mustBeAValidEmail: '有効なメールアドレスである必要があります！',
      deviceSerialNumber: 'デバイスシリアル番号',
      next: '次へ',
      success: '成功',
      submit: '送信',
      firstName: '名',
      firstNameIsRequired: '名は必須です',
      lastName: '姓',
      lastNameIsRequired: '姓は必須です',
      save: '保存',
      undoChanges: '変更を元に戻す',
      cancel: 'キャンセル',
      loading: '読み込み中...',
      yes: 'はい',
      no: 'いいえ',
      clear: 'クリア',
      confirm: '確認',
      noOption: 'オプションなし',
      openText: '開く',
      edit: '編集',
      logout: 'ログアウト',
      update: '更新',
      locked: 'ロック',
      unlocked: 'ロック解除',
      latitude: '緯度 (LAT)',
      longitude: '経度 (LONG)',
      ok: 'OK',
      okay: 'オーケー',
      done: '完了',
      comingSoon: '近日公開...',
      reset: 'リセット',
      attention: '注意',
      note: '備考',
    },
    api: {
      auth: {
        fieldsAreMissing: '必須フィールドが不足しています',
        userNotFound: 'ユーザーが見つかりません',
        missingRefreshToken: 'リフレッシュトークンがありません',
        cannotRefreshToken: 'リフレッシュトークンを更新できません',
      },
      helpers: {
        error: 'エラー',
        invalidHTTPMethod: '無効なHTTPメソッド',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'メールアドレスを確認',
        confirmEmailIsRequired: '確認されたメールが必要です',
        emailNotMatched: 'メールアドレスが一致しません',
      },
      confirmPassword: {
        confirmPassword: 'パスワードを確認',
        confirmPasswordIsRequired: '確認されたパスワードが必要です',
        newPasswordIsRequired: '新しいパスワードが必要です',
        showPassword: 'パスワードを表示',
        passwordLength: 'パスワードは6文字以上である必要があります',
        passwordContainSpaces: 'パスワードにスペースを含めることはできません',
        passwordNotMatch: 'パスワードが一致しません',
        newPassword: '新しいパスワード',
      },
      confirmModal: {
        areYouSure: '本当によろしいですか？',
      },
      appliedFilters: {
        between: '{{firstValue}} と {{secondValue}} の間',
        bottom: '下 {{primaryValue}}%',
        top: '上 {{primaryValue}}%',
        activeFilters: 'アクティブフィルター',
        active: 'アクティブ',
        closed: 'クローズ',
      },
      editTable: {
        editTable: 'テーブルを編集',
        selectDeselect: 'テーブルの値を選択/選択解除',
        resetToDefault: 'デフォルトにリセット',
      },
      errorBoundary: {
        oops: 'おっと！予期しないエラーが発生しました。',
        tryAgain: 'もう一度試してください！問題が続く場合は、管理者にお問い合わせください。',
        refresh: 'リフレッシュ',
      },
      noResultFound: {
        noResultFoundTryAgain: '申し訳ありません、結果が見つかりませんでした。もう一度試してください。',
      },
      permissionGuard: {
        invalidPermission: '無効な権限構造、配列には3つの要素が必要です',
      },
      preview: {
        findInView: 'ビューで検索',
        showDetails: '詳細を表示',
        closeEsc: '閉じる (Esc)',
        prevLeftArrow: '前 (左矢印)',
        nextRightArrow: '次 (右矢印)',
        proceedWithoutSaving: '保存せずに進む',
        continueEditing: '編集を続ける',
        unsavedChanges: {
          partTwo: '変更内容が ',
          partThree: '保存されていません',
          partFour: '。保存せずに進むと、すべての変更が破棄されます。よろしいですか？',
        },
      },
      printRecord: {
        title: '漏洩イベント記録',
        date: '日付:',
        location: '場所',
        leakDetails: '漏洩の詳細',
        leakParameters: '漏洩パラメータ',
        energyLoss: 'エネルギー損失',
        leakSourceEquipment: '漏洩源機器',
        notes: 'メモ',
        leakEventStatus: '漏洩イベントの状態',
        requiredAction: '必要なアクション',
        leakSourceImage: '漏洩源画像',
        smartLeakTag: 'スマート漏洩タグ',
        lossPerYear: '損失 ({{currency}}/年)',
        costOfEnergy: 'エネルギーコスト ({{currency}}/kWh)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'アサインメントが削除されました',
          deleteAssignmentError: 'アサインメントは削除できませんでした。後で再試行してください',
          currentlyAssigned: '現在割り当てられているアクション',
          repairedBy: '修理者',
          text: 'このアサインメントを削除しようとしています。この操作は元に戻せません',
        },
        actionSaveDate: '最終保存日時',
      },
      visibleColumnsPopper: {
        tableValues: 'テーブルの値を選択/選択解除',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: '等しい',
        LESS_THAN: '未満',
        GREATER_THAN: 'より大きい',
        LESS_THAN_OR_EQUAL_TO: '以下',
        GREATER_THAN_OR_EQUAL_TO: '以上',
        BETWEEN_A_AND_Y: 'の間',
        TOP_PERCENT: '上位 "x"%',
        BOTTOM_PERCENT: '下位 "x"%',
      },
      recordFields: {
        CREATED_AT: '作成日時',
        RECORD_ID: '記録ID番号',
        DOCUMENT_ID: '記録ID番号',
        DOLLARS_PER_YEAR: '損失',
        GAS_COMPONENT: 'ガス/成分',
        LEAK_TYPE: '漏洩タイププロファイル',
        DATE_TIME: '日付/時間',
        DB_PEAK: 'ピークdB',
        COMPANY: '会社',
        NOTES: 'メモ',
        USER: 'ユーザー',
        EQUIPMENT_TAG: '機器/部品タグ',
        FLOW: '流量 (m³/h)',
        EQUIPMENT: 'ユニット/機器',
        ROUTE: 'ルート',
        ROUTE_LOCATION: 'ルートの場所',
        PROJECT_SURVEY: '監査/調査記録参照',
        OUTAGE_STATUS: '停止/シャットダウンが必要？',
        FACILITY: '施設/サイト',
        ACTION_STATUS: 'アクションステータス',
        SERVICE_TYPE: 'サービス/種類',
        DB_ACTIVE: 'アクティブdB',
        REFERENCE_ID: 'プロジェクト番号/調査ID',
        AMBIENT_TEMPERATURE: '周囲温度 (°C)',
        LEAK_PRESSURE: '圧力 (kPag)',
        LEAK_TEMPERATURE: 'ガス温度 (°C)',
        LEAK_CATEGORY: '漏洩カテゴリー',
        DISTANCE: '距離 (m)',
        GAS_COST: 'ガスのコスト ($/m³)',
        COMPRESSOR_FLOW: 'm³/h あたりBHP',
        DEVICE_ID: 'デバイスID',
        COMPRESSOR_BHP: 'コンプレッサーBHP',
        COMPRESSOR_TYPE: 'コンプレッサータイプ',
        MOTOR_EFFICIENCY: 'モーター効率 (%)',
        ENERGY_COST: 'エネルギーコスト（$/kWh)',
        HOURS: '年間稼働時間',
        LOCATION: '漏洩場所',
        LAST_MODIFIED: '最終保存日時',
        POWER_LOAD: '% フルロード電力',
        CLIENT: 'クライアント',
        SERVICE: 'サービス',
        CAPACITY: '% 容量',
        EQUIPMENT_TYPE: '機器/部品の種類',
        LEAK_TAG: '漏洩タグ番号/作業参照番号',
        REPAIRED_BY: '修理者',
        ALTITUDE: '高度 (m)',
        REQUIRED_ACTION: '必要なアクション',
        FREQUENCY_LOW_BOUND: '周波数 (低)',
        FREQUENCY_HIGH_BOUND: '周波数 (高)',
        SENSITIVITY: '感度',
        KWH_PER_YEAR: '年間kWh',
        ASSIGNED_BY: '割り当て者',
        ASSIGNED_TO: '割り当て先',
        ASSIGNED_DATE: '割り当て日',
        LOSS_PER_YEAR: '年間損失',
        ASSIGNED: '割り当て済み',
        CREATOR_USER_ID: 'ユーザーID',
        CURRENCY: '通貨',
        COMPLETED_ON: '完了日時',
        IMAGES: '漏洩源画像',
        ACTION_STATUS_MODIFIED: 'アクションステータス修正済み',
      },
      electricalRecordFields: {
        REGION: '地域',
        LOCATION_TYPE: 'ロケーションタイプ',
        VOLTAGE: '電圧',
        PHASE: '位相',
        FREQUENCY: '周波数',
        ELECTRICAL_EQUIPMENT_TAG: '機器/コンポーネント',
        ELECTRICAL_EQUIPMENT_TYPE: '機器/コンポーネントタイプ',
        FAULT_TYPE: '故障タイプ',
        ELECTRICAL_NOTES: '備考',
      },
      IMPERIAL_LABELS: {
        FLOW: '流量 (cfm)',
        AMBIENT_TEMPERATURE: '周囲温度 (°F)',
        LEAK_PRESSURE: '圧力 (psi)',
        LEAK_TEMPERATURE: 'ガス温度 (°F)',
        DISTANCE: '距離 (ft)',
        GAS_COST: 'ガスのコスト ($/ft³)',
        COMPRESSOR_FLOW: 'cfm あたりBHP',
        ALTITUDE: '高度 (ft)',
      },
      userFields: {
        EMAIL: 'メールアドレス',
        ROLE: '役割',
        DEVICE_ID: 'デバイスID',
        SUBSCRIPTION_RENEWAL_DATE: 'サブスクリプション更新日',
        DATE_TIME: '作成日',
        ACTIVE: 'ステータス',
        SOFTWARE_VERSION: 'ソフトウェアバージョン',
        FIRMWARE_VERSION: 'ファームウェアバージョン',
        FIRST_NAME: '名',
        LAST_NAME: '姓',
        COMPANY_SLUG: '会社',
        COMPANY_NAME: '会社',
      },
      auditFields: {
        documentId: '記録ID番号',
        companyName: '会社名',
        auditorName: '監査員名',
        auditorEmail: '監査員メールアドレス',
        createdAt: '作成日時',
        updatedAt: '更新日時',
        comment: 'コメント',
        isLocked: '監査ロック',
      },
      deviceFields: {
        DEVICE_ID: 'デバイスID',
        ACTIVE: 'ステータス',
        REGISTERED_ON: 'アクティブ化日',
        EVENT_COUNT: '記録数',
        UNIQUE_COMPANIES: '会社数',
        LAST_USED_AT: '最終使用日時',
        LAST_USED_BY_NAME: '最終使用者名',
        LAST_USED_BY_EMAIL: '最終使用者メール',
        LOCATION: '最終使用場所',
        FIRMWARE_VERSION: 'ファームウェアバージョン',
      },
      statsFields: {
        labels: {
          TIMELINE: 'タイムライン',
          CRITERIA: '基準',
        },
        timeline: {
          LAST_3_MONTHS: '過去3ヶ月',
          LAST_6_MONTHS: '過去6ヶ月',
          LAST_12_MONTHS: '過去12ヶ月',
          YEAR_TO_DATE: '今年累計',
          ALL_TIME: '全期間',
        },
        criteria: {
          LOSS: '損失',
          FLOW: '流量',
          KWH: 'kWh',
        },
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: '発見された漏洩の数 (#)',
          NO_OF_LEAKS_REPAIRED: '修理された漏洩の数 (#)',
          PERCENT_LEAKS_REPAIRED: '修理された漏洩の割合 (%)',
          VOL_FLOW_FOUND: '検出された体積流量 (m³/h)',
          VOL_FLOW_REPAIRED: '修理された体積流量 (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '修理された体積流量の割合 (%)',
          VALUE_FOUND: '発見された値',
          VALUE_REPAIRED: '修理された値',
          ENERGY_SAVING_OPPORTUNITY_FOUND: '省エネの機会 (発見) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: '省エネの機会の割合 % (発見 v 計算または測定)',
          ENERGY_SAVING_REALIZED_FIXED: '実現された省エネ (修正済み) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: '実現された省エネの割合 % (修正済み v 計算または測定)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'システム制御圧力の低下 (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: '検出された体積流量 (cfm)',
          VOL_FLOW_REPAIRED: '修理された体積流量 (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: '省エネの機会 (発見) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: '実現された省エネ (修正済み) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'システム制御圧力の低下 (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'パフォーマンス指標',
          ENPI_VALUE: 'EnPI値',
          ENB_VALUE: 'EnB値',
          SUCCESS_THRESHOLD: '成功閾値 (%)',
        },
      },
      assignedAction: {
        assignedTo: '割り当て先',
        assignedBy: '割り当て者',
        assignedDate: '割り当て日',
        requestReassign: '再割り当てをリクエスト',
        actionStatus: 'アクションステータス',
        partsNeeded: '必要な部品',
        productionOutage: 'シャットダウンのリクエスト',
        timeToRepair: '修理時間',
        repairCost: '修理費用',
        closeOutRef: 'クローズアウト参照',
        'assignmentRecord.documentId': '記録ID',
        assignmentId: '割り当てID',
        owner: '所有者',
        companyName: '会社',
        assignmentActive: '割り当てステータス',
        'assignmentRecord.leakStatus.building': 'ユニット/機器',
        'assignmentRecord.leakStatus.facility': '施設',
        'assignmentRecord.leakStatus.routeName': 'ルート',
        'assignmentRecord.leakStatus.leakTag': '漏洩タグ番号/作業参照番号',
        'assignmentRecord.currency': '通貨',
      },
      AAR_FIELD_LABEL: {
        NOTES: '実行されたアクション',
        CLOSE_OUT_REF: 'アクションクローズアウト参照番号',
        PARTS_NEEDED: '新しい部品/コンポーネントが必要',
        PRODUCTION_OUTAGE: '生産中断',
        REPAIR_COST: '費用 ($)',
        REQUEST_REASSIGN: '再割り当てをリクエスト',
        ACTION_STATUS: 'アクションステータス',
        TIME_TO_REPAIR: '修理にかかった時間 (分)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: '漏洩カテゴリ別の流量',
          LOSS_BY_LEAK: '漏洩カテゴリ別の損失',
          TAGS_BY_LEAK: '漏洩カテゴリ別のタグ',
          AVG_FLOW_BY_LEAK: '漏洩カテゴリ別の平均流量',
          SAVING_OPT_BY_REPAIR: '修理時間別の機会と節約',
          FLOW_REPAIR_TIME: '修理時間別の流量',
          LOSS_REPAIR_TIME: '修理時間別の損失',
          FLOW_REPAIR_PROG: '修理進捗別の流量',
          TOTAL_REPAIR_PROG: '修理進捗別の損失',
          FLOW_BY_EQUIP_COMP: '機器/部品別の流量',
          LOSS_BY_EQUIP_COMP: '機器/部品別の損失',
          TAGS_BY_EQUIP_COMP: '機器/部品別のタグ',
        },
        leakCategoryLabels: {
          LOW: '低',
          MEDIUM: '中',
          HIGH: '高',
        },
        actionStatus: {
          OPEN: 'オープン',
          COMPLETE: '完了',
        },
        equipmentTypes: {
          CONNECTOR: 'コネクタ',
          CONTROL_VALVE: '制御バルブ',
          HOSE: 'ホース',
          TUBE_FITTING: 'チューブ継手',
          METER: 'メーター',
          OPEN_ENDED_LINE: '開放端ライン',
          PRESSURE_RELIEF_VALVE: '圧力解放バルブ',
          PUMP_SEAL: 'ポンプシール',
          REGULATOR: 'レギュレーター',
          VALVE: 'バルブ',
          LEVEL_CONTROLLER: 'レベルコントローラー',
          POSITIONER: 'ポジショナー',
          PRESSURE_CONTROLLER: '圧力コントローラー',
          TRANSDUCER: 'トランスデューサー',
          GENERIC_PNEUMATIC_INSTRUMENT: '汎用空圧計器',
          GENERIC_RECIPROCATING_COMPRESSOR: '汎用往復圧縮機',
          GENERIC_CENTRIFUGAL_COMPRESSOR: '汎用遠心圧縮機',
        },
        mainGasValues: {
          AIR: '空気',
          METHANE: 'メタン (CH4)',
          CARBON_DIOXIDE: '二酸化炭素 (CO2)',
          HYDROGEN: '水素 (H2)',
          HELIUM: 'ヘリウム (He)',
          NITROGEN: '窒素 (N2)',
          REFRIGERANT: '冷媒 (HFCs)',
          ARGON: 'アルゴン (Ar)',
          OXYGEN: '酸素 (O)',
          WATER: '水 (蒸気)',
        },
        leakTypeValues: {
          DEFAULT: '選択されていません',
          HOLE: '穴',
          CRACK: 'ひび割れ',
          FITTING: '継手',
          FLANGE: 'フランジ',
          'GLAND/SEAL': 'グランド/シール',
          THREAD: 'ねじ',
          'TUBE/HOSE': 'チューブ/ホース',
        },
        serviceValues: {
          COMPRESSED_AIR: '圧縮空気',
          VACUUM: '真空',
          STEAM: '蒸気',
          PROCESS_GAS: 'プロセスガス',
          LIQUID: '液体',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'ロータリースクリュー',
          RECIPROCATING: '往復動',
          SCROLL: 'スクロール',
          CENTRIFUGAL: '遠心',
        },
        labels: {
          LOW: '低',
          MEDIUM: '中',
          HIGH: '高',
          EQUIPMENT: '機器',
          SAVINGS: '節約',
          OPPORTUNITY: '機会',
          LOSS: '損失',
          REPAIRED: '修理済み',
          UNREPAIRED: '未修理',
          LESS_15_DAYS: '15日未満',
          DAYS_16_TO_30: '16〜30日',
          DAYS_31_TO_60: '31〜60日',
          DAYS_61_TO_90: '60日以上',
          LESS_THAN_15_DAYS: '修理済み < 15日',
          '16_TO_30_DAYS': '修理済み 16〜30日',
          '31_TO_60_DAYS': '修理済み 31〜60日',
          MORE_THAN_60_DAYS: '修理済み 61〜90日',
          AIR: '空気',
          METHANE: 'メタン (CH4)',
          CARBON_DIOXIDE: '二酸化炭素 (CO2)',
          HYDROGEN: '水素 (H2)',
          HELIUM: 'ヘリウム (He)',
          NITROGEN: '窒素 (N2)',
          REFRIGERANT: '冷媒 (HFCs)',
          ARGON: 'アルゴン (Ar)',
          OXYGEN: '酸素 (O)',
          WATER: '水 (蒸気)',
          DEFAULT: '選択されていません',
          HOLE: '穴',
          CRACK: 'ひび割れ',
          FITTING: '継手',
          FLANGE: 'フランジ',
          'GLAND/SEAL': 'グランド/シール',
          THREAD: 'ねじ',
          'TUBE/HOSE': 'チューブ/ホース',
          COMPRESSED_AIR: '圧縮空気',
          VACUUM: '真空',
          STEAM: '蒸気',
          PROCESS_GAS: 'プロセスガス',
          LIQUID: '液体',
          ROTARY_SCREW: 'ロータリースクリュー',
          RECIPROCATING: '往復動',
          SCROLL: 'スクロール',
          CENTRIFUGAL: '遠心',
          CONNECTOR: 'コネクタ',
          CONTROL_VALVE: '制御バルブ',
          HOSE: 'ホース',
          TUBE_FITTING: 'チューブ継手',
          METER: 'メーター',
          OPEN_ENDED_LINE: '開放端ライン',
          PRESSURE_RELIEF_VALVE: '圧力解放バルブ',
          PUMP_SEAL: 'ポンプシール',
          REGULATOR: 'レギュレーター',
          VALVE: 'バルブ',
          LEVEL_CONTROLLER: 'レベルコントローラー',
          POSITIONER: 'ポジショナー',
          PRESSURE_CONTROLLER: '圧力コントローラー',
          TRANSDUCER: 'トランスデューサー',
          GENERIC_PNEUMATIC_INSTRUMENT: '汎用空圧計器',
          GENERIC_RECIPROCATING_COMPRESSOR: '汎用往復圧縮機',
          GENERIC_CENTRIFUGAL_COMPRESSOR: '汎用遠心圧縮機',
        },
        leakRecordPDF: '漏洩イベント記録 • 出力日時 {{date}}',
        leakTag: 'スマート漏洩タグ • 出力日時 {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'コンプレッサータグ',
          COMPRESSOR_MAKE: 'コンプレッサーメーカー',
          COMPRESSOR_MODEL: 'コンプレッサーモデル',
          COMPRESSOR_TYPE: 'コンプレッサータイプ',
          NAMEPLATE_HP: '定格馬力 (HP)',
          NAMEPLATE_CAPACITY: '定格容量 (m³/h)',
          MOTOR_EFFICIENCY: 'モーター効率 (%)',
          MOTOR_VOLTAGE: 'モーター電圧',
          FULL_LOAD_CURRENT: 'フルロード電流 (アンペア)',
          FULL_LOAD_INPUT_POWER: 'フルロード入力電力 (kW)',
          RATED_PRESSURE: '定格圧力 (kPag)',
          PRESSURE_SETPOINT: '圧力設定値 (kPag)',
          CONTROL: '制御',
          TOTAL_AIR_STORAGE_SIZE: '総空気貯蔵容量 (リットル)',
          IS_ACTIVE: 'コンプレッサーのアクティブ/オフ',
          OPERATION_TYPE: 'トリム / ベースロード',
          PERCENT_LOAD: '% 負荷',
          COMPRESSOR_SETPOINT_SEU: 'コンプレッサー設定値 (kPag)',
          COMPRESSOR_FLOW_SEU: 'コンプレッサー流量 (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'システム圧力設定値 (kPag)',
          SYSTEM_MEASURED_FLOW: 'システム測定流量 (m³/h)',
          DATE_TIME_RUNNING_STATE: '動作状態の日付と時間',
          NOTES: 'メモ',
          NAMEPLATE: 'コンプレッサー名板',
          DATASHEET: 'コンプレッサーデータシート',
          BHP: 'ブレーキ馬力 (BHP)',
          OPERATING_HOURS: '稼働時間',
          LOADED_PERCENT_TIME_RUNNING: '稼働時間の割合 (%)',
          LOADED_PERCENT_FULL_LOAD_BHP: 'フルロードBHPの割合 (%)',
          LOADED_MOTOR_EFFICIENCY: 'モーター効率 (%)',
          UNLOADED_PERCENT_TIME_RUNNING: '非稼働時間の割合 (%)',
          UNLOADED_PERCENT_FULL_LOAD_BHP: 'フルロードBHPの割合 (非稼働時)',
          UNLOADED_MOTOR_EFFICIENCY: 'モーター効率 (非稼働時) (%)',
          ENERGY_COST: '総電力料金 ($)',
          ANNUAL_ENERGY_COST: '年間エネルギーコスト ($)',
          ANNUAL_ENERGY_KWH: '年間エネルギー消費量 (kWh)',
          COST_FULLY_LOADED: 'フルロード時のコスト ($)',
          COST_UNLOADED: '非稼働時のコスト ($)',
          KWH_PER_YEAR_LOADED: '年間消費エネルギー (kWh, 稼働時)',
          KWH_PER_YEAR_UNLOADED: '年間消費エネルギー (kWh, 非稼働時)',
          ENERGY_COST_PER_UNIT: '単位当たりエネルギーコスト ($/m³)',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: '定格容量 (cfm)',
          FULL_LOAD_INPUT_POWER: 'フルロード入力電力 (BTU/h)',
          RATED_PRESSURE: '定格圧力 (psi)',
          PRESSURE_SETPOINT: '圧力設定値 (psi)',
          TOTAL_AIR_STORAGE_SIZE: '総空気貯蔵容量 (gal)',
          COMPRESSOR_SETPOINT_SEU: 'コンプレッサー設定値 (psi)',
          COMPRESSOR_FLOW_SEU: 'コンプレッサー流量 (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'システム圧力設定値 (psi)',
          SYSTEM_MEASURED_FLOW: 'システム測定流量 (cfm)',
          ENERGY_COST_PER_UNIT: '単位当たりエネルギーコスト ($/cf)',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: '電力供給会社',
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: '単位',
          REBATE_COST: '$/kWh',
          REBATE_UNIT: '単位',
          ENERGY_SOURCES: 'エネルギー源',
          FE_HOURS_OPERATION: '稼働時間',
          FE_ENERGY_COST: 'エネルギーコスト ($/kWh)',
          FE_EFFICIENCY: '効率 (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: '効率 (kW/100cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'リクエストの検証エラーです。フォームフィールドを確認してください',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'デバイスを登録',
          help: 'ヘルプ',
          metric: 'メートリック',
          imperial: 'インペリアル',
          downloadApk: 'APKをダウンロード',
          downloadOta: 'ファイルをダウンロード',
        },
        otaDialog: {
          title: 'ファイルをダウンロード',
          downloadButton: 'ダウンロード',
          serialNumberLabel: 'デバイスシリアル番号',
          serialNumberRequired: 'シリアル番号は必須です',
          fileDownloaded: 'ファイル {{fileName}} が正常にダウンロードされました',
          errorFetchingFile: 'ファイル取得エラーです。後でもう一度お試しください',
          selectSerialNumber: 'デバイスのシリアル番号を選択',
        },
        banners: {
          leakRecordsList: {
            dialogOne: '現在、Essentialプランをご利用中です。',
            dialogTwo: 'クリック ',
            dialogThree: 'こちら',
            dialogFour: '新しい管理者ライセンスを購入するか、次のアドレスにお問い合わせください: ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable:
            '技術的な問題が発生しています。サービスに影響が出ている可能性があります。ご不便をおかけして申し訳ありません。',
          downForMaintenance: '現在、メンテナンス中です。後でもう一度お試しください。',
          tagsManagement: {
            dialogOne: '追加購入',
            dialogTwo: 'PROSARISスマート漏洩タグ',
            dialogThree: 'こちら！',
          },
        },
        mobileNav: {
          index: {
            menu: 'メニュー',
            editProfile: 'プロフィールを編集',
            logout: 'ログアウト',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'すべてクリア',
            readAll: 'すべて読む',
            noNotifications: '通知なし',
          },
        },
        index: {
          dashboard: 'ダッシュボード',
          records: '記録',
          actions: 'アクション',
          company: '会社',
          tags: 'タグ',
        },
        loggedInAs: 'としてログイン中',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: 'フィールド {{field}} でラベルを判別できません',
        cannontDeterminByLabel: 'ラベル {{label}} でフィールドを判別できません',
        active: 'アクティブ',
        closed: 'クローズ',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'ダッシュボード',
        records: '記録',
        actions: 'アクション',
        company: '会社',
        tags: 'タグ',
        redirecting: 'リダイレクト中...',
      },
      account: {
        index: {
          updatePassword: 'パスワードが正常に更新されました。',
          accountDeletedSuccess: 'アカウント {{user}} が正常に削除されました',
          accountDeletedError: '現在アカウントを削除できません。後でもう一度お試しください。',
          authenticationError: 'ユーザー認証に失敗しました',
          personalDetails: '個人情報',
          currentPasswordIsRequired: '現在のパスワードが必要です',
          username: 'ユーザー名',
          changePassword: 'パスワードを変更',
          currentPassword: '現在のパスワード',
          profilePicture: 'プロフィール写真',
          dangerZone: '危険ゾーン',
          integrations: '統合',
          sosShipments: 'SOSインベントリ',
          sosSyncMessage:
            'このプロセスはProsarisでSOS注文処理をトリガーします。SOSインベントリの最新データを使用します。このプロセスは通常、4時間ごとにスケジュールされたタスクで自動的に管理されますが、このボタンをクリックすると手動でプロセスを開始できます。',
          sosSyncSuccess: 'SOS同期が正常にトリガーされました',
          triggerSosSync: 'SOS同期をトリガー',
          hubspot: 'HubSpot',
          hubspotSyncMessage:
            'このプロセスはプラットフォームの最新データをHubSpotに即座にプッシュします。この同期は通常、1時間ごとにスケジュールされたタスクで自動的に管理されますが、このボタンをクリックすると手動で同期をトリガーできます。',
          hubspotSyncSuccess: 'Hubspot同期が正常にトリガーされました',
          triggerHubspotSync: 'Hubspot同期をトリガー',
          deleteAccountTitle: 'アカウントを削除',
          deleteAccountMessage: 'アカウントは利用できなくなり、すべての個人情報が完全に削除されます。',
          alertTitle: 'アカウント削除の確認をお願いします',
          deleteDialog: {
            dialogTwo: 'アカウントを削除すると、すべての個人情報が当社のデータベースから完全に削除されます。',
            dialogThree: 'この操作は元に戻せません。',
            dialogFour: '続行するにはパスワードを入力してください。',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'キャッシュが正常にクリアされました！',
          editProfile: 'プロフィールを編集',
          clearCache: 'キャッシュをクリア',
          alertTitle: 'アプリキャッシュのクリアを確認してください',
          deleteAll: 'すべて削除',
          accordionDialog: {
            dialogOne: '以下のページに保存されたすべての設定が削除されます：',
            dialogTwo: 'チャート、チャートフィルター、期間',
            dialogThree: 'フィルター、並べ替え、列、プレビュー',
            dialogFour: 'フィルター、列',
            dialogFive: '選択された会社',
            dialogSix: 'フィルター、列',
          },
          deleteSelective: '選択的に削除',
          selectiveDialog: '削除する保存済み設定を選択してください：',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: '期間',
            CHART_FILTERS: 'チャートフィルター',
            CHARTS: 'チャート',
          },
          Records: {
            HIGHLIGHTED: 'プレビュー',
            COLUMNS: '列',
            FILTERS: 'フィルター',
            SORT_COLUMN: '並べ替え',
          },
          Actions: {
            FILTERS: 'フィルター',
            COLUMNS: '列',
          },
          Company: {
            SELECTED: '選択された会社',
          },
          Tags: {
            FILTERS: 'フィルター',
            COLUMNS: '列',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: '会社',
          'leakStatus.building': 'ユニット/機器',
          'leakStatus.facility': '施設',
          'leakStatus.leakTag': '漏洩タグ番号/作業参照番号',
        },
        tableBody: {
          noAssignmentsToDisplay: '表示する割り当てがありません',
        },
        filtersCommonWords: {
          recordsFilter: '記録フィルター',
          column: '列',
          selectAColumn: '列を選択...',
          addSearchTerm: '検索語句を追加...',
          filtersArentAvailable: 'フィルターは利用できません',
          value: '値',
          exportCSV: 'CSVをエクスポート',
          show: '表示',
          loadMoreRecords: 'さらに記録を読み込む',
          selectedAllRecords: 'すべての記録を選択しました ({{totalNum}})',
          selectedFromRecords: '{{totalNum}} 件の記録から {{selectedNum}} 件を選択しました',
          reset: 'リセット',
        },
        invalidColumn: '無効な列 {{column}}',
        assignedActionRecords: '割り当てられたアクション記録',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'パスワードをお忘れですか？',
          successMessage: '確認メールを送信しました：',
        },
        authSuccess: {
          thanks: 'ありがとうございます！',
          backToLogin: 'ログインに戻る',
        },
        loginView: {
          forgotPass: 'パスワードをお忘れですか？',
          createAccount: 'アカウントを作成',
          passwordIsRequired: 'パスワードは必須です',
          resetPassword: 'パスワードをリセット',
          contactSupport: 'support@prosaris.ca にお問い合わせください',
          incorrectEmailOrPassword: 'メールアドレスまたはパスワードが正しくありません',
          resetPassDialog: {
            dialogOne: '続行する前にパスワードをリセットする必要があります。',
            dialogTwo: 'パスワードリセットの指示が記載されたメールを送信しました。',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'パスワードが正常にリセットされました',
          enterNewPassword: '新しいパスワードを以下に入力してください。',
          passwordChanged: 'パスワードが変更されました。',
        },
        createCompanyView: {
          createCompany: '会社を作成',
          companyName: '会社名',
          successCreatingCompany: '会社が作成されました。',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: '漏洩データの読み込み中に予期しないエラーが発生しました',
          calculatingError: '計算中に予期しないエラーが発生しました',
          dbMeasured: '測定されたdB',
          calculate: '計算',
          calculating: '計算中...',
          outflowCoefficient: '流出係数',
          kwhPerYear: '年間kWh',
        },
      },
      companies: {
        commonUsedWords: {
          assignedAdminLicenses: '割り当てられた管理者ライセンス',
          company: '会社',
          totalAccounts: '総アカウント数',
          email: 'メール',
          name: '名前',
          loadCompanyUsersError: '会社のユーザーの読み込みに失敗しました。後でもう一度お試しください',
          activated: 'アクティブ化済み',
          deactivated: '非アクティブ化済み',
          activating: 'アクティブ化中',
          deactivating: '非アクティブ化中',
          deactivate: '非アクティブ化',
          activate: 'アクティブ化',
          set: '設定',
          youAreGoingTo: 'これから行います',
          areYouSure: '本当に実行しますか？',
          companyNameIsRequired: '会社名は必須です',
          editCompany: '会社の編集',
          createCompany: '会社の作成',
          viewHistory: '履歴を見る',
          exportCSV: 'CSVエクスポート',
          onEssentialPlan: 'あなたはエッセンシャルプランに登録されています。support@prosaris.caにお問い合わせください',
        },
        groupPerformance: {
          title: 'グループパフォーマンス',
          normalizeData: 'データの正規化',
          selectTimeline: 'タイムラインを選択',
          selectCriteria: '基準を選択',
        },
        charts: {
          chart: {
            criteria: {
              KWH: '年間総kWh',
              LOSS: '総損失 ($/年)',
              FLOW: '総質量流量',
            },
            noDataAvailable: 'データが利用できません',
            topNCompanies: '上位{{numCompanies}}社',
          },
        },
        accounts: {
          accountDialog: {
            companyIsRequired: '会社は必須です',
            roleIsRequired: '役割は必須です',
            createAccountSuccessMessage: 'アカウントが正常に作成されました',
            emailTakenErrorMessage: 'このメールアドレスのアカウントはすでにシステム内に存在します',
            errorWhileCreatingAccountMessage: 'アカウント作成中にエラーが発生しました。後でもう一度お試しください。',
            changeAccountSuccessMessage: 'アカウントが正常に更新されました',
            errorWhileChangingAccountMessage: 'アカウント更新中にエラーが発生しました。もう一度お試しください。',
            editAccount: 'アカウントを編集',
            createAccount: 'アカウントを作成',
            role: '役割',
          },
          changeOwner: {
            changeOwnerMessage: 'このアカウントに関連する漏洩記録の所有者も変更しますか？',
            no: 'いいえ、現在のアカウントをそのままにする',
          },
          contentCell: {
            loadAccountRolesError: 'アカウント役割情報の読み込みに失敗しました。後でもう一度お試しください。',
            changeRoleSuccessMessage: 'アカウント {{email}} に新しい役割が割り当てられました',
            changeRoleErrorMessage: 'アカウント {{email}} の役割更新中にエラーが発生しました。後でもう一度お試しください。',
            editUser: 'ユーザーを編集',
            adminSetPassword: '管理者がパスワードを設定',
            adminSetSubRenewalDate: '管理者によるサブスクリプション更新日の設定',
            resetPassword: 'パスワードをリセット',
            user: 'ユーザー',
            deleteUser: 'ユーザーを削除',
          },
          index: {
            noUsersToDisplay: '表示するユーザーがいません',
            changeStatusSuccessMessage: '{{isActive}} ユーザーが正常に変更されました',
            changeStatusErrorMessage: '{{isActive}} ユーザーの変更中にエラーが発生しました。もう一度お試しください',
            changeOwnerSuccessMessage: '漏洩記録が新しい所有者に転送されました',
            changeOwnerErrorMessage: '記録を新しい所有者に転送できません。後でもう一度お試しください',
            deleteAccountMutationSuccessMessage: 'アカウント {{email}} が正常に削除されました',
            deleteAccountMutationErrorMessage: '現在、アカウント {{email}} を削除できません。後でもう一度お試しください',
            resetAccountPassWordSuccessMessage: 'パスワードリセットメールが正常に送信されました。',
            users: 'ユーザー',
            noneAvailable: '利用可能なものはありません',
            of: '/',
            contactSupport: '会社管理者ライセンスの詳細については、support@prosaris.ca にお問い合わせください',
            findAccount: 'アカウントを探す',
            placeholder: '名前またはメールアドレスを入力...',
            tooltip: 'ユーザーを招待するには、少なくとも1つの会社管理者ライセンスが必要です',
            invite: '招待',
            alertDialog: {
              messageOne: 'ユーザーのパスワードリセットリクエストを確認してください',
              messageTwo: 'アカウントステータス変更を確認してください',
              messageThree: 'アカウント削除を確認してください',
            },
            sendAPasswordResetEmail: '次のアカウントにパスワードリセットメールを送信しようとしています：',
            confirmMessage: '続行しますか？',
            followingAccount: '次のアカウント：',
            delete: '削除',
            userCreatedDialogTitle: 'ユーザーパスワードリセットリンクが正常に送信されました',
            userCreatedDialogDescription:
              'ユーザーにパスワードリセットリンクがメールアドレスに送信されました。以下のリンクをコピーすることもできます。',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'アカウントのパスワードが正常に更新されました',
            changedUserPasswordErrorMessage: 'アカウント更新中にエラーが発生しました。もう一度お試しください。',
            setNewPassword: '新しいパスワードを設定',
            accountPasswordUpdated: 'アカウントのパスワードが更新されました',
            accountPasswordUpdatedDescription:
              'アカウントのパスワードが正常に更新されました。新しいパスワードをコピーするには、下のボタンをクリックしてください。',
          },
          adminSetSubRenewalDate: {
            title: 'サブスクリプション更新日を設定',
            subRenewalDate: 'サブスクリプション更新日',
            changedSubRenewalDateSuccessMessage: 'サブスクリプション更新日を正常に更新しました',
            changedSubRenewalDateErrorMessage: 'アカウントの更新中にエラーが発生しました。後でもう一度お試しください',
          },
          setPassword: {
            automatic: '強力なパスワードを自動生成',
            manual: 'パスワードを手動で作成',
            copyLinkInstruction: '次のステップでパスワードを表示およびコピーできます',
          },
          userCreatedDialog: {
            title: 'ユーザーが正常に作成されました',
            description:
              '新しいユーザーはプラットフォームを利用開始できます。ユーザーにはパスワードをリセットするためのリンクが記載されたメールも送信されます。',
            passwordCopied: 'パスワードがクリップボードにコピーされました',
            passwordResetLinkCopied: 'リセットパスワードリンクがクリップボードにコピーされました',
            copyPassword: 'パスワードをコピー',
            copyPasswordResetLink: 'リセットパスワードリンクをコピー',
          },
          useRoleOptions: {
            notAvailable: '（利用不可）',
            adminSeatTaken: '（管理者シートが使用されています）',
            allSeatsAreTaken: '（すべての {{numSeats}} シートが使用されています）',
            adminRolesTitle: '管理者ロール',
            roles: {
              superAdmin: 'ルート管理者',
              companyAdmin: '会社 (管理者)',
              superCompanyAdmin: '企業 (管理者)',
              companyRead: '会社 (閲覧のみ)',
              superAdminRead: 'ルート管理者（閲覧のみ）',
              integrator: '統合者',
              auditor: '監査者',
              user: 'ユーザー',
              essential: 'Prosaris Essential',
            },
            rolesDescriptions: {
              superAdmin: '複数の会社を完全に管理する権限があります。',
              companyAdmin: '会社の記録を表示、編集、および割り当てる権限があります。',
              superCompanyAdmin: '会社の記録およびユーザーを完全に管理する権限があります。',
              companyRead: '会社の記録を閲覧のみ可能です。',
              superAdminRead: 'すべての会社に対して閲覧のみ可能です。',
              integrator: 'サードパーティ統合が会社とアカウントを管理することを許可します。',
              auditor: '漏洩記録の監査を許可します。',
              user: '自身の記録を作成/更新/削除する権限があります。',
              essential: '機能が制限された役割です。',
            },
            nonAdminRolesTitle: '非管理者ロール',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: '監査の読み込みに失敗しました。後でもう一度お試しください。',
            audits: '監査',
            noAuditsToDisplay: '表示する監査がありません',
            findByAuditorEmail: '監査員のメールで検索',
            placeholder: '監査員のメールアドレスを入力...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: '施設名は必須です',
              hoursYearlyIsRequired: '年間合計時間は必須です',
              totalHoursYearly: '年間合計時間は8760を超えることはできません',
              invalidPhoneNumber: '無効な電話番号です',
              createErrorMessage: '時間 × シフトは24時間を超えることはできません',
              loadFacilityErrorMessage: '施設の読み込みに失敗しました。後でもう一度お試しください。',
              createFacilitySuccessMessage: '施設が正常に作成されました',
              createFacilityErrorMessage: '施設作成中にエラーが発生しました。後でもう一度お試しください。',
              changeFacilitySuccessMessage: '施設が正常に更新されました',
              changeFacilityErrorMessage: '施設更新中にエラーが発生しました。もう一度お試しください。',
              facilityName: '施設名',
              abbreviation: '略称',
              address: '住所',
              enterALocation: '場所を入力',
              tooltip: 'エネルギー管理活動、またはレポート承認者のために',
              energyMgtOwner: 'エネルギー管理オーナー',
              title: '役職',
              phone: '電話番号',
              tooltipTwo: 'ソリューション更新および一般的なアカウント問題のために',
              accountOwner: 'アカウント所有者',
              manufacturingArea: '製造エリア',
              size: 'サイズ',
              unit: '単位',
              hoursOfOperation: '運用時間',
              auditFrequency: '監査頻度',
            },
            operationHours: {
              daysPerWeek: '日数/週',
              shiftsPerDay: 'シフト/日',
              hoursPerShift: '時間/シフト',
              weeksPerYear: '52週/年',
              totalHoursYearly: '合計 = 年間合計時間',
            },
            constants: {
              constant: '一定',
              monthly: '毎月',
              threeMonth: '3か月',
              fourMonth: '4か月',
              sixMonth: '6か月',
              nineMonth: '9か月',
              annual: '年間',
              sqft: '平方フィート [SQFT]',
              sqm: '平方メートル [SQM]',
            },
          },
          index: {
            users: 'ユーザー',
            devices: 'デバイス',
            facility: '施設',
            energy: 'エネルギー',
            seu: 'SEUs - 構成',
            enpi: 'EnPIs/EnBs',
            routes: 'ルート',
            audit: '監査',
            comingSoon: '近日公開...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'EnPIsの読み込みに失敗しました。後でもう一度お試しください。',
            createEnpisSuccessMessage: 'EnPIsが正常に作成されました',
            createEnpisErrorMessage: 'EnPIs作成中にエラーが発生しました。後でもう一度お試しください。',
            changeEnpisSuccessMessage: 'EnPIsが正常に更新されました',
            changeEnpisErrorMessage: 'EnPIs更新中にエラーが発生しました。もう一度お試しください。',
            showHistory: '履歴を表示',
          },
          changeHistory: {
            bulkOperation: '一括操作として',
            details: '詳細',
            created: '作成',
            updated: '更新',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: '履歴',
            noHistory: '履歴なし',
          },
        },
        energy: {
          index: {
            utilProvider: '電力供給会社',
            energyUse: 'エネルギー使用量と消費タイプ',
            error: {
              loadFacility: '施設の読み込みに失敗しました。後でもう一度お試しください。',
              loadEnergy: 'エネルギーデータの読み込みに失敗しました。後でもう一度お試しください。',
              loadUtilProviderLogo: '電力供給会社のロゴの読み込みに失敗しました。後でもう一度お試しください。',
              createEnergy: 'エネルギーデータの作成に失敗しました。後でもう一度お試しください。',
              createUtilProviderLogo: '電力供給会社のロゴのアップロードに失敗しました。後でもう一度お試しください。',
              updateEnergy: 'エネルギーデータの更新に失敗しました。後でもう一度お試しください。',
              deleteUtilProviderLogo: '電力供給会社のロゴの削除に失敗しました。後でもう一度お試しください。',
            },
            success: {
              createEnergy: 'エネルギーデータが正常に作成されました',
              updateEnergy: 'エネルギーデータが正常に更新されました',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: 'エネルギーコスト',
              incentiveRate: 'インセンティブレート',
              energySources: 'エネルギー源',
              per: 'あたり',
              validations: {
                utilProviderIsRequired: '電力供給会社は必須です',
                costOfEnergyIsRequired: 'エネルギーコストは必須です',
                costOfEnergyMustBeNumber: 'エネルギーコストは数値でなければなりません',
                costOfEnergyMustBeGreaterThanZero: 'エネルギーコストは0より大きい必要があります',
                costOfRebateMustBeAtLeatZero: 'リベートコストは少なくとも0でなければなりません',
                costOfRebateMustBeNumber: 'リベートコストは数値でなければなりません',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: 'ファイルサイズは1 MB未満である必要があります',
                preview: '画像のプレビューに失敗しました',
              },
              logo: 'ロゴ',
              addLogo: 'ロゴを追加',
              removeLogo: 'ロゴを削除',
            },
            EnergySources: {
              energySource: 'エネルギー源',
              percentage: '割合',
              selectEnergySource: 'エネルギー源を選択',
              addEnergySource: 'エネルギー源を追加',
              totalPercentage: '合計割合',
              mustBeEqualTo100: '（100%である必要があります）',
              constants: {
                energySources: {
                  NATURAL_GAS: '天然ガス',
                  FUEL_OIL: '重油',
                  DIESEL: 'ディーゼル',
                  GASOLINE: 'ガソリン',
                  PROPANE: 'プロパン',
                  COAL: '石炭',
                  WIND: '風力',
                  GEOTHERMAL: '地熱',
                  BIOMASS: 'バイオマス',
                  STEAM: '蒸気',
                  COMPRESSED_AIR: '圧縮空気',
                  CHILLED_WATER: '冷水',
                  NUCLEAR: '原子力',
                  HYDRO: '水力',
                  SOLAR: '太陽光',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: '現在の計算方法',
              currentCalcApproachMessage:
                'この計算方法は、ユーザーが上書きしない限り、すべての「エネルギーコスト」フィールドにデフォルトで適用されます。',
              alertTitle: '計算方法のリセット',
              alertMessageOne: 'この操作により、以降すべての「エネルギーコスト」フィールドに現在の計算方法が適用されなくなります。',
              alertMessageTwo: 'この変更は既存の記録には影響を与えませんのでご注意ください。',
              alertMessageThree: 'リセットを実行してもよろしいですか？',
            },
            ReportType: {
              calcApproach: '計算方法',
              constants: {
                calculations: {
                  FIXED: '固定効率',
                  USDOE: '米国エネルギー省 (US DoE)',
                  NREL: 'NREL 第22章',
                },
              },
              setAsCurrent: '現在の設定として適用',
              setAsCurrentMessage: 'この計算方法は、以降すべての「エネルギーコスト」フィールドに使用されます。',
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: '効率は0以上である必要があります',
                  efficiencyMustBeNumber: '効率は数値でなければなりません',
                  operationHoursMustBeGreaterThanZero: '稼働時間は0以上である必要があります',
                  operationHoursMustBeLessThan8760: '稼働時間は8760未満である必要があります',
                  operationHoursMustBeNumber: '稼働時間は数値でなければなりません',
                },
              },
              UsDoe: {
                loaded: '負荷時',
                unloaded: '無負荷時',
                calcSummary: '計算概要',
                currentOperatingSystems: '現在の運用システム',
                noCompsFoundAddCompOne: 'コンプレッサーが見つかりませんでした。上記の',
                noCompsFoundAddCompTwo: 'タブからコンプレッサーを追加してください。',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: '名前プレートのアップロード中にエラーが発生しました。後でもう一度お試しください。',
            dataSheetError: 'データシートのアップロード中にエラーが発生しました。後でもう一度お試しください。',
            createSuccess: 'コンプレッサー {{tag}} が作成されました',
            createError: 'コンプレッサーの作成に失敗しました。後でもう一度お試しください。',
            createCompressor: 'コンプレッサーを作成',
          },
          compressorFile: {
            tip: 'ファイルを追加',
            removeFile: 'ファイルを削除',
            removeImage: '画像を削除',
            title: 'コンプレッサー {{prop}} 削除の確認をお願いします',
            proceed: '続行しますか？',
          },
          compressorForm: {
            compTagIsReq: 'コンプレッサータグは必須です',
            hpMustBeNum: '名前プレートHPは数値である必要があります',
            capMustBeNum: '名前プレート容量は数値である必要があります',
            motorEffMustBeNum: 'モーター効率は数値である必要があります',
            motorVoltMustBeNum: 'モータ電圧は数値である必要があります',
            loadMustBeNum: 'フルロード電流は数値である必要があります',
            loadInputMustBeNum: 'フルロード入力電力は数値である必要があります',
            pressureMustBeNum: '定格圧力は数値である必要があります',
            setpointMustBeNum: '圧力設定値は数値である必要があります',
            airMustBeNum: '総空気貯蔵容量は数値である必要があります',
            addImage: '画像を追加',
            addFile: 'ファイルを追加',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'ロータリースクリュー',
              RECIPROCATING: 'レシプロ',
              CENTRIFUGAL: '遠心式',
            },
            rotaryScrew: {
              loadUnloadOilFree: '負荷/無負荷 - オイルフリー',
              loadUnload1Gal: '負荷/無負荷 (1 ガロン/CFM)',
              loadUnload3Gal: '負荷/無負荷 (3 ガロン/CFM)',
              loadUnload5Gal: '負荷/無負荷 (5 ガロン/CFM)',
              loadUnload10Gal: '負荷/無負荷 (10 ガロン/CFM)',
              ivmWithBlowdown: '入口弁調整（ブローダウンなし）',
              ivmWithoutBlowdown: '入口弁調整（ブローダウンあり）',
              variableDisplacement: '可変容量',
              vsdWithUnloading: 'VSD（無負荷運転あり）',
              vsdWithStopping: 'VSD（停止あり）',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'オン/オフ制御',
              reciprocatingLoadUnload: '負荷/無負荷',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV（ブローダウンあり）',
              centrifugalInletIbv: 'IGV（ブローダウンあり）',
            },
          },
          contentCell: {
            min: '% は少なくとも 0 以上である必要があります',
            max: '% は 100 を超えることはできません',
            max150: '% は 150 を超えることはできません',
            typeError: '負荷は有効なパーセンテージでなければなりません',
            setpointMustBeNum: 'コンプレッサー設定値は数値である必要があります',
            flowMustBeNum: 'コンプレッサー流量は数値である必要があります',
            pressureSetpointMustBeNum: 'システム圧力設定値は数値である必要があります',
            measuredFlowMustBeNum: 'システム測定流量は数値である必要があります',
            dateTime: '日付と時刻を選択',
            active: 'アクティブ',
            off: 'オフ',
            trim: 'トリム',
            baseLoad: 'ベースロード',
          },
          index: {
            error: 'コンプレッサーの読み込みに失敗しました。後でもう一度お試しください。',
            nameplateError: '名前プレートの読み込みに失敗しました。後でもう一度お試しください。',
            dataSheetError: 'データシートの読み込みに失敗しました。後でもう一度お試しください。',
            changeCompSuccess: 'コンプレッサーが正常に更新されました',
            changeCompError: 'コンプレッサー更新中にエラーが発生しました。もう一度お試しください。',
            deleteCompSuccess: 'コンプレッサーが正常に削除されました',
            deleteCompError: 'コンプレッサー削除中にエラーが発生しました。もう一度お試しください。',
            createCompNameplateSuccess: 'コンプレッサーの名前プレートが正常にアップロードされました',
            createCompNameplateError: '名前プレートのアップロード中にエラーが発生しました。後でもう一度お試しください。',
            deleteCompNameplateSuccess: 'コンプレッサーの名前プレートが正常に削除されました',
            deleteCompNameplateError: '名前プレート削除中にエラーが発生しました。後でもう一度お試しください。',
            createCompDataSheetSuccess: 'コンプレッサーのデータシートが正常にアップロードされました',
            createCompDataSheetError: 'データシートのアップロード中にエラーが発生しました。後でもう一度お試しください。',
            deleteCompDataSheetSuccess: 'コンプレッサーのデータシートが正常に削除されました',
            deleteCompDataSheetError: 'データシート削除中にエラーが発生しました。後でもう一度お試しください。',
            changeCompanyCompSuccess: 'システムデータが正常に更新されました',
            changeCompanyCompError: 'システムデータ更新中にエラーが発生しました。もう一度お試しください。',
            compDesignData: 'コンプレッサー設計データ',
            comp: 'コンプレッサー：',
            addComp: 'コンプレッサーを追加',
            editCompPermission: 'コンプレッサーを編集するには管理者ライセンスが必要です。以下にお問い合わせください：',
            selectOption: 'オプションを選択',
            undoChanges: '変更を元に戻す',
            selectComp: '上記からコンプレッサーを選択してください。',
            noCompsFound: 'コンプレッサーが見つかりません。',
            compRunningState: 'コンプレッサー稼働状態',
            noCompsFoundAddComp: 'コンプレッサーが見つかりませんでした。上記のセクションからコンプレッサーを追加してください。',
            title: 'コンプレッサーの削除を確認してください',
            areYouSure: '本当に実行しますか？',
            compressor: 'コンプレッサー',
            delete: '削除',
          },
        },
        devices: {
          contentCell: {
            device: 'デバイス',
            usedByMultipleCompanies: '複数の会社で使用されています',
          },
          index: {
            noDevicesToDisplay: '表示するデバイスがありません',
            loadingDevicesErrorMessage: '会社のデバイスの読み込みに失敗しました。後でもう一度お試しください。',
            changeStatusSuccessMessage: 'デバイスが正常に{{isActive}}されました',
            changeStatusErrorMessage: 'デバイスの{{isActive}}中にエラーが発生しました。もう一度お試しください。',
            devices: 'デバイス',
            findDevice: 'デバイスを検索',
            placeholder: 'デバイスIDを入力...',
            activatedOn: '有効化日',
            title: 'デバイスのステータス変更を確認してください',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} が正常に削除されました',
          deleteCompanyErrorMessage: '{{companyName}} の削除に失敗しました。後でもう一度お試しください。',
          needAdminLicense: 'この機能にアクセスするには管理者ライセンスが必要です。support@prosaris.ca にお問い合わせください。',
          deleteChildCompanyFirst:
            '子会社やアカウントがある会社を削除することはできません。まず、すべての子会社とアカウントを削除してください。',
          editSettings: '設定を編集',
          createASubCompany: '{{companyName}} の子会社を作成',
          deleteCompany: '会社を削除',
          cannotCreateChildCompanyDialog: {
            messageOne: 'コーポレートアカウントで作成可能な会社数の上限に達しました。詳細については、',
            messageTwo: 'にお問い合わせください。',
          },
          deleteCompanyDialog: {
            title: '会社の削除を確認してください',
            delete: '削除',
            deleting: '削除中',
            permanentlyDeleted: 'はデータベースから完全に削除されます。',
            cannotBeUndone: 'この操作は元に戻せません。',
          },
        },
        companyDialog: {
          industryIsRequired: '業界は必須です',
          createLogoErroerMessage: '画像のアップロード中にエラーが発生しました。後でもう一度お試しください。',
          deleteLogoErrorMessage: '画像削除中にエラーが発生しました。後でもう一度お試しください。',
          createCompanySuccessMessage: '会社 {{name}} が作成されました',
          createCompanyErrorMessage: '会社の作成に失敗しました。後でもう一度お試しください。',
          changeCompanySuccessMessage: '会社 {{name}} が更新されました',
          changeCompanyErrorMessage: '会社パラメータの変更に失敗しました。後でもう一度お試しください。',
          language: '言語',
          industry: '業界',
          country: '国',
          currency: '通貨',
        },
        companyLogo: {
          setImageErrorMessage: 'ファイルサイズは1MB未満である必要があります',
          logo: 'ロゴ',
          add: '追加',
          removeLogo: 'ロゴを削除',
          addLogo: 'ロゴを追加',
        },
        index: {
          loadCompaniesErrorMessage: '会社リストの読み込みに失敗しました。後でもう一度お試しください。',
          changeCompaniesSectionWidth: '会社セクションの幅を変更',
        },
        sidebar: {
          filterCompanies: '会社をフィルタ',
          placeholder: '会社名を入力',
          myCompany: '私の会社',
          childCompanies: '子会社',
        },
        routes: {
          index: {
            noFacility: '定義された施設がありません',
            createAFacility: 'ルートを計画するために施設を作成してください',
            goToCreateAFacility: '施設作成ページに移動',
            createAFacilityTooltip: 'ルートを計画する前に施設を作成する必要があります。',
            save: '保存',
            reset: 'リセット',
            routePlans: 'ルート計画',
            loading: '読み込み中...',
            noRoutesAdded: '追加されたルートはありません。',
            buildARouteTooltip: 'ルートビルダーを使用してルートを作成します。',
            createFloorplanSuccess: '成功！ フロアプランがアップロードされました！',
            createRouteSuccess: '成功！ ルートが作成されました！',
          },
          routeBuilder: {
            title: 'ルートビルダー',
            missingFields: 'Excelファイルに欠落しているフィールドがあります',
            manuallyBuildARoute: '新しいルート計画を手動で作成',
            manualInstructions: 'ルートは手動で作成するか、Excelファイルをアップロードして作成できます。',
            facility: '施設',
            route: 'ルート',
            enterRoute: 'ルートを入力',
            addFloorplan: 'フロアプランを追加',
            floorplanInstructionsTooltip:
              'フロアプランの画像を追加してルート作成を支援します。保存されると、ルートはフロアプラン上に重ねられます。',
            change: '変更',
            location: '場所',
            enterLocation: '場所を入力',
            equipment: '機器',
            enterEquipment: '機器を入力',
            criticality: '重要性',
            enterCriticality: '重要性を入力',
            description: '説明',
            enterDescription: '説明を入力',
            addEquipment: '機器を追加',
            addRoute: 'ルートを追加',
            downloadTemplate: 'テンプレートをダウンロード',
          },
          excelReader: {
            loadFromFile: 'ファイルから読み込む',
            instructionsTooltip:
              'Excelファイルをアップロードすることでルートを作成できます。各シートは施設内の異なる場所を表します。各場所には複数のルートを設定できます。',
          },
          graph: {
            controls: {
              addFloorplan: 'フロアプランを追加',
              toggleLabels: 'ラベルを切り替える',
              addWaypoint: 'ウェイポイントを追加',
              addEquipment: '機器を追加',
              swapEquipment: '機器を交換',
              showLeakStats: '漏れ統計を表示',
              save: '保存',
              delete: '削除',
            },
            routeStats: {
              title: 'ルート統計',
              totalDollarLossPerYear: '年間総損失額 ($):',
              totalKwhLossPerYear: '年間総損失量 (kWh):',
              totalLeakCount: '総漏れ数:',
              totalActiveOpen: 'アクティブで未解決の漏れ合計:',
              totalActiveClosed: 'アクティブで解決済みの漏れ合計:',
              noStatsAvailable: '統計が利用できません:',
              close: '閉じる',
            },
            nodeDetails: {
              edit: '編集',
              name: '名前',
              description: '説明',
              criticality: '重要性',
              equipmentInformation: '機器情報',
              thisIsAWaypoint: 'これはウェイポイントです',
              activeOpenLeakCount: 'アクティブで未解決の漏れ数:',
              noActiveLeaksDetected: 'アクティブな漏れは検出されていません',
              close: '閉じる',
              nothingToSeeHere: 'ここには何もありません',
              seeLeakRecords: '漏れ記録を見る',
            },
            swapEquipment: {
              title: '機器を交換',
              selectEquipment: '交換する機器を選択:',
              selectFirst: '最初の機器を選択',
              selectSecond: '2つ目の機器を選択',
              swap: '交換',
              cancel: 'キャンセル',
            },
            addNode: {
              title: '追加',
              waypoint: 'ウェイポイント',
              equipment: '機器',
              afterWhich: 'どの機器の後に追加しますか？',
              selectPreceding: '先行する機器を選択:',
              selectEquipment: '機器を選択',
              name: '名前',
              description: '説明',
              criticality: '重要性',
              add: '追加',
              cancel: 'キャンセル',
            },
            save: '保存',
            delete: '削除',
            successRouteUpdated: 'ルートが正常に更新されました',
            errorRouteUpdated: 'ルート更新中にエラーが発生しました。後でもう一度お試しください。',
            successRouteDeleted: 'ルートが正常に削除されました',
            errorRouteDeleted: 'ルート削除中にエラーが発生しました。後でもう一度お試しください。',
            successFloorplanUpdated: 'フロアプランが正常に更新されました',
            errorFloorplanUpdated: 'フロアプラン更新中にエラーが発生しました。後でもう一度お試しください。',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: '割り当てられた管理者ライセンス',
            of: '/',
            edit: '編集',
          },
          essentialMode: {
            disable: '無効化',
            enable: '有効化',
            essential: 'エッセンシャルモード:',
            enabled: '有効',
            disabled: '無効',
            moveCompany: '{{company}} をエッセンシャルモードに移動',
            affectedCompanies: '影響を受ける会社の数',
            affectedAccounts: '影響を受けるアカウントの数',
            archivedLeak: 'アーカイブされた漏れイベント記録の数',
            applyingChanges:
              'これらの変更を適用すると、アカウントは「エッセンシャル」ロールを取得します。ユーザーは最新の漏れイベント記録 {{quota}} 件をダッシュボードとモバイルアプリで表示でき、他の記録はアーカイブに移動されます。',
            essentialLabel: '子会社のエッセンシャルモードを変更',
            turnOffEssential: '{{company}} のエッセンシャルモードをオフにする',
            byApplyingChanges:
              'これらの変更を適用すると、すべてのアカウントは「ユーザー」ロールを取得します。ユーザーはすべての記録を表示でき、すべての記録がアーカイブから解除されます。',
          },
          hubspotSync: {
            disable: '無効にする',
            enable: '有効にする',
            hubspot: 'HubSpot同期',
            enabled: '有効',
            disabled: '無効',
            enableHubspotSync: '会社 {{company}} の HubSpot 同期を有効にする',
            disableHubspotSync: '会社 {{company}} の HubSpot 同期を無効にする',
            affectedCompanies: '影響を受ける企業数',
            applyingChanges: 'これらの変更を適用すると、HubSpot 同期は {{status}} になります。',
            hubspotLabel: '子会社の HubSpot 同期を変更する',
          },
          index: '設定',
        },
        index: {
          loadCompanyError: '会社情報を読み込めませんでした。後でもう一度お試しください。',
          companySlug: '会社スラッグ',
          notSelected: '未選択',
          country: '国',
          accounts: 'アカウント',
          currency: '通貨',
          adminLicenses: '管理者ライセンス',
          totalCustomer: 'ツリー内の総顧客アカウント数',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'デバイスを登録してください',
          toolTip: '登録カードまたは箱に記載されているシリアル番号を確認してください。',
          serialNumberIsRequired: 'シリアル番号は必須です',
          oops: 'おっと！',
          deviceNotFound: 'デバイスのシリアル番号が見つかりません',
          deviceFoundNdAssociatedCompany: 'デバイスが見つかり、会社に関連付けられています。',
          deviceFound: 'デバイスが見つかりました。',
          goToLogin: 'ログインに進む',
          contactSupport: 'サポートにお問い合わせください',
        },
        deviceErrorCodes: {
          'B13:001': 'デバイスのシリアル番号が見つかりません',
          'B13:002': 'デバイスはすでに有効化されています',
          'B13:003': '管理者のメールが送信されませんでした',
          'B13:004': '会社が見つかりません',
          'B13:005': '会社が無効です',
          'B13:006': '無効なタイプです',
        },
        registerAccountView: {
          registerAccount: 'アカウントを作成',
          termsMustBeAccepted: '利用規約に同意する必要があります。',
          saleType012Success: 'ログインしてデバイスの使用を開始できます。',
          saleType3Success: '基本ユーザーとして追加されました。会社の管理者に通知されました。',
          saleType4Success: {
            companyPlatformFound: '会社プラットフォームライセンスが見つかりました。',
            dialogOne: 'このデバイスは新しい会社プラットフォームライセンスに関連付けられています。',
            dialogTwo: 'お使いの会社は既にシステム内に存在している可能性があります。',
            dialogThree: 'その場合は、管理者にお問い合わせください。',
          },
          termsAndConditions: {
            dialogOne: 'Prosaris の',
            dialogTwo: '利用規約',
            dialogThree:
              'に同意し、それを読む時間が十分に与えられ、Prosaris ソフトウェアの使用に関する重要な条項（例えば、Prosaris の責任制限やデータの取り扱い方法を含む）を理解したことを確認します。',
          },
          acceptTerms: '利用規約に同意',
          registerAccountErrorCodes: {
            'B02:001': '無効な登録コード',
            'B02:002': '無効な会社ユーザー',
            'B02:003': '無効なユーザーロール',
            'B02:004': 'メールアドレスは既に使用されています',
            'B02:005': 'メールアドレスは既に使用されています',
            'B02:006': 'アカウントのパスワードをリセットできません',
            'B02:007': '無効な会社ユーザー',
            'B02:008': 'プロフィール写真がありません',
            'B02:009': 'アカウントリセットが必要です。プロセスが開始されました',
            'B02:010': 'アカウントが無効化されています',
            'B02:011': 'プロフィール写真の形式が無効です',
            'B02:012': 'デバイスは既に会社に登録されています',
            'B02:013': 'シリアル番号が未定義です',
            'B02:014': '無効なタイプ',
            'B02:015': 'パスワードリセットリンクの有効期限が切れています',
            'B02:016': 'この会社のアカウントを作成できません',
            'B02:017': '無効なロール',
            'B02:018': '無効なロール',
            'B02:019': '無効な会社ユーザー',
            'B02:020': '無効なユーザーロール',
            'B02:021': 'ユーザーはこの会社に属していません',
          },
        },
        devicesView: {
          serialNumbersIsReq: 'シリアル番号は必須です',
          failedToCopy: 'コピーに失敗したシリアル番号がクリップボードにコピーされました',
          invalidFormat: '1つ以上のデバイスの形式が無効です。確認してもう一度お試しください。',
          devices: 'デバイスを登録',
          deviceSerialNumber: 'デバイスシリアル番号',
          note: '各シリアル番号を新しい行に入力するか、カンマで区切って入力してください。',
          title: '登録カードまたは箱に記載されているシリアル番号を確認してください。',
          register: '登録',
          deviceRegistrationStatus: 'デバイス登録ステータス',
          success: '成功',
          noDeviceFound: 'デバイスが見つかりません。',
          failed: '失敗',
          copyFailed: 'コピーに失敗したシリアル番号をクリップボードにコピー',
        },
      },
      errors: {
        notFoundView: {
          title: 'お探しのページはここにはありません',
          description: '不正なルートを試したか、間違ってここに来た可能性があります。どちらにしても、ナビゲーションを使ってみてください。',
        },
        accountNotFound: {
          title: 'アカウントが見つかりません',
          description:
            'もう一度お試しください！ 問題が解決しない場合は、アカウントが現在非アクティブであるか、管理者によって初期化されていない可能性があります。管理者にお問い合わせください。',
          logout: 'ログアウト',
        },
      },
      integration: {
        index: {
          loggingInError: 'Prosarisへのログイン中にエラーが発生しました。',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: '画像は利用できません',
          activeActionRecord: {
            updateAssignmentSuccess: '割り当てられたアクションが更新されました。',
            updateAssignmentError: '割り当てられたアクションの更新中にエラーが発生しました。',
            deleteImageSuccess: '割り当てられた画像が削除されました。',
            deleteImageError: '割り当てられたアクション画像の削除中にエラーが発生しました。',
            confirmMarkAction: '本当にこのアクションを完了としてマークしますか？',
            confirmDeleteImage: '本当にこの画像を削除しますか？',
          },
          index: 'レコードの割り当てアクションの読み込み中にエラーが発生しました。',
        },
        audio: {
          noAudioAvailable: 'オーディオデータは利用できません',
          errorDownloadingAudio: 'オーディオのダウンロード中にエラーが発生しました。後でもう一度お試しください。',
          playPause: '再生 / 一時停止',
          generateRegion: '領域を生成',
          removeLastRegion: '最後の領域を削除',
          generateMarker: 'マーカーを生成',
          removeLastMarker: '最後のマーカーを削除',
          toggleTimeline: 'タイムラインを切り替え',
          download: 'ダウンロード',
        },
        audit: {
          updateAuditSuccess: '監査が正常に更新されました',
          updateAuditError: 'コメントの保存中にエラーが発生しました。後でもう一度お試しください。',
          createAuditSuccess: '監査が正常に作成されました',
          createAuditError: '監査作成中にエラーが発生しました。後でもう一度お試しください。',
          deleteAuditSuccess: '監査が正常に削除されました',
          deleteAuditError: '監査削除中にエラーが発生しました。後でもう一度お試しください。',
          noComment: 'コメントはありません',
          reviewComments: 'コメントをレビュー',
          reviewedBy: 'レビュー担当者:',
          auditDate: '監査日:',
          deleteAudit: '監査を削除',
          reviewedNoComments: 'コメントなしでレビュー済み',
          auditComments: '監査コメント',
          saveComment: 'コメントを保存',
          discardChanges: '変更を破棄',
        },
        changeHistory: {
          created: '作成された',
          updated: '更新された',
          deleted: '削除された',
          transferred: '転送された',
          leak: '漏れ',
          images: '画像',
          audit: '監査',
          audio: 'オーディオ',
          history: '履歴',
          noHistory: '履歴なし',
          bulk: '一括操作として',
          details: '詳細',
          unset: '未設定',
        },
        fft: {
          fft: 'FFT',
          fftAvg: 'FFT 平均',
          notApplicable: '適用不可',
          chartLayers: 'チャート層',
          avg: '平均',
          all: 'すべて',
          playing: '再生中...',
          showTimeSeries: '時系列を表示',
        },
        form: {
          updateLeakSuccess: '正常に更新されました。',
          updateLeakError: '漏れの更新中にエラーが発生しました。後でもう一度お試しください。',
          alertTitle: {
            partOne: '漏れ記録は',
            partTwo: '読み取り専用',
            partThree: 'モードです。',
          },
          alertDialog: {
            dialogOne: 'ただし、次のプロパティは変更可能です:',
            dialogTwo: 'アクションステータス',
          },
          GPSLocation: 'GPS位置',
          completedOn: '完了日:',
          lastSaved: '最終保存日:',
        },
        images: {
          removeSuccess: '画像が正常に削除されました。',
          removeError: '漏れ記録画像を削除できませんでした。後でもう一度お試しください。',
          text: '本当にこの画像を削除しますか？',
        },
        index: {
          title: '漏れイベント記録',
          loadingError: '漏れ記録の読み込み中にエラーが発生しました。後でもう一度お試しください。',
          unsavedMessage: "'OK'を押すと、保存されていない変更がすべて削除されることを確認します",
          notAnAuditor: '監査員ではありません',
          anEssential: 'あなたはエッセンシャルプランにいます',
          showHistory: '履歴を表示',
        },
        massFlow: {
          recalculating: '漏れパラメータ設定に従って流量/損失を再計算しています...',
          warning: "入力値が変更されたか、計算が古くなっています。'再計算'を押して流量と損失の値を更新してください",
          calculatingError: '流量の計算中にエラーが発生しました。後でもう一度お試しください。',
          noValue: '値なし',
          calculatingSuccess: '流量/損失を再計算しました',
          recalculate: '再計算',
          calculatorVersion: '計算バージョン:',
          noteDialog: {
            dialogOne: '漏れパラメータ設定を変更すると、流量/損失の計算が更新されます。詳細については、',
            dialogTwo: '計算インサイト',
            dialogThree: 'ページをご確認ください',
          },
        },
        unsavedWarningDialog: {
          recordChanged: '漏れ記録が変更されました',
          close: '閉じる',
          unsavedChanges: 'この記録には保存されていない変更があります。変更を保存するか、完全に削除することを検討してください。',
          discardChanges: '変更を破棄',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: 'デバイスIDは必須です',
          dbPeakIsRequired: 'ピークDB測定値は必須です',
          dbMin: 'ピークDB測定値は0以上でなければなりません',
          dbMax: 'ピークDB測定値は95以下でなければなりません',
          dbError: 'ピークDB測定値は数字でなければなりません',
          imageUploadError: '画像のアップロード中にエラーが発生しました。後でもう一度お試しください。',
          createLeakSuccess: '漏れ記録が正常に追加されました',
          createLeakError: 'アカウント作成中にエラーが発生しました。後でもう一度お試しください。',
          basicInfo: '基本情報',
          flowInput: '流量入力',
          mainGas: '主なガス/関心のある成分',
          selectComponent: 'コンポーネントを選択',
          selectLeakType: '漏れタイプを選択',
          mightBeSomethingHere: 'まだ確定していません',
          energyLoss: 'エネルギー損失',
          selectCompressorType: 'コンプレッサータイプを選択',
          noRoutes: 'ルートが見つかりません',
          noEquipment: '機器が見つかりません',
          noLocations: '場所が見つかりません',
          energyCost: 'エネルギーコスト',
          audioRecording: 'オーディオ録音',
          leakSourceEquipment: '漏れ源機器',
          selectComponentType: 'コンポーネントタイプを選択',
          selectService: 'サービスを選択',
          locationAndUser: '場所とユーザー',
          leakEventStatus: '漏れイベントステータス',
          low: '低',
          medium: '中',
          high: '高',
          open: 'オープン',
          complete: '完了',
          reset: 'リセット',
        },
        images: {
          leakSourceImageCapture: '漏れ源画像キャプチャ',
          add: '追加',
        },
        index: {
          title: '漏れ記録を作成',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'から',
          to: 'へ',
          qualifier: '修飾子',
        },
        modalAssignUsers: {
          assignLeakRecord: '漏れ記録の割り当て',
          selectAUserToAssign: '選択したレコードに割り当てるユーザーを選択してください',
          filterByName: '名前でフィルタ',
          filterByEmployeeName: '従業員名でフィルタ',
          unexpectedError: '予期しないエラーが発生しました',
          noUserFound: 'ユーザーが見つかりません',
          assigningActionForSelectedRecords: '選択したレコードにアクションを割り当てています...',
        },
        modalBulkEdit: {
          titleSingleRecord: '{{selectedRecordsCount}} 件の記録を一括編集',
          title: '{{selectedRecordsCount}} 件の記録を一括編集',
          selectAField: 'フィールドを選択してください',
          verifyAction: '他のユーザーに属する記録を更新しようとしています。以下に選択された記録の数を入力して確認してください。',
          matchCountError: '選択された記録の数と一致する必要があります（{{selectedRecordsCount}}）',
          moreThanOneType: '選択した記録には複数のタイプがあります。一部のフィールドは一括編集で利用できない場合があります。',
        },
        tableHead: {
          youAreSelecting: '選択中:',
          visibleRecords: '表示されているレコード',
          andAllUnloadedRecords: 'およびすべての未ロードレコード',
          allRecordsWillBeLoaded: 'すべてのレコードがロードされ、選択されます。',
          thisCanTakeAWhile: 'レコードが多い場合、時間がかかることがあります。',
          allRecords: '非表示のレコードも含むすべてのレコード',
          selectAllRecords: 'すべてのレコードを選択',
        },
        tableBody: {
          noRecordsToDisplay: '表示するレコードはありません。',
        },
        toolBar: {
          assign: '割り当て',
          onEssentialPlan: 'エッセンシャルプランにご加入中です。サポートはsupport@prosaris.caにお問い合わせください',
          bulkEdit: '一括編集',
          bulkEditSuccess: '{{len}} 件の記録が正常に更新されました',
          bulkEditSuccessPlural: '{{len}} 件の記録が正常に更新されました',
          bulkEditError: '記録を更新中にエラーが発生しました。後でもう一度お試しください',
          exportPDF: 'PDFをエクスポート',
          needAdminLicense: 'この機能にアクセスするには管理者ライセンスが必要です。サポートはsupport@prosaris.caにお問い合わせください',
          create: '作成',
          deleteRecordMessage: '選択したレコードを削除してもよろしいですか？',
          deleteRecordMessagePlural: '選択したレコードを削除してもよろしいですか？',
          permissionDenied: 'アクセス拒否！',
          notAllowedToDelete: '削除できない他のユーザーのレコードを選択しました。選択から削除してください。',
          generatingPDF: 'PDFを生成中...',
          cannotGeneratePDF:
            '申し訳ありませんが、現在{{numberSelected}}件のレコードを1つのPDFドキュメントとしてエクスポートできません。{{limitRecords}}件まで選択してください。',
          cannotExportMessage: '{{numberSelected}}件のレコードをエクスポートできません',
          bulkEditLimitExceeded: '{{maxAllowed}} 件以上の記録を編集することはできません。support@prosaris.ca にお問い合わせください。',
        },
        index: {
          cannotLoadLeakRecord: '申し訳ありませんが、現在漏れ記録をロードできません',
          successfullyAssignedAllRecords: 'すべてのレコードが正常に割り当てられました',
          actionRecordAssigned: 'アクションレコードが割り当てられました！',
          actionRecordAssignedPlural: 'アクションレコードが割り当てられました！',
          errorWhileAssigningLeak: '漏れの割り当て中にエラーが発生しました。後でもう一度お試しください。',
          successfullyDeletedAllRecords: 'すべてのレコードが正常に削除されました',
          successfullyDeletedRecord: '{{length}}件の漏れ記録が正常に削除されました',
          successfullyDeletedRecordPlural: '{{length}}件の漏れ記録が正常に削除されました',
          errorOnRecordsDelete: 'レコード削除中にエラーが発生しました。後でもう一度お試しください',
          leakPreview: 'プレビュー',
          leakRecord: '記録: ',
        },
      },
      tagList: {
        resetTag: 'タグをリセット',
        dataExport: {
          smartLeakTagsFormat: 'スマート漏れタグ形式',
          smartLeakTagsDescription: 'スマート漏れタグページにあるデフォルトフィールドを含みます',
          standardLeakRecordFormat: '標準漏れ記録形式',
          standardLeakRecordDescription: '漏れ記録に関連するすべてのフィールドを含みます',
        },
        filters: { smartLeakTagFilters: 'スマート漏れタグフィルタ' },
        index: {
          cannotLoadTags: '申し訳ありませんが、現在スマート漏れタグを読み込むことができません',
          updateLeakSuccess: 'スマート漏れタグのリセットが成功しました。',
          updateLeakError: 'スマート漏れタグのリセット中にエラーが発生しました。後でもう一度お試しください。',
          bulkEditSuccess: '{{len}}件のスマート漏れタグが正常にリセットされました',
          bulkEditSuccessPlural: '{{len}}件のスマート漏れタグが正常にリセットされました',
          bulkEditError: 'スマート漏れタグのリセット中にエラーが発生しました。後でもう一度お試しください。',
          tagsFileName: 'スマート漏れタグ',
          leakFileName: '漏れ記録',
        },
        tableBody: {
          title: 'タグのリセットを確認してください',
          alertDialog: {
            dialogOne: 'リセットしようとしているのは',
            dialogTwo: 'タグ',
            dialogThree: '漏れ記録から',
            dialogFour: 'アクションステータス: OPEN',
            dialogFive: '続行しますか？',
          },
        },
        toolBar: {
          resetTags: 'タグをリセット',
          title: 'タグを一括リセットすることを確認してください',
          alertDialog: {
            dialogOne: '選択には',
            dialogTwo: 'レコードが含まれています',
            dialogTwoPlural: 'レコードが含まれています',
            dialogThree: 'アクションステータス: OPEN',
            dialogFour: '続行しますか？',
          },
          exportFormatCSV: 'CSVのエクスポート形式を選択',
          exportFormatPDF: 'PDFのエクスポート形式を選択',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: '割り当てられたアクションの読み込み中にエラーが発生しました。もう一度お試しください。',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: '利用可能なチャート',
            selectAChart: 'チャートを選択...',
            chartFilters: 'チャートフィルタ',
          },
          charts: {
            errorMessages: {
              errorMessageOne: '漏れ記録の読み込み中にエラーが発生しました。もう一度お試しください。',
              errorMessageTwo: '終了日よりも前の日付を選択してください。',
              errorMessageThree: '6ヶ月未満の日付期間を選択してください。',
              errorMessageFour: '開始日よりも後の日付を選択してください。',
            },
            charts: 'チャート',
            chartAlreadyExists: 'このチャートはすでに存在します！',
          },
          filters: {
            select: '選択...',
            startDate: '開始日',
            endDate: '終了日',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `漏れカテゴリによる流量 (${unit})`,
              LOSS_BY_LEAK: (currency) => `漏れカテゴリによる損失 (${currency})`,
              TAGS_BY_LEAK: '漏れカテゴリによるタグ',
              AVG_FLOW_BY_LEAK: (unit) => `漏れカテゴリによる平均流量 (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `修理時間による損失と節約の機会 (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `修理時間による流量 (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `修理時間による損失 (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `修理進捗による流量 – 修理済み vs. 未修理 (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `修理進捗による損失 – 修理済み vs 未修理 (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `機器/部品による流量 (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `機器/部品による損失 (${currency})`,
              TAGS_BY_EQUIP_COMP: '機器/部品によるタグ',
            },
            datasetLabel: {
              totalLeakFlow: '見つかった総漏れ流量',
              totalLoss: '総損失',
              totalRecords: '見つかった総記録数',
              avgLeakFlow: '見つかった平均漏れ流量',
              totalLossIn: '総損失（単位）',
              flowByRepairTime: '修理時間による流量',
              lossByRepairTime: '修理時間による損失',
              flowByRepairProgress: '修理進捗による流量',
              recordsByCompletion: '完了による記録数',
              flowByEquipment: '機器による流量',
              totalLossByEquipment: '機器による総損失',
              totalRecordsByEquipment: '機器による総記録数',
            },
            labels: {
              low: '低',
              medium: '中',
              high: '高',
              equipment: '機器',
              savings: '節約',
              opportunity: '機会',
              loss: '損失',
              repaired: '修理済み',
              unrepaired: '未修理',
              LESS_15_DAYS: '15日未満',
              DAYS_16_TO_30: '16〜30日',
              DAYS_31_TO_60: '31〜60日',
              DAYS_61_TO_90: '60日以上',
            },
          },
        },
        index: {
          assignedActions: 'あなたに割り当てられたアクション',
        },
      },
      export: {
        imageUrlOne: '漏れ画像URL 1',
        imageUrlTwo: '漏れ画像URL 2',
        leakTag: 'スマート漏れタグ/作業参照番号',
        per: 'あたり',
      },
    },
    languages: {
      English: '英語',
      Chinese: '中国語',
      Spanish: 'スペイン語',
      French: 'フランス語',
      German: 'ドイツ語',
      Norwegian: 'ノルウェー語',
      Italian: 'イタリア語',
      Turkish: 'トルコ語',
      Japanese: '日本語',
    },
  },
};
